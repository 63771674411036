<template>
  <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.6 6.41667H11.4C11.7313 6.41667 12 6.67789 12 7V8.75C12 9.07211 11.7313 9.33333 11.4 9.33333H9.6C9.26869 9.33333 9 9.07211 9 8.75V7C9 6.67789 9.26869 6.41667 9.6 6.41667ZM10.05 4.95833H6.45V5.83333H5.55V4.95833H1.95V5.83333H1.05V4.78352C1.05 4.3976 1.37306 4.08333 1.77019 4.08333H5.55V2.91667H4.8C4.46869 2.91667 4.2 2.65544 4.2 2.33333V0.583333C4.2 0.261224 4.46869 0 4.8 0H7.2C7.53131 0 7.8 0.261224 7.8 0.583333V2.33333C7.8 2.65544 7.53131 2.91667 7.2 2.91667H6.45V4.08333H10.2298C10.6269 4.08333 10.95 4.39742 10.95 4.78352V5.83333H10.05V4.95833ZM5.1 6.41667H6.9C7.23131 6.41667 7.5 6.67789 7.5 7V8.75C7.5 9.07211 7.23131 9.33333 6.9 9.33333H5.1C4.76869 9.33333 4.5 9.07211 4.5 8.75V7C4.5 6.67789 4.76869 6.41667 5.1 6.41667ZM0.599999 6.41667H2.4C2.73131 6.41667 3 6.67789 3 7V8.75C3 9.07211 2.73131 9.33333 2.4 9.33333H0.599999C0.268687 9.33333 0 9.07211 0 8.75V7C0 6.67789 0.268687 6.41667 0.599999 6.41667Z"
      fill="var(--v-accent-base)"
    />
  </svg>
</template>

<script>
export default {
  name: 'Hierarchy',
};
</script>
