























import { TimePeriod } from '@/store/tasks';
import { format } from 'date-fns';
import Vue, { PropType } from 'vue';

export default Vue.extend({
  name: 'MonthPicker',
  props: {
    period: { type: Object as PropType<TimePeriod>, default: () => ({ begin: new Date(), end: new Date() }) },
    disabled: { type: Boolean, default: false },
    value: { type: Date },
  },
  data() {
    return {
      date: new Date(),
      selectedYear: new Date().getFullYear(),
      months: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ],
      enabled: true,
      allTime: undefined,
    };
  },
  computed: {
    formattedMonth() {
      return this.months[this.date.getMonth()];
    },
    formattedDate() {
      return format(this.date, 'M.yyyy');
    },
    disablePrevBtn() {
      return (
        this.selectedYear === this.period.begin.getFullYear() && this.date.getMonth() < this.period.begin.getMonth()
      );
    },
    disableNextBtn() {
      return this.selectedYear === this.period.end.getFullYear() && this.date.getMonth() >= this.period.end.getMonth();
    },
    availableYears() {
      return Array.from(
        { length: this.period.end.getFullYear() - this.period.begin.getFullYear() + 1 },
        (v, k) => k + this.period.begin.getFullYear(),
      );
    },
  },
  watch: {
    selectedYear(value) {
      const newDate = new Date(this.date);
      newDate.setFullYear(value);
      this.date = newDate;
    },
    async allTime(newValue) {
      await this.$emit('onMonthChanged', newValue ? undefined : this.date);
      await this.$emit('input', newValue ? undefined : this.date);
    },
    value(value) {
      if (value == null) {
        this.allTime = true;
      } else {
        this.date = value;
      }
    },
  },
  mounted() {
    if (this.value == null) {
      this.allTime = true;
      console.log(666);
    } else {
      this.date = this.value;
    }
  },
  methods: {
    async changeDate(date: Date) {
      this.date = date;
      this.selectedYear = new Date(this.date).getFullYear();
      await this.$emit('onMonthChanged', date);
      await this.$emit('input', date);
    },
    async previousMonth() {
      const newDate = new Date(this.date);
      newDate.setMonth(newDate.getMonth() - 1);
      await this.changeDate(newDate);
    },
    async nextMonth() {
      const newDate = new Date(this.date);
      newDate.setMonth(newDate.getMonth() + 1);
      await this.changeDate(newDate);
    },
  },
});
