




























































































































import Task from '@/components/MentoringTask.vue';
import Vue, { PropType } from 'vue';
import { TaskEntity, TaskSetEntity, TaskSetStage, TaskSetType, UserEntity } from '@mentessa/types';
import { DeleteTask, LoadPausedTasks, SendTasks, SetTaskSetStage } from '@/store/tasks';
import { mapState } from 'vuex';
import { UsersState } from '@/store/users';
import { EditGoals, EditTasks, ShowWelcome, UIState, WelcomeType } from '@/store/ui';
import ButtonWithConfirmation from '@/components/ButtonWithConfirmation.vue';

export default Vue.extend({
  name: 'MentoringTaskList',
  props: {
    title: String,
    taskSet: { type: Object as PropType<TaskSetEntity> },
    isMentorMode: Boolean,
    mentoringUser: { type: Object as PropType<UserEntity> },
    isMonthly: Boolean,
    canCreateNew: Boolean,
    forceSelect: Boolean,
  },
  components: { Task, ButtonWithConfirmation },
  data: () => ({
    taskSetLocal: undefined,
  }),
  computed: {
    ...mapState<UsersState>('users', {
      me: (state: UsersState) => state.me,
    }),
    ...mapState<UIState>('ui', {
      editGoals: (state: UIState) => state.ui.editGoals,
      editTasks: (state: UIState) => state.ui.editTasks,
    }),
    isEditMode() {
      return this.isMonthly ? this.editGoals : this.editTasks;
    },
    isUpdated() {
      // To-Do: refactor
      return JSON.stringify(this.taskSet) !== JSON.stringify(this.taskSetLocal);
    },
    daysLeft() {
      if (!this.taskSetLocal?.tasks) {
        return 0;
      }
      // ToDo: Remove this crazy hack with time-shifting
      const today = new Date().getTime() - 1000 * 60 * 60 * 12; // Sub 12 hours to compensate time zone
      const days = Math.ceil((Date.parse(this.taskSetLocal.due) - today) / (1000 * 60 * 60 * 24));
      return days >= 0 ? days : 0;
    },
    validationAvailable() {
      return !this.isMentorMode && this.taskSet?.stage === TaskSetStage.Active;
    },
    evaluationAvailable() {
      return this.isMentorMode && this.taskSet?.stage === TaskSetStage.Review;
    },
    haveTasks() {
      return this.taskSetLocal?.tasks?.length;
    },
    isTasksValid() {
      if (!this.taskSetLocal?.tasks?.length) return true;
      return this.taskSetLocal.tasks.every((t) => (t.text ? t.text.length <= 70 : true));
    },
    canAddMoreTask() {
      return this.taskSetLocal?.tasks?.length < (this.isMonthly ? 5 : 10);
    },
  },
  watch: {
    taskSet: {
      deep: true,
      handler() {
        if (this.taskSet) {
          this.taskSetLocal = JSON.parse(JSON.stringify(this.taskSet));
        } else {
          this.taskSetLocal = undefined;
        }
      },
    },
    isEditMode(isEdit) {
      if (isEdit) {
        if (!this.taskSetLocal?.tasks?.length) {
          this.setEmptyTaskSet();
        }
      } else {
        if (this.taskSetLocal) {
          this.taskSetLocal.tasks = this.taskSetLocal.tasks?.filter((task: TaskEntity) => task.text.length > 0);
        }
      }
    },
    mentoringUser() {
      if (this.taskSet != null) {
        this.taskSetLocal = JSON.parse(JSON.stringify(this.taskSet));
      } else if (this.taskSetLocal?.tasks?.length > 0) {
        this.taskSetLocal.tasks = [];
      }
    },
  },
  methods: {
    updateTasks(task, i) {
      Object.assign(this.taskSetLocal.tasks[i], task);
    },
    async addTask() {
      if (this.canAddMoreTask) {
        const emptyTask = { text: '', progress: 0, isEvaluated: true };
        this.taskSetLocal.tasks.push(emptyTask);
      }
    },
    async sendTasks() {
      if (this.isMonthly) {
        await this.$store.dispatch(new EditGoals(false));
      } else {
        await this.$store.dispatch(new EditTasks(false));
      }
      await this.$store.dispatch(new SendTasks(this.taskSetLocal));
    },
    async submitForValidation() {
      await this.$store.dispatch(new SetTaskSetStage(this.taskSet, TaskSetStage.Review));
      this.taskSetLocal.stage = TaskSetStage.Review;
      const percents =
        (this.taskSet.tasks.reduce((pv, cv) => pv + Number(cv.isDone), 0) / this.taskSet.tasks.length) * 100;

      await this.$store.dispatch(
        new ShowWelcome(this.isMonthly ? WelcomeType.MenteeGoalsCompleted : WelcomeType.MenteeTasksCompleted, {
          percents,
          taskSetId: this.taskSet.id,
        }),
      );
    },
    async submitEvaluation() {
      const taskSetId = this.taskSet.id;
      await this.$store.dispatch(new SetTaskSetStage(this.taskSet, TaskSetStage.Close));
      if (this.isMonthly) {
        await this.$store.dispatch(new ShowWelcome(WelcomeType.MentorEvaluation, { taskSetId: taskSetId }));
      }
    },
    getNextWeekDay(dayNumber: number, firstDay: Date = undefined) {
      const day = firstDay ?? new Date();
      const utcDate = new Date(Date.UTC(day.getFullYear(), day.getMonth(), day.getDate()));
      utcDate.setDate(utcDate.getDate() + ((7 + dayNumber - utcDate.getDay()) % 7));
      utcDate.setHours(0, 0, 0, 0);
      return utcDate;
    },
    async setEmptyTaskSet() {
      if (!this.taskSetLocal) {
        const today = new Date();
        const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        const from = this.isMonthly
          ? new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate()))
          : this.getNextWeekDay(1);
        const due = this.isMonthly
          ? new Date(Date.UTC(lastDayOfMonth.getFullYear(), lastDayOfMonth.getMonth(), lastDayOfMonth.getDate()))
          : this.getNextWeekDay(5, from);
        this.taskSetLocal = {
          from,
          due,
          stage: TaskSetStage.Active,
          type: this.isMonthly ? TaskSetType.Monthly : TaskSetType.Weekly,
          isActive: true,
          mentor: this.me,
          mentee: this.mentoringUser,
          tasks: [],
        };
      }
      if (!this.taskSetLocal.tasks?.length) {
        const pausedTasks = await this.$store.dispatch(
          new LoadPausedTasks(this.mentoringUser, this.isMonthly ? TaskSetType.Monthly : TaskSetType.Weekly),
        );
        this.taskSetLocal.tasks = pausedTasks ?? [];
        while (this.taskSetLocal.tasks.length < 3) this.taskSetLocal.tasks.push({ text: '', progress: 0 });
      }
    },
    async switchEditMode() {
      if (this.isMonthly) {
        await this.$store.dispatch(new EditGoals(!this.isEditMode));
        if (!this.isEditMode) {
          this.taskSetLocal.tasks = [];
        }
      } else {
        await this.$store.dispatch(new EditTasks(!this.isEditMode));
      }
      if (!this.isEditMode) {
        this.taskSet?.tasks.forEach((task, index) => {
          if (task.text != this.taskSetLocal.tasks[index].text) {
            this.taskSetLocal.tasks[index].text = task.text;
          }
        });
      }
    },
    async removeTask(task, index) {
      if (this.taskSetLocal?.tasks.length > index) {
        if (await this.$store.dispatch(new DeleteTask(task))) {
          this.taskSetLocal.tasks.splice(index, 1);
        }
      }
    },
    // remove this if transition is appruved
    // testWelcomeOpen() {
    //   this.$store.dispatch(new ShowWelcome(WelcomeType.MentorEvaluation, { taskSetId: 3 }));
    // },
  },
  mounted() {
    if (this.taskSet != null) {
      this.taskSetLocal = JSON.parse(JSON.stringify(this.taskSet));
    }
  },
});
