


































































































import { UserEntity, MentoringType } from '@mentessa/types';
import Vue, { PropType } from 'vue';

export default Vue.extend({
  name: 'MentoringProfile',
  props: {
    user: { type: Object as PropType<UserEntity> },
  },
  computed: {
    isMentorProfilDisplayed() {
      return this.user.attributes.mentoringType === MentoringType.Mentor;
    },
    isMenteeProfilDisplayed() {
      return this.user.attributes.mentoringType === MentoringType.Mentee;
    },
  },
});
