




































import Vue, { PropType } from 'vue';
import { TaskEntity } from '@mentessa/types';

export default Vue.extend({
  name: 'AchievementsList',
  props: {
    title: String,
    tasks: { type: Array as PropType<TaskEntity[]> },
    isMonthly: Boolean,
  },
});
