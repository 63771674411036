















































import Vue from 'vue';
import { mapState } from 'vuex';
import { UsersState } from '@/store/users';
import { ShowDrawer, CloseDrawer, UIState } from '@/store/ui';

export default Vue.extend({
  name: 'NavigationDrawer',
  data: () => ({
    filters: ['location', 'division', 'department', 'role', 'skills'],
    filterValues: {
      location: '',
      division: '',
      department: '',
      role: '',
      skills: '',
    },
    showDrawer: false,
  }),
  computed: {
    ...mapState<UsersState>('users', {
      sources: (state: UsersState) => state.filterSources,
    }),
    ...mapState<UIState>('ui', {
      isDrawerVisible: (state: UIState) => state.navigationDrawer.isVisible,
    }),
  },
  methods: {
    getFilterSource(name: string) {
      if (this.sources[name]) {
        return this.sources[name];
      }
    },
    applyFilters() {
      this.$emit('setFilters', this.filterValues);
    },
    selectFilter(filterName: string, data) {
      this.filterValues[filterName] = data;
    },
    async toggleDrawer(newVal) {
      if (newVal) {
        await this.$store.dispatch(new ShowDrawer());
      } else {
        await this.$store.dispatch(new CloseDrawer());
      }
    },
  },
});
