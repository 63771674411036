import {
  CoupleStatistics,
  FeedbackOptions,
  GuidanceOptions,
  GuidanceType,
  NotificationDialog,
  NotificationSnackbar,
  TotalStatistics,
  UIMutations,
  UIState,
} from './types';
import { MutationTree } from 'vuex';
import { TaskEntity } from '@mentessa/types';

export const mutations: MutationTree<UIState> = {
  [UIMutations.SetNotificationDialog](state: UIState, notificationDialog: NotificationDialog) {
    state.notificationDialog = notificationDialog;
  },
  [UIMutations.SetNotificationSnackbar](state: UIState, notificationSnackbar: NotificationSnackbar) {
    state.notificationSnackbar = notificationSnackbar;
  },
  [UIMutations.ShowGuidanceSnackbarVisibility](state: UIState, isVisible: boolean) {
    state.guidanceSnackbar.isVisible = isVisible;
  },
  [UIMutations.DoGuidance](state: UIState, isVisible: boolean) {
    state.guidanceSnackbar.isVisible = isVisible;
  },
  [UIMutations.ShowWelcome](state: UIState, { welcomeType, options }) {
    state.welcomeDialog.type = welcomeType;
    state.welcomeDialog.options = options;
    state.welcomeDialog.isVisible = true;
  },
  [UIMutations.CloseWelcome](state: UIState) {
    state.welcomeDialog.isVisible = false;
  },
  [UIMutations.SetEditGoals](state: UIState, isEdit: boolean) {
    state.ui.editGoals = isEdit;
  },
  [UIMutations.SetEditTasks](state: UIState, isEdit: boolean) {
    state.ui.editTasks = isEdit;
  },
  [UIMutations.SetGuidanceType](state: UIState, guidanceType: GuidanceType) {
    state.guidanceSnackbar.type = guidanceType;
  },
  [UIMutations.SetGuidanceOptions](state: UIState, options: GuidanceOptions) {
    state.guidanceSnackbar.options = options;
  },
  [UIMutations.SetTotalStats](state: UIState, stats: TotalStatistics) {
    state.stats.total = stats;
  },
  [UIMutations.SetCoupleStats](state: UIState, stats: CoupleStatistics) {
    state.stats.couple = stats;
  },
  [UIMutations.SetFeedbackVisible](state: UIState, options: FeedbackOptions) {
    state.feedback = options;
  },
  [UIMutations.SetCommentsDialogTask](state: UIState, task: TaskEntity) {
    state.commentsDialog.task = task;
  },
  [UIMutations.SetCommentsDialogVisible](state: UIState, isVisible: boolean) {
    state.commentsDialog.isVisible = isVisible;
  },
  [UIMutations.SetNavigationDrawerVisible](state: UIState) {
    state.navigationDrawer.isVisible = true;
  },
  [UIMutations.SetNavigationDrawerHidden](state: UIState) {
    state.navigationDrawer.isVisible = false;
  },
};
