




























































import Vue, { PropType } from 'vue';
import { TaskEntity, TaskSetStage } from '@mentessa/types';
import MentoringCommentDialog from '@/components/Inputs/CommentDialog.vue';
import { SetTaskDone, SetTaskEvaluated, SetTaskProgress } from '@/store/tasks';

export default Vue.extend({
  name: 'Task',
  components: { MentoringCommentDialog },
  props: {
    task: { type: Object as PropType<TaskEntity> },
    isEditMode: Boolean,
    isMonthly: Boolean,
    stage: { type: Number as PropType<TaskSetStage> },
  },
  data: () => ({
    localTask: {
      id: undefined,
      text: '',
      progress: 0,
      isDone: false,
      isEvaluated: false,
    },
    dialog: false,
  }),
  watch: {
    // Required for switching between mentees
    task() {
      this.updateLocalTask();
    },
    localTask: {
      deep: true,
      handler(ov, nv) {
        // console.log(ov, '--->', nv);
        this.$emit('updateTask', nv);
      },
    },
  },
  methods: {
    updateLocalTask() {
      if (this.localTask.id !== this.task.id) this.localTask.id = this.task.id;
      if (this.localTask.text !== this.task.text) this.localTask.text = this.task.text;
      if (this.localTask.progress !== this.task.progress) this.localTask.progress = this.task.progress;
      if (this.localTask.isDone !== this.task.isDone) this.localTask.isDone = this.task.isDone;
      if (this.localTask.isEvaluated !== this.task.isEvaluated) this.localTask.isEvaluated = this.task.isEvaluated;
    },
    async setTaskEvaluated() {
      if (this.localTask.isEvaluated) await this.$store.dispatch(new SetTaskEvaluated(this.task, true));
    },
    async setTaskDone() {
      await this.$store.dispatch(new SetTaskDone(this.task, this.localTask.isDone));
    },
    async setProgress(value) {
      await this.$store.dispatch(new SetTaskProgress(this.task, value));
    },
  },
  mounted() {
    this.updateLocalTask();
  },
});
