import { GetterTree } from 'vuex';
import { TenantState } from '@/store/tenant/types';
import { RootState } from '@/store/types';

export const getters: GetterTree<TenantState, RootState> = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  itemsForSource: (state: TenantState, getters) => (source: string) => {
    if (source === 'locations') {
      return state.locations;
    } else if (source === 'usernames') {
      return state.usernames;
    }
    return state.tenant[source];
  },
};
