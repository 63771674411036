import Evaluate from '@/components/Icons/Evaluate.vue';
import CheckBoxFilled from '@/components/Icons/CheckBoxFilled.vue';
import FilterIcon from '@/components/Icons/FilterIcon.vue';
import Handshake from '@/components/Icons/Handshake.vue';
import Hierarchy from '@/components/Icons/Hierarchy.vue';
import StartDate from '@/components/Icons/StartDate.vue';
import Magnifier from '@/components/Icons/Magnifier.vue';
import Comment from '@/components/Icons/Comment.vue';
import Comments from '@/components/Icons/Comments.vue';
import { IconsOptions } from 'vuetify/types/services/icons';

const options: IconsOptions = {
  iconfont: 'mdiSvg',
  values: {
    evaluate: {
      component: Evaluate,
    },
    check_box_filled: {
      component: CheckBoxFilled,
    },
    filter: {
      component: FilterIcon,
    },
    handshake: {
      component: Handshake,
    },
    hierarchy: {
      component: Hierarchy,
    },
    start_date: {
      component: StartDate,
    },
    magnifier: {
      component: Magnifier,
    },
    comment: {
      component: Comment,
    },
    comments: {
      component: Comments,
    },
  },
};

export default options;
