import { PluginObject } from 'vue';
import router from '@/router';

import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
import { Breadcrumb, BreadcrumbHint } from '@sentry/vue';

export type VueSentryOptions = {
  enabled: boolean;
  dsn: string;
  tracing: {
    enabled: boolean;
    sampleRate: number;
  };
  logErrors: boolean;
  breadcrumbs: {
    hook: {
      enabled: boolean;
    };
  };
};

const SentryPlugin: PluginObject<VueSentryOptions> = {
  install(Vue, options): void {
    if (options.enabled) {
      const integrations = [];
      if (options.tracing.enabled) {
        integrations.push(
          new Integrations.BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            tracingOrigins: ['*'],
          }),
        );
      }
      const extraOptions = {};
      if (options.breadcrumbs.hook.enabled) {
        extraOptions['beforeBreadcrumb'] = function (breadcrumb: Breadcrumb, hint?: BreadcrumbHint): Breadcrumb {
          if (breadcrumb.category.startsWith('ui')) {
            let element = hint.event.target;
            let tag;
            while (element && !tag) {
              tag = element.attributes['sentry-tag']?.value ?? element.id;
              if (!tag) {
                element = element.parentElement;
              }
            }
            if (tag) {
              breadcrumb.message = `[${tag.toString()}] ${breadcrumb.message}`;
            }
          }
          return breadcrumb;
        };
      }

      Sentry.init({
        Vue: Vue,
        dsn: options.dsn,
        integrations,
        tracesSampleRate: options.tracing.sampleRate,
        logErrors: options.logErrors,
        ...extraOptions,
      });
    }

    Vue.prototype.$sentry = Sentry;
  },
};

export default SentryPlugin;

declare module 'vue/types/vue' {
  interface Vue {
    readonly $sentry: typeof Sentry;
  }
}
