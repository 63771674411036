import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { getters } from './getters';
import { mutations } from './mutations';
import { actions } from './actions';
import { UIState } from '@/store/ui/types';

export const initialState: UIState = {
  notificationDialog: {
    message: '',
    variant: '',
    buttonText: '',
  },
  notificationSnackbar: {
    message: '',
  },
  guidanceSnackbar: {
    type: undefined,
    isVisible: false,
    options: {},
  },
  welcomeDialog: {
    type: undefined,
    isVisible: false,
    options: undefined,
  },
  commentsDialog: {
    isVisible: false,
    task: undefined,
  },
  ui: {
    editGoals: false,
    editTasks: false,
  },
  stats: {
    total: {
      time: { total: 0, weekAvg: 0 },
      goals: { total: 0, perPair: 0, donePerPair: 0, monthly: { total: 0, couples: 0 } },
      tasks: { total: 0, perWeek: 0, donePerWeek: 0, weekly: { total: 0, couples: 0 } },
    },
    couple: {
      time: { total: 0, week: 0 },
      goals: { total: 0, completed: 0 },
      tasks: { total: 0, completed: 0 },
    },
  },
  feedback: {
    isVisible: false,
  },
  navigationDrawer: {
    isVisible: false,
  },
};

export const module: Module<UIState, RootState> = {
  namespaced: true,
  state: initialState,
  actions,
  mutations,
  getters,
};

export * from './types';
