import { MentoringType, TaskSetEntity, TaskSetStage, TaskSetType, TaskState, UserEntity } from '@mentessa/types';
import { parseISO } from 'date-fns';

export function taskSetSuccessRate(taskSet: TaskSetEntity): number {
  let tasksCount = 0;
  const evaluatedTasksCount = taskSet.tasks.reduce((acc, t) => {
    if (t.state === TaskState.Pause) return acc;
    ++tasksCount;
    if (!t.isEvaluated) return acc;
    if (taskSet.type === TaskSetType.Monthly) return acc + t.progress / 100;
    return acc + (t.isDone ? 1 : 0);
  }, 0);
  return tasksCount > 0 ? evaluatedTasksCount / tasksCount : 0;
}

export function taskSetEvaluationRate(taskSet: TaskSetEntity, target: UserEntity): number {
  const q = taskSet.questionnaires?.filter((q) => q.targetId === target.id) ?? [];
  return q[0]?.rating ?? 0;
}

export function compareMonth(baseDate?: Date) {
  return (dateStr: string, monthShift = 0): boolean => {
    if (!baseDate) return true;
    const parsedDate = parseISO(dateStr);
    if (monthShift) {
      parsedDate.setMonth(parsedDate.getMonth() - monthShift);
    }
    return parsedDate.getFullYear() === baseDate.getFullYear() && parsedDate.getMonth() === baseDate.getMonth();
  };
}

export function getRatingEvaluation(user: UserEntity, targetDate?: Date): number {
  const taskSets = user.taskSets.filter(
    (taskSet) =>
      taskSet.stage === TaskSetStage.Close &&
      taskSet.type === TaskSetType.Monthly &&
      compareMonth(targetDate)(taskSet.due.toString()),
  );
  let totalScore = 0;
  let scoresCount = 0;
  taskSets.forEach((taskSet) => {
    const questionnaires = taskSet?.questionnaires.filter((q) => q.targetId === user.id) ?? [];
    if (questionnaires.length > 0) {
      totalScore += questionnaires.reduce((total, q) => total + q.rating, 0) / questionnaires.length;
      ++scoresCount;
    }
  });
  if (scoresCount > 0) return 5 * (totalScore / scoresCount);

  return null;
}

export function getRatingTotal(user: UserEntity, targetDate?: Date): number {
  const taskSets = user.taskSets.filter(
    (taskSet) => taskSet.stage === TaskSetStage.Close && compareMonth(targetDate)(taskSet.due.toString()),
  );
  if (!taskSets?.length) {
    return null;
  }

  let qScore = 0;
  let qScoresCount = 0;
  let wScore = 0;
  let wScoresCount = 0;
  let gScore = 0;

  taskSets.forEach((ts) => {
    if (ts.type === TaskSetType.Monthly) {
      qScore += taskSetEvaluationRate(ts, user);
      gScore += taskSetSuccessRate(ts);
      ++qScoresCount;
    } else {
      wScore += taskSetSuccessRate(ts);
      ++wScoresCount;
    }
  });

  if (wScoresCount > 0) wScore /= wScoresCount;
  if (qScoresCount > 0) {
    qScore /= qScoresCount;
    gScore /= qScoresCount;
  }

  return (5 * (wScore + gScore + qScore)) / 3;
}

export function getTasks(user: UserEntity, targetDate?: Date): string {
  const taskSets = user.taskSets.filter(
    (taskSet) => taskSet.type === TaskSetType.Weekly && compareMonth(targetDate)(taskSet.due.toString()),
  );
  if (!taskSets?.length) {
    return '-';
  }
  const totalTasks = taskSets.reduce((total, taskSet) => total + taskSet.tasks.length, 0);
  if (user.attributes.mentoringType === MentoringType.Mentor) return totalTasks.toString();
  const doneTasks = taskSets.reduce(
    (total, taskSet) => total + taskSet.tasks.reduce((pv, cv) => pv + (cv.isDone && cv.isEvaluated ? 1 : 0), 0),
    0,
  );
  return `${doneTasks} / ${totalTasks}`;
}

export function getGoals(user: UserEntity, targetDate?: Date): number {
  const taskSets = user.taskSets.filter(
    (taskSet) => taskSet.type === TaskSetType.Monthly && compareMonth(targetDate)(taskSet.due.toString()),
  );
  if (!taskSets?.length) {
    return null;
  }
  return Math.round(
    (100 * taskSets.reduce((total, taskSet) => total + taskSetSuccessRate(taskSet), 0)) / taskSets.length,
  );
}
