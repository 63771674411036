import { GetterTree } from 'vuex';
import { UsersState } from './types';
import { RootState } from '@/store/types';
import { UserEntity } from '@mentessa/types';

export const getters: GetterTree<UsersState, RootState> = {
  isAllStreamUsersLoaded(state: UsersState): boolean {
    const {
      stream: {
        pagination: { currentPage: current, totalPages: total },
      },
    } = state;
    return current !== 0 && current >= total;
  },
  isNewPageAvailable(state: UsersState, getters): boolean {
    const {
      stream: { isLoading },
    } = state;
    return !(isLoading || getters.isAllStreamUsersLoaded);
  },
  hasFilters(state: UsersState) {
    return Object.keys(state.stream?.filters).length > 0;
  },
  getUserById(state: UsersState) {
    return (id: number): UserEntity => {
      let user = state.stream?.users?.find((user) => user.id === id);
      if (!user) {
        user = state.hidden?.users?.find((user) => user.id === id);
        if (!user && id === state.me.id) {
          user = state.me;
        }
      }
      return user;
    };
  },
  getUserMentee(state: UsersState) {
    // ToDo. Switch to filter for one to many relation
    return (mentor: UserEntity) => state.stream?.users?.find((user) => user.mentorId === mentor.id);
  },
  getUserMentees(state: UsersState) {
    //return (mentor: UserEntity) => state.stream?.users?.find((user) => user.mentorId === mentor.id);
    return (mentor: UserEntity) => state.stream?.users?.filter((user) => user.mentorId === mentor.id);
  },
  getMentoringPairs(state: UsersState, getters) {
    return (ids: number[]) => {
      const pairs = [];
      for (const id of ids) {
        const user = getters.getUserById(id) as UserEntity;
        if (user.mentorId) {
          pairs.push({ mentor: getters.getUserById(user.mentorId) as UserEntity, mentee: user });
        }
      }
      return pairs;
    };
  },
};
