















































































































import { SetFavoriteStatus } from '@/store/users';
import { SendDirectMessage } from '@/store/messages';
import { ShowNotificationSnackbarAction } from '@/store/ui';
import Vue, { PropType } from 'vue';
import { UserEntity } from '@mentessa/types';
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import { required, max } from 'vee-validate/dist/rules';

extend('required', {
  ...required,
  message: ' ',
});

extend('max', {
  ...max,
  message: 'May not be greater than {length} characters',
});

export default Vue.extend({
  name: 'UserDetail',
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    user: { type: Object as PropType<UserEntity> },
  },
  data: () => ({
    message: '',
  }),
  computed: {
    matchesNumber() {
      return 0;
    },
  },
  methods: {
    isFavorite() {
      return this.user.isFavorite;
    },
    async toggleFavorite() {
      await this.$store.dispatch(new SetFavoriteStatus(this.user, !this.user.isFavorite));
    },
    async sendMessage() {
      const success = await this.$store.dispatch(new SendDirectMessage(this.user.id, this.message));
      if (success) {
        this.message = '';
        await this.$store.dispatch(new ShowNotificationSnackbarAction(this.$t('notifications.messageSent')));
      } else {
        console.error('Cant send message');
        await this.$store.dispatch(new ShowNotificationSnackbarAction(this.$t('notifications.messageSendError')));
      }
    },
  },
});
