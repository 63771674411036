

































import { VAutocomplete } from 'vuetify/lib';
import { mapGetters, mapState } from 'vuex';
import { UtilsState } from '@/store/utils';

export default VAutocomplete.extend({
  name: 'Language',
  props: {
    required: Boolean,
    hint: String,
    validationHint: String,
    value: Array,
  },
  data: () => ({}),
  computed: {
    ...mapGetters('utils', {
      nameForLang: 'nameForLang',
    }),
    ...mapState<UtilsState>('utils', {
      languages: (state: UtilsState) => Object.entries(state.languages).map((x) => ({ code: x[0], name: x[1] })),
    }),
  },
  methods: {
    removeChip: function (data) {
      const index = data.index;
      this.$emit('input', [...this.value.slice(0, index), ...this.value.slice(index + 1)]);
      this.$emit('change', [...this.value.slice(0, index), ...this.value.slice(index + 1)]);
    },
    filterLang(item, searchToken) {
      return item.name.toLowerCase().includes(searchToken.toLowerCase());
    },
  },
});
