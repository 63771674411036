import { TenantMutations, TenantState } from '@/store/tenant/types';
import { MutationTree } from 'vuex';
import { TenantEntity } from '@mentessa/types';

export const mutations: MutationTree<TenantState> = {
  [TenantMutations.SetTenant](state: TenantState, tenant: TenantEntity) {
    state.tenant = tenant;
  },

  [TenantMutations.SetLocations](state: TenantState, locations: { name: string }[]) {
    state.locations = locations;
  },

  [TenantMutations.SetUsernames](state: TenantState, usernames: { id?: number; name: string }[]) {
    state.usernames = usernames;
  },
};
