
































import Vue from 'vue';
import FeedRecord from '@/components/DashboardFeed/FeedRecord.vue';
import { format, parseISO } from 'date-fns';
import { mapState } from 'vuex';
import { LoadAllEvents, TasksState } from '@/store/tasks';

function formatDate(date: string) {
  return format(parseISO(date), 'd.M.yyyy');
}

export default Vue.extend({
  name: 'Feed',
  components: { FeedRecord },
  data: () => ({
    i: 0,
    date: new Date(),
  }),
  computed: {
    ...mapState<TasksState>('tasks', {
      events: (state: TasksState) => state.activityFeed,
    }),
    eventsByDays() {
      const result = {};
      this.events.forEach((e) => {
        if (result[formatDate(e.createdAt)]) {
          result[formatDate(e.createdAt)].push(e);
        } else {
          result[formatDate(e.createdAt)] = [e];
        }
      });
      return result;
    },
    formattedDate() {
      return format(this.date, 'd.M.yyyy');
    },
  },
  methods: {
    async loadEvents() {
      await this.$store.dispatch(new LoadAllEvents(this.date));
    },
    async previousDay() {
      const newDate = new Date(this.date);
      newDate.setDate(newDate.getDate() - 1);
      this.date = newDate;
      await this.loadEvents();
    },
    async nextDay() {
      const newDate = new Date(this.date);
      newDate.setDate(newDate.getDate() + 1);
      this.date = newDate;
      await this.loadEvents();
    },
  },
  async mounted() {
    await this.loadEvents();
  },
});
