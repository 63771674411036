import { VueSentryOptions } from '@/plugins/sentry';

const isProduction = process.env.NODE_ENV === 'production';

function getBoolean(name, defaultValue = false) {
  if (process.env[name] == null) {
    return defaultValue;
  }
  return ['true', '1'].includes(process.env[name].toLowerCase());
}

export const keycloakOptions = {
  url: process.env.VUE_APP_KEYCLOAK_URL,
  realm: process.env.VUE_APP_KEYCLOAK_REALM || 'mentessa-saas',
  clientId: process.env.VUE_APP_KEYCLOAK_CLIENT_ID || 'web-app',
};

export const loggerOptions = {
  isEnabled: getBoolean('VUE_APP_LOG_ENABLE', true),
  logLevel: process.env.VUE_APP_LOG_LEVEL || (isProduction ? 'error' : 'debug'),
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: '|',
  showConsoleColors: true,
};

export const messageOptions = {
  autoFetch: getBoolean('VUE_APP_MESSAGE_FETCH_AUTO', true),
  fetchInterval: Number(process.env.VUE_APP_MESSAGE_FETCH_INTERVAL) || 5 * 60 * 1000,
};

export const notificationOptions = {
  snackbar: {
    showTime: Number(process.env.VUE_APP_SNACKBAR_SHOW_TIME) || 3 * 1000,
  },
};

export const sentryOptions: VueSentryOptions = {
  breadcrumbs: { hook: { enabled: getBoolean('VUE_APP_SENTRY_BREADCRUMB_HOOK', false) } },
  enabled: getBoolean('VUE_APP_SENTRY_ENABLED', false),
  dsn: process.env.VUE_APP_SENTRY_DSN ?? '',
  tracing: {
    enabled: getBoolean('VUE_APP_SENTRY_USE_TRACING', true),
    sampleRate: Number(process.env.VUE_APP_SENTRY_TRACING_SAMPLE_RATE ?? 0.1),
  },
  logErrors: getBoolean('VUE_APP_SENTRY_LOG_ERRORS', true),
};

const host = window.location.host;
const hostParts = host.split('.');
function getNewCommunitySubdomain() {
  return process.env.VUE_APP_NEW_COMMUNITY_SUBDOMAIN || 'new';
}

export enum AppType {
  NewCommunity,
  UseCommunity,
}

export const app = {
  newCommunitySubdomain: getNewCommunitySubdomain(),
  community: hostParts[0],
  type: hostParts[0] === getNewCommunitySubdomain() ? AppType.NewCommunity : AppType.UseCommunity,
};
