













































import { UserEntity } from '@mentessa/types';
import Vue, { PropType } from 'vue';

export default Vue.extend({
  name: 'MentorMenteePersons',
  props: {
    mentor: Object as PropType<UserEntity>,
    mentee: Object as PropType<UserEntity>,
  },
  methods: {
    getFullName(user: UserEntity) {
      return `${user.identity.attributes.firstName} ${user.identity.attributes.lastName}`;
    },
  },
});
