<template>
  <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.1345 14.1883L22.0523 20.1062C22.2603 20.3143 22.3771 20.5966 22.377 20.8909C22.3768 21.1851 22.2599 21.4673 22.0517 21.6753C21.8436 21.8833 21.5613 22.0001 21.2671 22C20.9728 21.9999 20.6906 21.8829 20.4826 21.6747L14.5649 15.7569C12.7959 17.1271 10.5713 17.7719 8.34374 17.5601C6.11618 17.3484 4.05296 16.2959 2.5738 14.6169C1.09464 12.9379 0.310665 10.7584 0.381349 8.52184C0.452033 6.28529 1.37207 4.15967 2.9543 2.57741C4.53652 0.995143 6.66209 0.0750819 8.89858 0.00439586C11.1351 -0.0662901 13.3145 0.717709 14.9935 2.1969C16.6724 3.67609 17.7249 5.73937 17.9366 7.96699C18.1484 10.1946 17.5036 12.4192 16.1334 14.1883H16.1345ZM9.17736 15.3994C10.9277 15.3994 12.6064 14.704 13.844 13.4663C15.0817 12.2286 15.7771 10.5499 15.7771 8.79953C15.7771 7.04914 15.0817 5.37044 13.844 4.13272C12.6064 2.89501 10.9277 2.19967 9.17736 2.19967C7.42701 2.19967 5.74836 2.89501 4.51067 4.13272C3.27299 5.37044 2.57767 7.04914 2.57767 8.79953C2.57767 10.5499 3.27299 12.2286 4.51067 13.4663C5.74836 14.704 7.42701 15.3994 9.17736 15.3994Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: 'Magnifier',
};
</script>

<style scoped></style>
