






























































































import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';
import { LoadClosedTasks, SelectMentee, TasksState } from '@/store/tasks';
import AchievementsList from '@/components/Badges/AchievementsList.vue';
import { MentoringType, QuestionnaireEntity, TaskSetStage, TaskSetType } from '@mentessa/types';
import { UsersState } from '@/store/users';
import Badge from '@/components/Badge.vue';
import { format, parseISO } from 'date-fns';
import { ShowFeedback } from '@/store/ui';
import { getRatingTotal, taskSetSuccessRate } from '@/utils/ratings';

function compareMonth(dateStr: string, date2Str: string, monthShift = 0) {
  const parsedDate = parseISO(dateStr);
  const parsedDate2 = parseISO(date2Str);
  if (monthShift) {
    parsedDate.setMonth(parsedDate.getMonth() - monthShift);
  }
  return parsedDate.getFullYear() === parsedDate2.getFullYear() && parsedDate.getMonth() === parsedDate2.getMonth();
}

function formatRating(rating?: number) {
  return rating != null ? `${(rating * 5).toFixed(1)}` : '-';
}

function formatDate(date: string) {
  return format(parseISO(date), 'd.M.yyyy');
}

export default Vue.extend({
  name: 'Badges',
  components: {
    Badge,
    AchievementsList,
  },
  data: () => ({
    // badges: [],
    // img: '/badges/rocket.svg',
  }),
  computed: {
    ...mapState<UsersState>('users', {
      me: (state: UsersState) => state.me,
    }),
    ...mapState<TasksState>('tasks', {
      mentees: (state: TasksState) => state.mentees,
      mentor: (state: TasksState) => state.mentor,
      mentee: (state: TasksState) => state.mentee,
      closed: (state: TasksState) => state.closed,
    }),
    ...mapGetters('users', {
      getUserById: 'getUserById',
      getUserMentee: 'getUserMentee',
    }),
    mentoringUser() {
      if (this.mentor) return this.mentor;
      return this.mentee;
    },
    badges() {
      const badges = [];
      badges.push(
        ...(this.me.attributes.badges?.map((badge) => ({
          title: this.$t(`badges.${badge.name}`),
          image: `/badges/${badge.name}.svg`,
        })) || []),
      );
      badges.push({ title: this.$t('badges.inactive'), image: '/badges/inactive.svg' });
      return badges;
    },
    goals() {
      const goals = [];
      this.closed?.forEach((taskSet) => {
        if (taskSet.type === TaskSetType.Monthly) goals.push(...taskSet.tasks);
      });
      return goals;
    },
    tasks() {
      const tasks = [];
      this.closed?.forEach((taskSet) => {
        if (taskSet.type === TaskSetType.Weekly) tasks.push(...taskSet.tasks);
      });
      return tasks;
    },
    target() {
      return { ...this.me, taskSets: this.closed };
    },
    ratings() {
      const taskSets = this.closed.filter(
        (taskSet) => taskSet.stage === TaskSetStage.Close && taskSet.type === TaskSetType.Monthly,
      );
      return taskSets.map((taskSet) => {
        const questionnaire = taskSet.questionnaires.find((q) => q.targetId === this.target.id);
        const weeklyTaskSets = this.closed.filter(
          (wt) => wt.type === TaskSetType.Weekly && compareMonth(wt.due, taskSet.due),
        );
        const totalTasks = weeklyTaskSets.reduce((total, taskSet) => total + taskSet.tasks.length, 0);
        let tasks;
        if (this.me.attributes.mentoringType === MentoringType.Mentor) tasks = totalTasks;
        else {
          if (totalTasks === 0) tasks = 0;
          const doneTasks = weeklyTaskSets.reduce(
            (total, taskSet) => total + taskSet.tasks.reduce((pv, cv) => pv + (cv.isDone && cv.isEvaluated ? 1 : 0), 0),
            0,
          );
          tasks = `${doneTasks} / ${totalTasks}`;
        }

        return {
          interval: `${formatDate(taskSet.from)}-${formatDate(taskSet.due)}`,
          rating: formatRating(questionnaire?.rating),
          rating2: getRatingTotal(this.target, parseISO(taskSet.due))?.toFixed(1) ?? '-',
          questionnaire,
          tasks,
          goals: `${Math.round(taskSetSuccessRate(taskSet) * 100)}%`,
        };
      });
    },
  },
  async mounted() {
    if (this.mentees?.length > 0 && !this.mentee) await this.$store.dispatch(new SelectMentee(this.mentees[0]));
    await this.$store.dispatch(new LoadClosedTasks());
    // this.badges = Array.from({ length: 2 }, (b, i) => {
    //   return { id: i, isActive: false };
    // });
  },
  methods: {
    showQuestionnaire(questionnaire: QuestionnaireEntity) {
      this.$store.dispatch(new ShowFeedback(questionnaire));
    },
  },
});
