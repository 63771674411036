import Vue from 'vue';
import VueRouter from 'vue-router';
import { app, AppType } from '@/config';
import { routes as newCommunityRoutes } from './new';
import { routes as useCommunityRoutes } from './use';
import store from '../store';

Vue.use(VueRouter);

const routes = app.type === AppType.NewCommunity ? newCommunityRoutes : useCommunityRoutes;
Vue.prototype.$log.debug(`Load routes table for [${AppType[app.type]}] ${app.community}`);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

let pausedResolve = null;
let pausedPromise = Promise.resolve();

router.beforeEach(async (to, from, next) => {
  await pausedPromise;
  const user = store.state.users.me;
  // Attention: order of /rules and /profile guards is important here.
  if (!user.attributes.rulesRead) {
    if (to.path !== '/rules') return next('/rules');
    else return next();
  }
  if (!user.complete) {
    console.log('not complete', to.path !== '/profile');
    if (to.path !== '/profile') return next('/profile');
    else return next();
  }
  if (['/dashboard'].includes(to.path) && !(user.attributes.isHR || user.attributes.isManager)) {
    return next('/community');
  }
  if (['/mentoring', '/badges', '/badges/', '/mentoring/'].includes(to.path) && user.attributes.mentoringType == null) {
    return next('/community');
  }
  if (to.path.startsWith('/admin') && !store.state.users.permissions.superAdmin) {
    return next('/community');
  }

  next();
});

export function pause(): void {
  if (!pausedResolve) {
    console.log('Pause routing');
    pausedPromise = new Promise((resolve) => (pausedResolve = resolve));
  }
}

export function resume(): void {
  if (pausedResolve) {
    console.log('Resume routing');
    pausedResolve();
    pausedResolve = null;
  }
}

pause();

export default router;
