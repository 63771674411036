















































import Vue, { PropType } from 'vue';
import { UserEntity } from '@mentessa/types';

export default Vue.extend({
  name: 'TopMentors',
  props: {
    mentors: {
      type: Array as PropType<UserEntity[]>,
      default: () => [],
    },
  },
  methods: {
    showModal() {
      this.$emit('showModal');
    },
    getFullName(user: UserEntity) {
      return `${user.identity.attributes.firstName} ${user.identity.attributes.lastName}`;
    },
  },
});
