var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"m-mentoring-task d-flex justify-space-between",staticStyle:{"height":"50px","width":"100%"}},[_c('div',{staticClass:"d-flex pt-0 align-center",style:(_vm.$vuetify.breakpoint.smAndDown
        ? 'width: calc(100% - 120px); max-width: calc(100% - 120px)'
        : 'width: calc(100% - 170px); max-width: calc(100% - 170px)')},[(!_vm.isEditMode && !_vm.isLocalEditMode)?_c('div',{staticClass:"d-flex flex-grow-1 flex-shrink-1 task-view-inner align-center",class:_vm.taskClasses,staticStyle:{"max-width":"100%"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();}}},[_c('p',{staticClass:"text-truncate",staticStyle:{"margin-top":"0","margin-bottom":"0","margin-left":"5px","color":"var(--v-accent-base)","cursor":"initial"}},[_vm._v(" "+_vm._s(_vm.task.text)+" ")]),(_vm.isMentorMode && _vm.isMonthly && _vm.task.progress > 0)?_c('p',{staticClass:"pl-3 pr-1",staticStyle:{"margin-top":"0","margin-bottom":"0","color":"var(--v-primary-base)","font-weight":"bold"}},[_vm._v(" "+_vm._s(_vm.task.progress)+"% ")]):_vm._e(),_c('v-tooltip',{attrs:{"color":"rgba(0,0,0,.9)","bottom":"","nudge-top":"5px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2 mr-xs-2 mr-sm-2 mr-md-6",attrs:{"icon":""},on:{"click":_vm.showCommentsDialog}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("$comment")])],1)]}}],null,false,3565201547)},[_c('span',[_vm._v(_vm._s(_vm.$t('tooltips.commentActivity')))])])],1):_vm._e(),(_vm.isEditMode || _vm.isLocalEditMode)?_c('v-text-field',{class:{ 'field-edit-mode': !_vm.isEditMode && !_vm.isLocalEditMode },attrs:{"disabled":_vm.localTask.id != null && !_vm.isLocalEditMode,"dense":"","outlined":"","full-width":"","hide-details":"","background-color":_vm.isValidField ? 'var(--v-error-lighten5)' : 'transparent'},on:{"click":function($event){$event.stopPropagation();}},model:{value:(_vm.localTask.text),callback:function ($$v) {_vm.$set(_vm.localTask, "text", $$v)},expression:"localTask.text"}}):_vm._e(),(_vm.isLocalEditMode)?_c('v-btn',{attrs:{"depressed":"","color":"primary","disabled":_vm.isValidField},on:{"click":_vm.saveChanges}},[_vm._v(" "+_vm._s(_vm.$t('mentoring.saveTask'))+" ")]):_vm._e()],1),(_vm.evaluationAvailable)?_c('div',{staticClass:"ml-2 mr-0 mt-0"},[_c('v-tooltip',{attrs:{"color":"rgba(0,0,0,.9)","bottom":"","nudge-right":"-40%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('div',_vm._g({},on),[_c('v-checkbox',{staticClass:"mt-1",class:_vm.localTask.isEvaluated ? 'evaluated-on' : 'evaluated-off',staticStyle:{"transform":"scale(1.2)"},attrs:{"on-icon":"$evaluate","off-icon":"$evaluate","hide-details":""},on:{"change":_vm.setTaskEvaluated},model:{value:(_vm.localTask.isEvaluated),callback:function ($$v) {_vm.$set(_vm.localTask, "isEvaluated", $$v)},expression:"localTask.isEvaluated"}})],1)]}}],null,false,1470362886)},[_c('span',[_vm._v(_vm._s(_vm.approveTooltip))])])],1):_vm._e(),(_vm.executionAvailable)?_c('v-tooltip',{class:{ 'm-mentoring-task-edit': !_vm.isEditMode, 'm-mentoring-task-disable-done-checkbox': !_vm.localTask.id },attrs:{"disabled":!_vm.localTask.id || _vm.isEditMode || !_vm.changesAvailable,"bottom":"","nudge-left":"-90%","nudge-top":"20px","color":"rgba(0,0,0,.9)","close-delay":"1000"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('div',_vm._g({},on),[_c('v-checkbox',{staticClass:"ma-0",staticStyle:{"width":"auto"},attrs:{"color":"primary","on-icon":"$check_box_filled","dense":"","hide-details":"","readonly":!_vm.localTask.id || _vm.isEditMode || !_vm.changesAvailable || _vm.isPaused},on:{"change":_vm.setTaskDone},model:{value:(_vm.localTask.isDone),callback:function ($$v) {_vm.$set(_vm.localTask, "isDone", $$v)},expression:"localTask.isDone"}})],1)]}}],null,false,4126289379)},[(_vm.isMonthly)?_c('span',[_vm._v(" "+_vm._s(_vm.localTask.isDone ? _vm.$t('tooltips.markGoalAsNotDone') : _vm.$t('tooltips.markGoalAsDone'))+" ")]):_c('span',[_vm._v(_vm._s(_vm.localTask.isDone ? _vm.$t('tooltips.markTaskAsNotDone') : _vm.$t('tooltips.markTaskAsDone')))]),(_vm.isMentorMode && _vm.localTask.isDone)?_c('span',{staticStyle:{"text-decoration":"underline","cursor":"pointer"},on:{"click":_vm.showCommentsDialog}},[_vm._v(" "+_vm._s(_vm.$t('tooltips.addComment'))+" ")]):_vm._e()]):_vm._e(),(_vm.isMonthly && !_vm.isMentorMode)?_c('div',{staticClass:"d-flex justify-space-between mx-0 mt-0 mb-0",style:(_vm.$vuetify.breakpoint.smAndDown ? 'width: auto' : 'min-width: 160px; max-width: 160px')},[_c('v-tooltip',{attrs:{"color":"rgba(0,0,0,.9)","bottom":"","nudge-left":"-90%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('div',_vm._g({staticClass:"d-flex pt-1",staticStyle:{"height":"20px"},style:(_vm.$vuetify.breakpoint.smAndDown ? 'width: 60px' : 'width: 110px')},on),[_c('v-slider',{attrs:{"step":"10","ticks":"always","readonly":!_vm.changesAvailable,"disabled":!_vm.changesAvailable,"hide-details":""},on:{"end":_vm.setProgress},model:{value:(_vm.localTask.progress),callback:function ($$v) {_vm.$set(_vm.localTask, "progress", $$v)},expression:"localTask.progress"}})],1)]}}],null,false,2999812591)},[_c('span',[_vm._v(_vm._s(_vm.$t('tooltips.markProgress')))])]),_c('div',{staticClass:"m-font-s-16 m-font-w-500 m-font-c-primary",staticStyle:{"padding-top":"10px"}},[_vm._v(_vm._s(_vm.localTask.progress)+"%")])],1):_vm._e(),(_vm.isMentorMode && !_vm.isEditMode)?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("more_horiz")])],1)]}}],null,false,861102628)},[_c('v-list',[_c('v-list-item',[_c('v-list-item-title',{on:{"click":_vm.handleEdit}},[_vm._v(" "+_vm._s(_vm.$t('mentoring.edit')))])],1),_c('v-list-item',[_c('v-list-item-title',{on:{"click":_vm.togglePause}},[_vm._v(" "+_vm._s(_vm.taskSetStateActionTitle))])],1),_c('v-list-item',[_c('v-list-item-content',[_c('LinkWithConfirmation',{attrs:{"activatorButtonText":_vm.$t('mentoring.delete'),"warningMessage":"Are you sure that you want to delete?","confirmButtonText":"Yes"},on:{"confirmed":_vm.deleteTask}})],1)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }