






































































import Vue, { PropType } from 'vue';
import { UserEntity } from '@mentessa/types';

export default Vue.extend({
  name: 'ModalTopMembers',
  props: {
    mentors: {
      type: Array as PropType<UserEntity[]>,
      default: () => [],
    },
  },
  methods: {
    getFullName(user: UserEntity) {
      return `${user.identity.attributes.firstName} ${user.identity.attributes.lastName}`;
    },
    doAction(user: UserEntity) {
      // ToDo: Add logic later
    },
  },
});
