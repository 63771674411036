import { render, staticRenderFns } from "./DashboardCommunityStatistics.vue?vue&type=template&id=5b0f4406&scoped=true&"
import script from "./DashboardCommunityStatistics.vue?vue&type=script&lang=ts&"
export * from "./DashboardCommunityStatistics.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b0f4406",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDialog } from 'vuetify/lib/components/VDialog';
installComponents(component, {VDialog})
