




































import Vue, { PropType } from 'vue';
import { MentoringType, UserEntity } from '@mentessa/types';
import { mapGetters, mapState } from 'vuex';
import { TasksState } from '@/store/tasks';
import { TransferUsers, UsersState } from '@/store/users';

export default Vue.extend({
  name: 'BoxMentoringTransfer',
  props: {
    user: Object as PropType<UserEntity>,
  },

  data() {
    return {
      transfers: {},
      isTransferAvailable: false,
    };
  },
  computed: {
    ...mapState<TasksState>('tasks', {
      mentees: (state: TasksState) => state.mentees,
    }),
    ...mapState<UsersState>('users', {
      users: (state: UsersState) =>
        state.me.attributes.mentoringType === MentoringType.Mentor
          ? [state.me, ...state.stream.users]
          : state.stream.users,
    }),
    ...mapGetters('users', {
      getUserById: 'getUserById',
    }),
    mentors() {
      return this.users
        .filter((user) => user.attributes.mentoringType === MentoringType.Mentor)
        .sort((a, b) =>
          this.getFullName(a) > this.getFullName(b) ? 1 : this.getFullName(a) < this.getFullName(b) ? -1 : 0,
        );
    },
  },
  methods: {
    getFullName(user: UserEntity) {
      return `${user.identity.attributes.firstName} ${user.identity.attributes.lastName}`;
    },
    getUserMentor(user: UserEntity) {
      return this.getUserById(user.mentorId);
    },
    addTransfer(mentee: UserEntity, mentor: UserEntity) {
      this.transfers[mentee.id] = mentor;
      this.isTransferAvailable = this.checkIsTransferAvailable();
    },
    checkIsTransferAvailable() {
      for (const menteeId in this.transfers) {
        const menteeRecord = this.mentees.find((mentee) => mentee.user.id === +menteeId);
        if (menteeRecord?.user.tempMentorId) {
          if (menteeRecord.user.tempMentorId !== this.transfers[menteeId].id) {
            return true;
          }
        } else {
          if (menteeRecord.user.mentorId !== this.transfers[menteeId].id) {
            return true;
          }
        }
      }
      return false;
    },
    async doTransfer() {
      const actualTransfers = [];
      for (const menteeId in this.transfers) {
        const menteeRecord = this.mentees.find((mentee) => mentee.user.id === +menteeId);
        const mentorId = this.transfers[menteeId].id;
        if (menteeRecord?.user.tempMentorId) {
          if (menteeRecord.user.tempMentorId !== mentorId) {
            console.log(`Change tempMentee from ${menteeRecord.user.tempMentorId} to ${mentorId}`);
            actualTransfers.push({
              menteeId: +menteeId,
              mentorId: mentorId !== menteeRecord.user.mentorId ? mentorId : null,
            });
          }
        } else {
          if (menteeRecord.user.mentorId !== mentorId) {
            console.log(`Set tempMentee for ${menteeId} to ${mentorId}`);
            actualTransfers.push({ menteeId: +menteeId, mentorId });
          }
        }
      }
      if (actualTransfers.length > 0) {
        await this.$store.dispatch(new TransferUsers(actualTransfers));
      }
    },
  },
});
