





























































































import Vue from 'vue';
import MentoringTaskList from '@/components/MentoringTaskList.vue';
import MentoringProfile from '@/components/MentoringProfile.vue';
import MentoringFeed from '@/components/MentoringFeed.vue';
import { mapGetters, mapState } from 'vuex';
import { MentoringUserRecord, SelectMentee, TasksState } from '@/store/tasks';
import { UsersState } from '@/store/users';
import { MentoringType } from '@mentessa/types';
import { GiveFeedback, ShowCommentsDialog, ShowNotificationSnackbarAction } from '@/store/ui';

export default Vue.extend({
  name: 'Mentoring',
  props: {
    select: String,
    comments: String,
    evaluate: String,
    evaluateUserId: Number,
    evaluateTaskSetId: Number,
  },
  data: () => ({
    showAddTaskSnackbar: true,
    stages: {
      monthlyGoalsInitialFilling: {
        isActive: true,
      },
      weeklyTasksInitialFilling: {
        isActive: false,
      },
    },
  }),
  components: { MentoringFeed, MentoringProfile, MentoringTaskList },
  computed: {
    ...mapState<TasksState>('tasks', {
      activityFeed: (state: TasksState) => state.activityFeed,
      mentees: (state: TasksState) => state.mentees,
      mentor: (state: TasksState) => state.mentor,
      mentee: (state: TasksState) => state.mentee,
      canCreateNew: (state: TasksState) => state.canCreateNew,
    }),
    ...mapState<UsersState>('users', {
      me: (state: UsersState) => state.me,
    }),
    ...mapGetters('tasks', {
      getTaskById: 'getTaskById',
    }),
    isMentorMode() {
      return this.me?.attributes.mentoringType === MentoringType.Mentor;
    },
    mentoringUser() {
      if (this.mentor) return this.mentor;
      return this.mentee;
    },
  },
  async created() {
    if (this.comments) {
      const task = this.getTaskById(Number(this.comments));
      if (task) {
        await this.$store.dispatch(new ShowCommentsDialog(task));
      } else {
        await this.$store.dispatch(new ShowNotificationSnackbarAction(this.$t('notifications.commentExpired')));
      }
      // await this.$router.replace({ query: null });
    }
  },
  async mounted() {
    const taskSetId = this.evaluateTaskSetId;
    const userId = this.evaluateUserId;
    const forceEvaluate = taskSetId && userId;

    if (userId || taskSetId) {
      await this.$router.replace({ query: null });
      if (!forceEvaluate) {
        console.error('Wrong evaluation link', userId, taskSetId);
      }
    }
    if (this.mentees?.length > 0) {
      await this.$store.dispatch(new SelectMentee(this.mentees[0]));
      if (forceEvaluate && this.mentees[0].user.id === userId) {
        await this.$store.dispatch(new GiveFeedback(taskSetId));
      }
    } else if (forceEvaluate && this.mentor?.user.id === userId) {
      await this.$store.dispatch(new GiveFeedback(taskSetId));
    }
  },
  methods: {
    async selectMenteeRecord(user: MentoringUserRecord) {
      await this.$store.dispatch(new SelectMentee(user));
    },
  },
});
