import { UsersState } from '@/store/users';
import { TenantState } from '@/store/tenant';
import { UtilsState } from '@/store/utils';
import { MessagesState } from '@/store/messages';
import { TasksState } from '@/store/tasks';
import { EventsState } from '@/store/events';

export interface RootState {
  initialized: boolean;
  forceLogout: boolean;
  users?: UsersState;
  tenant?: TenantState;
  utils?: UtilsState;
  messages?: MessagesState;
  tasks?: TasksState;
  events?: EventsState;
}

export interface Action {
  readonly type: string;
}

export enum RootActions {
  Initialize = '[Root] Initialize',
}

export enum RootMutations {
  Initialize = '[Root] Initialize',
  Logout = '[Root] Logout',
  ForceLogout = '[Root] Force Logout',
}

export class Initialize implements Action {
  type = `${RootActions.Initialize}`;
}
