var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-1 mb-8 mx-5 py-5 px-sm-2 px-md-5 elevation-2 m-task-list",class:{ 'm-edit-mode': _vm.forceSelect || _vm.isEditMode },staticStyle:{"border-radius":"8px","position":"relative"},style:(_vm.isMonthly ? 'height: 45%; max-height: 45%' : 'height: 55%; max-height: 55%')},[_c('div',{staticClass:"d-flex justify-space-between"},[_c('div',{staticClass:"px-3 m-font-s-16 m-font-w-500"},[_vm._v(" "+_vm._s(_vm.title)+" "),(_vm.isMentorMode)?_c('div',{staticClass:"d-inline-block"},[(!_vm.evaluationAvailable && _vm.canCreateNew)?_c('v-btn',{staticClass:"mr-1",staticStyle:{"top":"-1px","width":"21px","height":"21px","border-radius":"50%"},attrs:{"x-small":"","fab":"","dark":"","depressed":"","color":"var(--v-secondary-base)"},on:{"click":_vm.switchEditMode}},[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("edit")])],1):_vm._e(),(_vm.isEditMode && _vm.canAddMoreTask)?_c('v-tooltip',{attrs:{"right":"","color":"rgba(0,0,0,.9)"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"top":"-1px","width":"21px","height":"21px","border-radius":"50%"},attrs:{"x-small":"","fab":"","dark":"","depressed":"","color":"var(--v-secondary-base)"},on:{"click":_vm.addTask}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("add")])],1)]}}],null,false,1105073523)},[_c('span',[_vm._v(_vm._s(_vm.isMonthly ? _vm.$t('tooltips.addGoal') : _vm.$t('tooltips.addTask')))])]):_vm._e()],1):_vm._e()]),(_vm.haveTasks)?_c('p',{staticClass:"mb-0 pr-2 m-font-c-secondary"},[_vm._v(_vm._s(_vm.daysLeft)+" "+_vm._s(_vm.$t('mentoring.daysLeft')))]):_vm._e()]),(_vm.haveTasks)?_c('div',{staticClass:"px-2 pt-2 pb-5 pr-sm-0 pr-md-4",staticStyle:{"overflow-y":"auto"},style:(_vm.$vuetify.breakpoint.smAndDown
        ? 'position: relative;'
        : 'position: absolute; height: calc(100% - 100px); width: calc(100% - 20px)')},_vm._l((_vm.taskSetLocal.tasks),function(task,i){return _c('Task',{key:i,attrs:{"task":task,"isMentorMode":_vm.isMentorMode,"isEditMode":_vm.isEditMode,"isMonthly":_vm.isMonthly,"stage":_vm.taskSetLocal.stage},on:{"updateTask":function (t) { return _vm.updateTasks(t, i); },"onTaskDelete":function (t) { return _vm.removeTask(t, i); }}})}),1):_c('div',{staticClass:"d-flex fill-height justify-center align-center"},[_c('p',{staticClass:"mb-2 mt-2"},[_vm._v(" "+_vm._s(_vm.canCreateNew ? _vm.isMonthly ? _vm.$t('mentoring.noGoals') : _vm.$t('mentoring.noTasks') : _vm.isMonthly ? _vm.$t('mentoring.cantCreateGoals') : _vm.$t('mentoring.cantCreateTasks'))+" ")])]),_c('div',{staticClass:"mt-3 text-center",staticStyle:{"position":"absolute","bottom":"15px","width":"100%"}},[(_vm.isEditMode && !_vm.isTasksValid)?_c('p',{staticClass:"mb-2",staticStyle:{"color":"var(--v-error-base)"}},[_c('small',[_vm._v(_vm._s(_vm.$t('validation.max70')))])]):_vm._e(),(_vm.isEditMode)?_c('ButtonWithConfirmation',{attrs:{"activatorButtonText":_vm.isMonthly ? _vm.$t('mentoring.sendMonthlyGoals') : _vm.$t('mentoring.sendWeeklyTasks'),"activatorButtonDisabled":!_vm.isUpdated || !_vm.isTasksValid,"warningMessage":_vm.isMonthly ? _vm.$t('mentoring.warnings.sendMonthly') : _vm.$t('mentoring.warnings.sendWeekly'),"confirmButtonText":_vm.isMonthly ? _vm.$t('mentoring.sendMonthlyGoals') : _vm.$t('mentoring.sendWeeklyTasks')},on:{"confirmed":_vm.sendTasks}}):_vm._e(),(_vm.validationAvailable)?_c('ButtonWithConfirmation',{attrs:{"activatorButtonText":_vm.isMonthly ? _vm.$t('mentoring.submitMonthlyForValidation') : _vm.$t('mentoring.submitWeeklyForValidation'),"warningMessage":_vm.isMonthly
          ? _vm.$t('mentoring.warnings.submitMonthlyForValidation')
          : _vm.$t('mentoring.warnings.submitWeeklyForValidation'),"confirmButtonText":_vm.isMonthly ? _vm.$t('mentoring.submitMonthlyForValidation') : _vm.$t('mentoring.submitWeeklyForValidation')},on:{"confirmed":_vm.submitForValidation}}):_vm._e(),(_vm.evaluationAvailable)?_c('ButtonWithConfirmation',{attrs:{"activatorButtonText":_vm.$t('mentoring.submitEvaluation'),"warningMessage":_vm.isMonthly ? _vm.$t('mentoring.warnings.submitMonthlyEvaluation') : _vm.$t('mentoring.warnings.submitWeeklyEvaluation'),"confirmButtonText":_vm.$t('mentoring.submitEvaluation')},on:{"confirmed":_vm.submitEvaluation}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }