


















































import Vue from 'vue';

export default Vue.extend({
  props: {
    isLoading: Boolean,
  },
  name: 'Loader',
});
