







































import Task from '@/components/DashboardTaskList/Task.vue';
import Vue, { PropType } from 'vue';
import { TaskSetEntity } from '@mentessa/types';
import { mapState } from 'vuex';
import { UsersState } from '@/store/users';
import { UIState } from '@/store/ui';
import { format, parseISO } from 'date-fns';
import { taskSetSuccessRate } from '@/utils/ratings';

function formatDate(date: string) {
  return format(parseISO(date), 'd.M.yyyy');
}

export default Vue.extend({
  name: 'TaskList',
  props: {
    isMonthly: Boolean,
    taskSet: { type: Object as PropType<TaskSetEntity> },
    title: String,
    isBordered: Boolean,
    periodCount: Number,
  },
  components: { Task },
  data: () => ({}),
  computed: {
    ...mapState<UsersState>('users', {
      me: (state: UsersState) => state.me,
    }),
    ...mapState<UIState>('ui', {
      editGoals: (state: UIState) => state.ui.editGoals,
      editTasks: (state: UIState) => state.ui.editTasks,
    }),
    daysLeft() {
      if (!this.taskSet?.tasks) {
        return 0;
      }
      // ToDo: Remove this crazy hack with time-shifting
      const today = new Date().getTime() - 1000 * 60 * 60 * 12; // Sub 12 hours to compensate time zone
      const days = Math.ceil((Date.parse(this.taskSet.due) - today) / (1000 * 60 * 60 * 24));
      return days >= 0 ? days : 0;
    },
    haveTasks() {
      return this.taskSet?.tasks?.length;
    },
    dueDate() {
      return formatDate(this.taskSet.due);
    },
    fromDate() {
      return formatDate(this.taskSet.from);
    },
    rating() {
      if (this.isMonthly) {
        return `${Math.round(taskSetSuccessRate(this.taskSet) * 100)}%`;
      }
      const validated = this.taskSet.tasks.reduce((acc, t) => acc + (t.isEvaluated ? 1 : 0), 0);
      const rating = validated / this.taskSet.tasks.length;
      return (rating * 5).toFixed(1);
    },
  },
});
