<template>
  <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.15041 3.53561H6.30042V4.56695C6.30042 5.2981 5.69479 5.89296 4.95042 5.89296C4.20604 5.89296 3.60042 5.2981 3.60042 4.56695V2.32748L2.38355 3.04573C2.02167 3.25752 1.80042 3.64243 1.80042 4.05497V4.92608L0.300427 5.77693C0.0135526 5.939 -0.0858222 6.29997 0.0810525 6.58174L1.58105 9.1343C1.74605 9.41608 2.11355 9.51184 2.40042 9.34978L4.33917 8.2503H6.90041C7.56229 8.2503 8.10041 7.72174 8.10041 7.07163H8.40041C8.73229 7.07163 9.00041 6.80827 9.00041 6.48229V5.30362H9.15041C9.39978 5.30362 9.60041 5.10656 9.60041 4.86162V3.97762C9.60041 3.73267 9.39978 3.53561 9.15041 3.53561ZM11.9198 2.84683L10.4198 0.294269C10.2548 0.0124933 9.88729 -0.0832737 9.60041 0.0787936L7.66166 1.17827H5.74542C5.52042 1.17827 5.30104 1.24089 5.10979 1.35691L4.48167 1.74182C4.30542 1.84864 4.20042 2.03833 4.20042 2.24092V4.56695C4.20042 4.97396 4.53604 5.30362 4.95042 5.30362C5.36479 5.30362 5.70042 4.97396 5.70042 4.56695V2.94628H9.15041C9.72978 2.94628 10.2004 3.40854 10.2004 3.97762V4.50249L11.7004 3.65164C11.9873 3.48773 12.0848 3.1286 11.9198 2.84683Z"
      fill="#fff"
    />
  </svg>
</template>

<script>
export default {
  name: 'Handshake',
};
</script>

<style scoped></style>
