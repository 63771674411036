









































































































import Vue from 'vue';
import { BadgeType, ShowWelcome, WelcomeType } from '@/store/ui';
import { mapState } from 'vuex';
import { ReadRules, UsersState } from '@/store/users';

export default Vue.extend({
  name: 'CommunityRules',
  computed: {
    ...mapState<UsersState>('users', {
      me: (state: UsersState) => state.me,
    }),
    isEnglish() {
      return this.$i18n.locale === 'en';
    },
  },
  methods: {
    async gotIt() {
      if (!this.me) return;
      if (this.me.attributes.rulesRead) {
        await this.$router.push('/community');
      } else {
        await this.$store.dispatch(new ReadRules());
        if (this.me.attributes.isHR) {
          await this.$store.dispatch(new ShowWelcome(WelcomeType.HRWelcome));
        } else {
          await this.$store.dispatch(new ShowWelcome(WelcomeType.BadgeReceived, { badge: BadgeType.Welcome }));
        }
      }
    },
  },
});
