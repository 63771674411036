import { ActionTree } from 'vuex';
import { CheckNewAvailability, LoadEvents, TasksActions, TasksMutations, TasksState } from './types';
import { RootState } from '@/store/types';
import { api } from '@/store/api';
import {
  ActivityEntity,
  EventVisibility,
  MentoringType,
  TaskCommentEntity,
  TaskSetEntity,
  UserEntity,
} from '@mentessa/types';
import { EditGoals, EditTasks, GuidanceType, ShowGuidance, ShowNotificationSnackbarAction } from '@/store/ui';
import i18n from '@/plugins/i18n';
import { parseISO } from 'date-fns';
import { UsersMutations } from '@/store/users';

export const actions: ActionTree<TasksState, RootState> = {
  async [TasksActions.LoadMentor]({ commit, dispatch, rootState }) {
    const response = await api.get('auth/me/mentor');
    const user: UserEntity = response?.data;
    if (user) {
      console.log(`Your mentor is ${user.identity.attributes.firstName} ${user.identity.attributes.lastName}`);
    } else {
      console.log('You have no mentor');
    }
    commit(TasksMutations.SetMentor, user);
    await dispatch(new LoadEvents(user, rootState.users.me), { root: true });
  },

  async [TasksActions.LoadMentees]({ commit }) {
    const response = await api.get('auth/me/mentees');
    const users: UserEntity[] = response?.data;
    console.log(`You have ${users.length} mentees`);

    commit(TasksMutations.SetMentees, users);
  },

  async [TasksActions.LoadTasks]({ rootState, commit }) {
    const response = await api.get('task-sets/active');
    const sets: TaskSetEntity[] = response?.data;
    const me = rootState.users.me;

    sets.forEach((set) => {
      set.tasks.sort((l, r) => l.id - r.id);
      if (me.attributes.mentoringType === MentoringType.Mentor) {
        commit(TasksMutations.SetTaskSetForMentee, set);
      } else if (me.attributes.mentoringType === MentoringType.Mentee) {
        commit(TasksMutations.SetTaskSetForMentor, set);
      }
    });
  },
  async [TasksActions.SendTasks]({ commit, dispatch }, { taskSet }) {
    const ts: TaskSetEntity = taskSet;
    if (ts.id) {
      console.log(`Update task set ${ts.id}`);
      await api.put(`task-sets/${ts.id}`, ts);
    } else {
      console.log(`Create task set`, ts);
      const response = await api.post('task-sets', {
        type: ts.type,
        stage: ts.stage,
        from: ts.from,
        due: ts.due,
        tasks: ts.tasks,
        mentee: { id: ts.mentee.id },
        mentor: { id: ts.mentor.id },
      });
      const resultSet = response?.data;
      commit(TasksMutations.SetTaskSetForMentee, resultSet);
      await dispatch(new CheckNewAvailability(ts.mentor, ts.mentee), { root: true });
    }
  },

  async [TasksActions.SelectMentee]({ rootState, commit, dispatch }, { mentee }) {
    console.log(`Select mentee`, mentee);
    await dispatch(new EditGoals(false), { root: true });
    await dispatch(new EditTasks(false), { root: true });

    commit(TasksMutations.SetMentee, mentee);
    await dispatch(new LoadEvents(rootState.users.me, mentee.user), { root: true });
    const availability = await dispatch(new CheckNewAvailability(rootState.users.me, mentee.user), { root: true });
    if (
      !mentee.tasks?.goals &&
      availability.goals &&
      rootState.users.me.attributes.mentoringType === MentoringType.Mentor
    ) {
      await dispatch(new ShowGuidance(GuidanceType.MentorWelcome), { root: true });
    }
  },

  async [TasksActions.SetTaskSetStage]({ dispatch, commit }, { taskSet, stage }) {
    console.log(`Set taskSet ${taskSet.id} stage from ${taskSet.stage} to ${stage}`);
    try {
      const response = await api.post(`task-sets/${taskSet.id}/stage`, { value: stage });
      commit(TasksMutations.SetTaskSetStage, { taskSet, stage });
      // await dispatch(
      //   new ShowNotificationSnackbarAction(
      //     i18n
      //       .t(
      //         (taskSet.type = TaskSetType.Monthly
      //           ? 'mentoring.notifications.goalDone'
      //           : 'mentoring.notifications.taskDone'),
      //       )
      //       .toString(),
      //   ),
      //   {
      //     root: true,
      //   },
      // );
      return response != null && response.status === 201;
    } catch (e) {
      console.error('Error while send stage.', e);
      await dispatch(new ShowNotificationSnackbarAction(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
    return false;
  },

  async [TasksActions.SetTaskDone]({ dispatch, commit }, { task, status }) {
    console.log(`Set task ${task.id} isDone from ${task.isDone} to ${status}`);
    try {
      const response = await api.post(`tasks/${task.id}/done`, { value: status });
      commit(TasksMutations.SetTaskDone, { task, status });
      // await dispatch(
      //   new ShowNotificationSnackbarAction(
      //     i18n.t(status ? 'mentoring.notifications.taskDone' : 'mentoring.notifications.taskUndone').toString(),
      //   ),
      //   {
      //     root: true,
      //   },
      // );
      return response != null && response.status === 201;
    } catch (e) {
      console.error('Error while send task done.', e);
      await dispatch(new ShowNotificationSnackbarAction(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
    return false;
  },

  async [TasksActions.SetTaskEvaluated]({ dispatch, commit }, { task, status }) {
    console.log(`Set task ${task.id} isEvaluated from ${task.isEvaluated} to ${status}`);
    try {
      const response = await api.post(`tasks/${task.id}/evaluated`, { value: status });
      commit(TasksMutations.SetTaskEvaluated, { task, status });
      // await dispatch(new ShowNotificationSnackbarAction(i18n.t('mentoring.notifications.taskEvaluated').toString()), {
      //   root: true,
      // });
      return response != null && response.status === 201;
    } catch (e) {
      console.error('Error while send evaluated.', e);
      await dispatch(new ShowNotificationSnackbarAction(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
    return false;
  },

  async [TasksActions.SetTaskProgress]({ dispatch, commit }, { task, value }) {
    // console.log(`Set task ${task.id} progress from ${task.progress} to ${value}`);
    try {
      const response = await api.post(`tasks/${task.id}/progress`, { value });
      commit(TasksMutations.SetTaskProgress, { task, value });
      // await dispatch(new ShowNotificationSnackbarAction(i18n.t('mentoring.notifications.progressSet').toString()), {
      //   root: true,
      // });
      return response != null && response.status === 201;
    } catch (e) {
      console.error('Error while send task progress.', e);
      await dispatch(new ShowNotificationSnackbarAction(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
    return false;
  },

  async [TasksActions.LoadEvents]({ commit, rootState }, { mentee, mentor }) {
    console.log(`Load events [${mentor.id}, ${mentee.id}]`);
    const response = await api.get(`events`, { params: { mentee: mentee.id, mentor: mentor.id } });
    const activities: ActivityEntity[] = response?.data;
    if (activities) {
      commit(
        TasksMutations.SetEvents,
        activities.filter(
          (activity) =>
            activity.visibility === EventVisibility.Public ||
            (activity.visibility === EventVisibility.HR && rootState.users.me.attributes.isHR),
        ),
      );
    }
  },

  async [TasksActions.ReadEvent]({ commit }, { event }) {
    try {
      await api.post(`events/${event.id}/read`);
      commit(TasksMutations.SetEventRead, event);
    } catch (e) {
      console.error('Error while ReadEvent', e);
    }
  },

  async [TasksActions.LoadTaskComments]({ dispatch, commit }, { task }) {
    try {
      const response = await api.get(`tasks/${task.id}/comments`);
      const comments: TaskCommentEntity[] = response.data?.map((comment) => ({ ...comment, task: { id: task.id } }));
      if (comments) {
        commit(TasksMutations.SetTaskComments, comments);
      }
    } catch (e) {
      console.error('Error while loading task comments.', e);
      await dispatch(new ShowNotificationSnackbarAction(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },

  async [TasksActions.SendTaskComment]({ dispatch, commit }, { task, value }) {
    try {
      const response = await api.post(`tasks/${task.id}/comments`, value);
      const comment: TaskCommentEntity = response.data;
      if (comment) {
        commit(TasksMutations.AppendTaskComment, comment);
      }
    } catch (e) {
      console.error('Error while sending task comment.', e);
      await dispatch(new ShowNotificationSnackbarAction(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },

  async [TasksActions.LoadAllEvents]({ commit, rootState }, { date }) {
    if (!date) date = new Date();
    // console.log(`Load all events`, date);
    try {
      const response = await api.get(`events`, { params: { date } });
      const activities: ActivityEntity[] = response?.data;
      if (activities) {
        commit(
          TasksMutations.SetEvents,
          activities.filter(
            (activity) =>
              activity.visibility === EventVisibility.Public ||
              (activity.visibility === EventVisibility.HR && rootState.users.me.attributes.isHR),
          ),
        );
      }
    } catch (e) {
      console.error('Cannot load all activities', e);
    }
  },

  async [TasksActions.CheckNewAvailability]({ commit }, { mentor, mentee }) {
    console.log(`Check availability for [${mentor.id}, ${mentee.id}]`);
    try {
      const response = await api.get('task-sets/availability', { params: { mentor: mentor.id, mentee: mentee.id } });
      const availability = response?.data;
      commit(TasksMutations.SetNewAvailability, availability);
      return availability;
    } catch (e) {
      console.error('Cannot load availability', e);
    }
    return { goals: false, tasks: false };
  },

  async [TasksActions.SetTaskState]({ dispatch, commit }, { task, state }) {
    console.log(`Set task ${task.id} state from ${task.state} to ${state}`);
    try {
      const response = await api.post(`tasks/${task.id}/state`, { state });
      commit(TasksMutations.SetTaskState, { task, state });
      return response != null && response.status === 201;
    } catch (e) {
      console.error('Error while set task state.', e);
      await dispatch(new ShowNotificationSnackbarAction(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
    return false;
  },

  async [TasksActions.SetTaskText]({ dispatch, commit }, { task, text }) {
    console.log(`Set task ${task.id} text`);
    try {
      const response = await api.post(`tasks/${task.id}/text`, { text });
      commit(TasksMutations.SetTaskText, { task, text });
      return response != null && response.status === 201;
    } catch (e) {
      console.error('Error while set task text.', e);
      await dispatch(new ShowNotificationSnackbarAction(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
    return false;
  },

  async [TasksActions.DeleteTask]({ dispatch, commit }, { task }) {
    console.log(`Delete task ${task.id}`);
    try {
      const response = await api.delete(`tasks/${task.id}`);
      commit(TasksMutations.RemoveTaskFromTaskSet, { task });
      return response != null && response.status === 200;
    } catch (e) {
      console.error('Error while delete task.', e);
      await dispatch(new ShowNotificationSnackbarAction(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
    return false;
  },

  async [TasksActions.LoadTasksPeriod]({ dispatch, commit }) {
    try {
      const response = await api.get(`tasks/period`);
      const period = { begin: parseISO(response.data.begin), end: parseISO(response.data.end) };
      commit(TasksMutations.SetPeriod, period);
      return response?.status === 200;
    } catch (e) {
      console.error('Error while load tasks period.', e);
      await dispatch(new ShowNotificationSnackbarAction(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
    return false;
  },

  async [TasksActions.LoadPausedTasks]({ dispatch }, { mentee, taskSetType }) {
    try {
      const response = await api.get(`tasks/paused?menteeId=${mentee.id}&type=${taskSetType}`);
      return response?.data;
    } catch (e) {
      console.error('Error while load paused tasks.', e);
      await dispatch(new ShowNotificationSnackbarAction(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
    return false;
  },

  async [TasksActions.LoadAllTasks]({ commit, dispatch }) {
    try {
      const response = await api.get('task-sets');
      const taskSets = response?.data ?? [];
      commit(TasksMutations.SetAllTasks, taskSets);
      commit(`users/${UsersMutations.AssignTaskSets}`, taskSets, { root: true });
      return taskSets;
    } catch (e) {
      console.error('Error while load all tasks.', e);
      await dispatch(new ShowNotificationSnackbarAction(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
    return false;
  },

  async [TasksActions.LoadClosedTasks]({ commit, dispatch }) {
    try {
      const response = await api.get('task-sets/closed');
      const taskSets = response?.data ?? [];
      commit(TasksMutations.SetClosedTasks, taskSets);
      return taskSets;
    } catch (e) {
      console.error('Error while load closed tasks.', e);
      await dispatch(new ShowNotificationSnackbarAction(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
    return false;
  },
};
