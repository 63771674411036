










































































































































































import Vue, { PropType } from 'vue';
import { TaskEntity, TaskSetStage, TaskState } from '@mentessa/types';
import { SetTaskDone, SetTaskEvaluated, SetTaskProgress, SetTaskState, SetTaskText } from '@/store/tasks';
import { ShowCommentsDialog } from '@/store/ui';
import LinkWithConfirmation from '@/components/LinkWithConfirmation.vue';

export default Vue.extend({
  name: 'MentoringTask',
  components: {
    LinkWithConfirmation,
  },
  props: {
    task: { type: Object as PropType<TaskEntity> },
    isMentorMode: Boolean,
    isEditMode: Boolean,
    isMonthly: Boolean,
    stage: { type: Number as PropType<TaskSetStage> },
  },
  data: () => ({
    localTask: {
      id: undefined,
      text: '',
      progress: 0,
      isDone: false,
      isEvaluated: false,
    },
    dialog: false,
    isLocalEditMode: false,
  }),
  computed: {
    isValidField() {
      return this.localTask.text.length > 70 && this.localTask.text.length > 0;
    },
    evaluationAvailable() {
      return (
        this.isMentorMode &&
        this.stage === TaskSetStage.Review &&
        (this.isMonthly || this.task?.isDone) &&
        !this.isPaused
      );
    },
    executionAvailable() {
      return !this.isMentorMode && this.stage === TaskSetStage.Active && !this.isMonthly;
    },
    changesAvailable() {
      return (
        !this.isPaused &&
        ((!this.isMentorMode && this.stage === TaskSetStage.Active) ||
          (this.isMentorMode && (this.localTask.isDone || this.stage === TaskSetStage.Review)))
      );
    },
    isPaused() {
      return this.task.state === TaskState.Pause;
    },
    taskClasses() {
      return { 'task-paused': this.isPaused };
    },
    taskSetStateActionTitle() {
      return this.$t(this.isPaused ? 'mentoring.resume' : 'mentoring.pause');
    },
    approveTooltip() {
      return this.isMonthly
        ? this.localTask?.isEvaluated
          ? this.$t('tooltips.unApproveGoal')
          : this.$t('tooltips.approveGoal')
        : this.localTask?.isEvaluated
        ? this.$t('tooltips.unApproveTask')
        : this.$t('tooltips.approveTask');
    },
  },
  watch: {
    // Required for switching between mentees
    task() {
      this.updateLocalTask();
    },
    localTask: {
      deep: true,
      handler(oldValue: TaskEntity, newValue: TaskEntity) {
        if (this.isEditMode) {
          this.$emit('updateTask', newValue);
        }
      },
    },
  },
  methods: {
    updateLocalTask() {
      if (this.localTask.id !== this.task.id) this.localTask.id = this.task.id;
      if (this.localTask.text !== this.task.text) this.localTask.text = this.task.text;
      if (this.localTask.progress !== this.task.progress) this.localTask.progress = this.task.progress;
      if (this.localTask.isDone !== this.task.isDone) this.localTask.isDone = this.task.isDone;
      if (this.localTask.isEvaluated !== this.task.isEvaluated) this.localTask.isEvaluated = this.task.isEvaluated;
    },
    async setTaskEvaluated() {
      // if (this.localTask.isEvaluated) await this.$store.dispatch(new SetTaskEvaluated(this.task, true));
      await this.$store.dispatch(new SetTaskEvaluated(this.task, this.localTask.isEvaluated));
    },
    async setTaskDone() {
      await this.$store.dispatch(new SetTaskDone(this.task, this.localTask.isDone));
    },
    async setProgress(value) {
      await this.$store.dispatch(new SetTaskProgress(this.task, value));
    },
    async showCommentsDialog() {
      await this.$store.dispatch(new ShowCommentsDialog(this.task));
    },
    // async openComments() {
    //   // To-Do
    //   console.log('To-Do: Open comments');
    // },
    async togglePause() {
      await this.$store.dispatch(
        new SetTaskState(this.task, this.task.state === TaskState.Active ? TaskState.Pause : TaskState.Active),
      );
    },
    handleEdit() {
      this.isLocalEditMode = true;
    },
    async saveChanges() {
      if (this.localTask.text !== this.task.text) {
        this.$emit('updateTask', this.localTask);
        await this.$store.dispatch(new SetTaskText(this.task, this.localTask.text));
      }
      this.isLocalEditMode = false;
    },
    async deleteTask() {
      await this.$emit('onTaskDelete', this.task);
    },
  },
  mounted() {
    this.updateLocalTask();
  },
});
