<template>
  <div class="m-mentoring-details d-flex flex-row mt-2 mt-sm-6" style="width: 100%">
    <div class="d-flex flex-grow-1" style="max-height: calc(100vh - 80px)">
      <div class="d-flex flex-column flex-grow-1" style="flex-basis: 50%">
        <div class="m-block d-flex flex-grow-1 mt-1 mb-8 mx-5 elevation-2" style="height: 100px; max-height: 100px">
          <div class="flex-grow-1 m-font-s-14 m-font-w-500 m-font-c-primary">Mentee</div>
          <div class="flex-grow-1 m-font-s-14 m-font-w-500">Mentor</div>
        </div>
        <div
          class="m-block mt-1 mb-8 mx-5 elevation-2"
          style="height: calc(100% - 200px); max-height: calc(100% - 200px)"
        >
          <p class="m-font-s-16 m-font-w-500">{{ $t('mentoringDetails.monthlyGoals') }}</p>
        </div>
        <div class="m-block mt-1 mb-8 mx-5 elevation-2" style="height: 100px; max-height: 100px">
          <p class="m-font-s-16 m-font-w-500">{{ $t('mentoringDetails.earnedBadges') }}</p>
        </div>
      </div>
      <div class="d-flex flex-column flex-grow-1" style="flex-basis: 50%">
        <div class="m-block mt-1 mb-8 mx-5 elevation-2" style="height: 100%; max-height: 100%">
          <p class="m-font-s-16 m-font-w-500">{{ $t('mentoringDetails.weeklyTasks') }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MentoringDetails',
};
</script>

<style scoped>
.m-mentoring-details {
}
.m-block {
  padding: 15px;
  border-radius: 8px;
}
</style>
