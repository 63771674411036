

























import Vue from 'vue';

export default Vue.extend({
  name: 'ChatSearch',
  props: {
    items: Array,
    value: String,
  },
  data: () => ({
    search: undefined,
    searchToken: undefined,
  }),
  watch: {
    value(value) {
      this.searchToken = value;
    },
  },
});
