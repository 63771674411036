






















import { VSelect } from 'vuetify/lib';
import { mapGetters, mapState } from 'vuex';
import { TenantState } from '@/store/tenant';

export default VSelect.extend({
  name: 'SelectMultiple',
  props: {
    hint: String,
    validationHint: String,
    itemSource: String,
    value: Array,
  },
  data: function () {
    return {};
  },
  computed: {
    ...mapGetters('tenant', {
      preparedItems: 'itemsForSource',
    }),
    ...mapState<TenantState>('tenant', {
      tenant: (state: TenantState) => state.tenant,
    }),
  },
  watch: {
    //
  },
  methods: {
    removeChip: function (data) {
      const index = data.index;
      this.$emit('input', [...this.value.slice(0, index), ...this.value.slice(index + 1)]);
      this.$emit('change', [...this.value.slice(0, index), ...this.value.slice(index + 1)]);
    },
  },
  mounted(): void {
    //
  },
});
