




























import Vue, { PropType } from 'vue';
import { ActivityEntity, EventType, UserEntity } from '@mentessa/types';
import { mapState } from 'vuex';
import { UsersState } from '@/store/users';
import { ReadEvent, TasksState } from '@/store/tasks';
import { format, parseISO } from 'date-fns';

function getFullName(user: UserEntity) {
  return `${user.identity.attributes.firstName} ${user.identity.attributes.lastName}`;
}

function formatDate(date: string) {
  return format(parseISO(date), 'd.M.yyyy');
}

export default Vue.extend({
  name: 'MentoringFeedRecord',
  props: {
    record: { type: Object as PropType<ActivityEntity> },
  },
  computed: {
    ...mapState<UsersState>('users', {
      me: (state: UsersState) => state.me,
    }),
    ...mapState<TasksState>('tasks', {
      mentee: (state: TasksState) => state.mentee,
      mentor: (state: TasksState) => state.mentor,
    }),
    isUnread() {
      return this.me.id !== this.record.sender.id && !this.record.isRead;
    },
    text() {
      switch (this.record.event) {
        case EventType.GoalsSent:
          if (this.me.id === this.record.sender.id) {
            return this.$t('activities.goalsSent.mentor', {
              user: getFullName(this.mentee.user),
              from: formatDate(this.record.attributes.interval.from),
              to: formatDate(this.record.attributes.interval.to),
            });
          } else {
            return this.$t('activities.goalsSent.mentee', {
              user: getFullName(this.record.sender),
              from: formatDate(this.record.attributes.interval.from),
              to: formatDate(this.record.attributes.interval.to),
            });
          }
        case EventType.TasksSent:
          if (this.me.id === this.record.mentorId) {
            return this.$t('activities.tasksSent.mentor', {
              user: getFullName(this.mentee.user),
              from: formatDate(this.record.attributes.interval.from),
              to: formatDate(this.record.attributes.interval.to),
            });
          } else {
            return this.$t('activities.tasksSent.mentee', {
              user: getFullName(this.record.sender),
              from: formatDate(this.record.attributes.interval.from),
              to: formatDate(this.record.attributes.interval.to),
            });
          }
        case EventType.GoalComment:
          return this.$t('activities.goalCommented', {
            user: getFullName(this.record.sender),
            title: this.record.attributes.title,
          });
        case EventType.TaskComment:
          return this.$t('activities.taskCommented', {
            user: getFullName(this.record.sender),
            title: this.record.attributes.title,
          });
        case EventType.GoalsComplete:
          return this.$t('activities.goalCompleted', {
            user: getFullName(this.record.sender),
            title: this.record.attributes.title,
          });
        case EventType.TasksComplete:
          return this.$t('activities.taskCompleted', {
            user: getFullName(this.record.sender),
            title: this.record.attributes.title,
          });
        case EventType.GoalsNotDone:
          return this.$t('activities.goalNotDone', {
            user: getFullName(this.record.sender),
            title: this.record.attributes.title,
          });
        case EventType.TasksNotDone:
          return this.$t('activities.taskNotDone', {
            user: getFullName(this.record.sender),
            title: this.record.attributes.title,
          });
        case EventType.GoalsApproved:
          return this.$t('activities.goalApproved', {
            user: getFullName(this.record.sender),
            title: this.record.attributes.title,
          });
        case EventType.TasksApproved:
          return this.$t('activities.taskApproved', {
            user: getFullName(this.record.sender),
            title: this.record.attributes.title,
          });
        case EventType.GoalsNotApproved:
          return this.$t('activities.goalNotDone', {
            user: getFullName(this.record.sender),
            title: this.record.attributes.title,
          });
        case EventType.TasksNotApproved:
          return this.$t('activities.taskNotDone', {
            user: getFullName(this.record.sender),
            title: this.record.attributes.title,
          });
        case EventType.GoalsLastEvaluation:
          return this.$t('activities.goalFinalEvaluation', {
            user: getFullName(this.record.sender),
            from: formatDate(this.record.attributes.interval.from),
            to: formatDate(this.record.attributes.interval.to),
          });
        case EventType.TasksLastEvaluation:
          return this.$t('activities.taskFinalEvaluation', {
            user: getFullName(this.record.sender),
            from: formatDate(this.record.attributes.interval.from),
            to: formatDate(this.record.attributes.interval.to),
          });
        case EventType.BadgeReceived:
          if (this.record.attributes.title === 'Support') {
            if (this.me.id === this.record.mentorId) {
              return this.$t('activities.newBadgeSupportMentor');
            }
            return this.$t('activities.newBadgeSupport');
          } else if (this.record.attributes.title === 'LetTalk') {
            return this.$t('activities.newBadgeLetsTalk');
          }
          return this.$t('activities.newBadge', {
            user: getFullName(this.record.sender),
            title: this.$t(`badges.${this.record.attributes.title}`),
          });
        case EventType.GoalsSubmitted:
          if (this.me.id === this.record.sender.id) {
            return this.$t('activities.goalsSubmitted.mentor', {
              user: getFullName(this.mentor.user),
              from: formatDate(this.record.attributes.interval.from),
              to: formatDate(this.record.attributes.interval.to),
            });
          } else {
            return this.$t('activities.goalsSubmitted.mentee', {
              user: getFullName(this.record.sender),
              from: formatDate(this.record.attributes.interval.from),
              to: formatDate(this.record.attributes.interval.to),
            });
          }
        case EventType.TasksSubmitted:
          if (this.me.id === this.record.mentorId) {
            return this.$t('activities.tasksSubmitted.mentor', {
              user: getFullName(this.mentee.user),
              from: formatDate(this.record.attributes.interval.from),
              to: formatDate(this.record.attributes.interval.to),
            });
          } else {
            return this.$t('activities.tasksSubmitted.mentee', {
              user: getFullName(this.record.sender),
              from: formatDate(this.record.attributes.interval.from),
              to: formatDate(this.record.attributes.interval.to),
            });
          }
        case EventType.GoalPaused:
          return this.$t('activities.goalPaused', {
            user: getFullName(this.record.sender),
            title: this.record.attributes.title,
          });
        case EventType.GoalResumed:
          return this.$t('activities.goalResumed', {
            user: getFullName(this.record.sender),
            title: this.record.attributes.title,
          });
        case EventType.TaskPaused:
          return this.$t('activities.taskPaused', {
            user: getFullName(this.record.sender),
            title: this.record.attributes.title,
          });
        case EventType.TaskResumed:
          return this.$t('activities.taskResumed', {
            user: getFullName(this.record.sender),
            title: this.record.attributes.title,
          });
        case EventType.GoalDeleted:
          return this.$t('activities.goalDeleted', {
            user: getFullName(this.record.sender),
            title: this.record.attributes.title,
          });
        case EventType.TaskDeleted:
          return this.$t('activities.taskDeleted', {
            user: getFullName(this.record.sender),
            title: this.record.attributes.title,
          });
        case EventType.GoalChanged:
          return this.$t('activities.goalChanged', {
            user: getFullName(this.record.sender),
            title: this.record.attributes.title,
          });
        case EventType.TaskChanged:
          return this.$t('activities.taskChanged', {
            user: getFullName(this.record.sender),
            title: this.record.attributes.title,
          });
      }
      return '';
    },
    formattedDate() {
      return format(parseISO(this.record.createdAt), 'd.M.yyyy hh.mm');
    },
  },
  methods: {
    async readEvent() {
      await this.$store.dispatch(new ReadEvent(this.record));
    },
  },
});
