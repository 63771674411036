









import Vue from 'vue';

export default Vue.extend({
  name: 'Badge',
  props: {
    image: String,
    title: String,
  },
});
