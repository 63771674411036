import { ActionTree } from 'vuex';
import { RootState } from '@/store/types';
import { CoupleStatistics, TotalStatistics, UIActions, UIMutations, UIState } from '@/store/ui/types';
import { api } from '@/store/api';

export const actions: ActionTree<UIState, RootState> = {
  async [UIActions.ShowNotificationDialog]({ commit }, { message, variant, buttonText }) {
    commit(UIMutations.SetNotificationDialog, { message, variant, buttonText });
  },
  async [UIActions.ShowNotificationSnackbar]({ commit }, { message }) {
    commit(UIMutations.SetNotificationSnackbar, { message });
  },
  async [UIActions.ShowGuidance]({ commit }, { guidanceType, options }) {
    commit(UIMutations.SetGuidanceType, guidanceType);
    commit(UIMutations.SetGuidanceOptions, options);
    setTimeout(() => commit(UIMutations.ShowGuidanceSnackbarVisibility, true), 5000);
  },
  async [UIActions.CloseGuidanceSnackbar]({ commit }) {
    commit(UIMutations.ShowGuidanceSnackbarVisibility, false);
  },
  async [UIActions.ShowWelcomeAction]({ commit }, { welcomeType, options }) {
    commit(UIMutations.ShowWelcome, { welcomeType, options });
  },
  async [UIActions.CloseWelcomeAction]({ commit }) {
    commit(UIMutations.CloseWelcome);
  },
  async [UIActions.ShowCommentsDialog]({ commit }, { task }) {
    commit(UIMutations.SetCommentsDialogTask, task);
    commit(UIMutations.SetCommentsDialogVisible, true);
  },
  async [UIActions.CloseCommentsDialog]({ commit }) {
    commit(UIMutations.SetCommentsDialogVisible, false);
  },
  async [UIActions.EditGoals]({ commit }, { isEdit }) {
    commit(UIMutations.SetEditGoals, isEdit);
  },
  async [UIActions.EditTasks]({ commit }, { isEdit }) {
    commit(UIMutations.SetEditTasks, isEdit);
  },
  async [UIActions.LoadTotalStats]({ commit }, { filterDate }) {
    try {
      const response = await api.get('stats', { params: { filterDate } });
      const stats: TotalStatistics = response?.data;
      commit(UIMutations.SetTotalStats, stats);
    } catch (e) {
      console.error('LoadTotalStats Error', e);
    }
  },
  async [UIActions.GiveFeedback]({ commit }, { taskSetId }) {
    commit(UIMutations.SetFeedbackVisible, { isVisible: true, taskSetId });
  },
  async [UIActions.ShowFeedback]({ commit }, { questionnaire }) {
    commit(UIMutations.SetFeedbackVisible, { isVisible: true, questionnaire });
  },
  async [UIActions.CloseFeedback]({ commit }) {
    commit(UIMutations.SetFeedbackVisible, { isVisible: false });
  },
  async [UIActions.LoadCoupleStats](_, { mentor, mentee, filterDate }) {
    try {
      const response = await api.get('stats', { params: { mentor: mentor.id, mentee: mentee.id, filterDate } });
      const stats: CoupleStatistics = response?.data;
      return stats;
    } catch (e) {
      console.error('LoadTotalStats Error', e);
      return undefined;
    }
  },
  async [UIActions.ShowNavigationDrawer]({ commit }) {
    commit(UIMutations.SetNavigationDrawerVisible);
  },
  async [UIActions.CloseNavigationDrawer]({ commit }) {
    commit(UIMutations.SetNavigationDrawerHidden);
  },
};
