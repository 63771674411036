















































import { mapState } from 'vuex';
import { BadgeType, CloseWelcome, GiveFeedback, ShowWelcome, UIState, WelcomeType } from '@/store/ui';
import Vue from 'vue';
import { TenantState } from '@/store/tenant';
import { UsersState } from '@/store/users';
import { MentoringType } from '@mentessa/types';
import { TasksState } from '@/store/tasks';

export default Vue.extend({
  name: 'WelcomeDialog',
  data: () => ({
    header: '',
    title: '',
    text: '',
    buttonText: '',
    emojiClass: '',
  }),
  computed: {
    ...mapState<UsersState>('users', {
      me: (state: UsersState) => state.me,
    }),
    ...mapState<TasksState>('tasks', {
      mentee: (state: TasksState) => state.mentee,
    }),
    ...mapState<UIState>('ui', {
      isVisible: (state: UIState) => state.welcomeDialog.isVisible,
      type: (state: UIState) => state.welcomeDialog.type,
      options: (state: UIState) => state.welcomeDialog.options,
    }),
    ...mapState<TenantState>('tenant', {
      logo: (state: TenantState) => state.tenant?.config.theme.logo,
    }),
    showWelcomeDialog: {
      get: function () {
        return this.isVisible;
      },
      set: async function (val) {
        if (val === false) {
          await this.$store.dispatch(new CloseWelcome());
        }
      },
    },
  },
  watch: {
    type(newType) {
      this.setType(newType);
    },
  },
  methods: {
    async buttonClickFunction(type: WelcomeType) {
      switch (type) {
        case WelcomeType.MentorWelcome:
          await this.$router.push('/mentoring');
          this.showWelcomeDialog = false;
          break;
        case WelcomeType.MenteeWelcome:
          await this.$router.push('/mentoring');
          this.showWelcomeDialog = false;
          break;
        case WelcomeType.HRWelcome:
          if (this.me.complete) {
            await this.$router.push('/community');
          } else {
            await this.$router.push('/profile');
          }
          this.showWelcomeDialog = false;
          break;
        case WelcomeType.MentorEvaluation:
          this.showWelcomeDialog = false;
          await this.$store.dispatch(new GiveFeedback(this.options.taskSetId));
          break;
        case WelcomeType.MenteeTasksCompleted:
          this.showWelcomeDialog = false;
          break;
        case WelcomeType.MenteeGoalsCompleted:
          this.showWelcomeDialog = false;
          await this.$store.dispatch(new GiveFeedback(this.options.taskSetId));
          break;
        case WelcomeType.FeedBack:
          this.showWelcomeDialog = false;
          if (this.me.attributes.mentoringType === MentoringType.Mentor) {
            if (!this.mentee.tasks.goals) {
              // setTimeout(async () => {
              //   await this.$store.dispatch(new ShowGuidance(GuidanceType.MentorAddGoals));
              // }, 4000);
            } else if (!this.mentee.tasks.weekly) {
              // setTimeout(async () => {
              //   await this.$store.dispatch(new ShowGuidance(GuidanceType.MentorAddTasks));
              // }, 4000);
            }
          }
          // ToDo: Put correct path
          break;
        case WelcomeType.BadgeReceived:
          this.showWelcomeDialog = false;
          if (this.options?.badge === BadgeType.Welcome) {
            await this.$store.dispatch(
              new ShowWelcome(
                this.me.attributes.mentoringType === MentoringType.Mentor
                  ? WelcomeType.MentorWelcome
                  : WelcomeType.MenteeWelcome,
              ),
            );
          } else {
            await this.$router.push('/badges');
          }
          break;
      }
    },

    setType(type: WelcomeType) {
      let sectionName, emojiClassName;

      const format = {};
      switch (type) {
        case WelcomeType.MentorWelcome:
          sectionName = 'mentorWelcome';
          emojiClassName = 'm-emoji-welcome';
          break;
        case WelcomeType.MenteeWelcome:
          sectionName = 'menteeWelcome';
          emojiClassName = 'm-emoji-welcome';
          break;
        case WelcomeType.HRWelcome:
          sectionName = 'hrWelcome';
          emojiClassName = 'm-emoji-welcome';
          break;
        case WelcomeType.MentorEvaluation:
          sectionName = 'mentorEvaluation';
          emojiClassName = 'm-emoji-feedback';
          break;
        case WelcomeType.MenteeTasksCompleted:
          sectionName = 'menteeTasksCompleted';
          emojiClassName = `m-emoji-goals-completed-${
            this.options.percents >= 90 ? 'wow' : this.options.percents >= 50 ? 'great' : 'good'
          }`;
          break;
        case WelcomeType.MenteeGoalsCompleted:
          sectionName = 'menteeGoalsCompleted';
          emojiClassName = `m-emoji-goals-completed-${
            this.options.percents >= 90 ? 'wow' : this.options.percents >= 50 ? 'great' : 'good'
          }`;
          break;
        case WelcomeType.FeedBack:
          sectionName = 'feedBack';
          emojiClassName = 'm-emoji-feedback';
          break;
        case WelcomeType.BadgeReceived:
          sectionName = 'badgeReceived';
          emojiClassName = `m-emoji-badge-${this.options?.badge ? BadgeType[this.options.badge] : 'GreatJob'}`;
          format['name'] = this.options?.badge
            ? this.$t('badges.' + BadgeType[this.options.badge])
            : this.$t('badges.badge');
          break;
      }

      if (sectionName && emojiClassName) {
        this.header = this.$t(`welcomeDialog.${sectionName}.header`);
        this.title = this.$t(`welcomeDialog.${sectionName}.title`, format);
        this.text = this.$t(`welcomeDialog.${sectionName}.text`);
        this.buttonText = this.$t(`welcomeDialog.${sectionName}.buttonText`);
        this.emojiClass = emojiClassName;
      }
    },
  },
});
