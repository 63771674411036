import { GetterTree } from 'vuex';
import { MessagesState } from './types';
import { RootState } from '@/store/types';

export const getters: GetterTree<MessagesState, RootState> = {
  isAllRequestsLoaded(state: MessagesState): boolean {
    const {
      requests: {
        pagination: { currentPage: current, totalPages: total },
      },
    } = state;
    return current !== 0 && current >= total;
  },
  isNewRequestsPageAvailable(state: MessagesState, getters): boolean {
    const {
      requests: { isLoading },
    } = state;
    return !(isLoading || getters.isAllRequestsLoaded);
  },
};
