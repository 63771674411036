import { TenantState } from '@/store/tenant/types';
import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { getters } from './getters';
import { mutations } from './mutations';
import { actions } from './actions';

export const initialState: TenantState = {
  tenant: undefined,
  tags: undefined,
  locations: undefined,
  usernames: undefined,
};

export const module: Module<TenantState, RootState> = {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};

export * from './types';
