<template>
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.6384 0.714355H0.622315C0.0701853 0.714355 -0.208417 1.38428 0.182797 1.77549L4.97273 6.56615V11.903C4.97273 12.1058 5.0717 12.2959 5.23787 12.4123L7.30984 13.8621C7.71862 14.1483 8.28789 13.8583 8.28789 13.3529V6.56615L13.078 1.77549C13.4684 1.38505 13.1917 0.714355 12.6384 0.714355Z"
      fill="var(--v-secondary-base)"
    />
  </svg>
</template>

<script>
export default {
  name: 'FilterIcon',
};
</script>

<style scoped></style>
