<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19 3H5C3.89 3 3 3.9 3 5V19C3 20.1 3.89 21 5 21H19C20.11 21 21 20.1 21 19V5C21 3.9 20.11 3 19 3Z"
      fill="var(--v-primary-base)"
    />
  </svg>
</template>

<script>
export default {
  name: 'CheckBoxFilled',
};
</script>

<style scoped></style>
