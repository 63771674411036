







import { mapState } from 'vuex';
import { UIState } from '@/store/ui';
import Vue from 'vue';
import { ShowNotificationSnackbarAction } from '@/store/ui';
import { notificationOptions } from '@/config';

export default Vue.extend({
  name: 'NotificationSnackbar',
  computed: {
    ...mapState<UIState>('ui', {
      message: (state: UIState) => state.notificationSnackbar.message,
    }),
  },
  watch: {
    message(newVal) {
      if (newVal) {
        setTimeout(async () => {
          await this.$store.dispatch(new ShowNotificationSnackbarAction(''));
        }, notificationOptions.snackbar.showTime);
      }
    },
  },
});
