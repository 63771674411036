












































import Vue from 'vue';
import { mapState } from 'vuex';
import { UsersState } from '@/store/users';

export default Vue.extend({
  name: 'Search',
  data: () => ({
    value: [],
    search: undefined,
    items: [],
  }),
  computed: {
    ...mapState<UsersState>('users', {
      sources: (state: UsersState) => state.filterSources,
    }),
  },
  watch: {
    search(token) {
      if (token == null || token === '') {
        this.items = [...this.value];
        return;
      }

      const items = [...this.value];
      ['name', 'location', 'division', 'department', 'role', 'skills'].forEach((src) => {
        items.push(
          ...this.sources[src]
            .filter((x) => x.toLowerCase().includes(token.toLowerCase()))
            .map((x) => ({ src, name: x })),
        );
      });
      this.items = items;
    },
  },
  methods: {
    async doFilter() {
      const preparedFilters = {};
      this.value.forEach((filter) => {
        preparedFilters[filter.src] = filter.name;
      });
      if (Object.keys(preparedFilters).length) {
        this.$emit('setFilters', preparedFilters);
      }
    },
    async clearFilters() {
      this.$emit('clearFilters');
    },
    clearSearch() {
      if (this.search != null) {
        this.search = null;
      }
    },
    clearValue() {
      if (this.value.length) {
        this.value = [];
      }
    },
    removeItem(index) {
      this.value.splice(index, 1);
      if (this.value.length === 0) {
        this.clearFilters();
      }
    },
  },
});
