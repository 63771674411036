






























































































import Vue from 'vue';
import { LoadTaskComments, SendTaskComment, TasksState } from '@/store/tasks';
import { mapState } from 'vuex';
import { format, parseISO } from 'date-fns';
import { CloseCommentsDialog, UIState } from '@/store/ui';

export default Vue.extend({
  name: 'CommentDialog',
  data: () => ({
    force: false,
    newComment: '',
    previousState: false,
    showComments: false,
  }),
  computed: {
    ...mapState<TasksState>('tasks', {
      comments: function (state: TasksState) {
        return state.comments;
      },
    }),
    ...mapState<UIState>('ui', {
      isVisible: (state: UIState) => state.commentsDialog.isVisible,
      task: (state: UIState) => state.commentsDialog.task,
    }),
    showCommentsDialog: {
      get: function () {
        return this.isVisible;
      },
      set: async function (val) {
        if (val === false) {
          await this.$store.dispatch(new CloseCommentsDialog());
        }
      },
    },
  },
  watch: {
    force(value) {
      console.log('watch force:', value);
    },
    showCommentsDialog(isVisible) {
      if (isVisible) this.loadComments();
    },
  },
  methods: {
    async sendComment() {
      const comment = {
        text: this.newComment,
        isRead: false,
      };
      await this.$store.dispatch(new SendTaskComment(this.task, comment));
      this.newComment = '';
    },
    formatDate(date: string) {
      return format(parseISO(date), 'd.M.yyyy h:m');
    },
    async loadComments() {
      await this.$store.dispatch(new LoadTaskComments(this.task));
    },
    async updateDialogState(state) {
      if (state && !this.previousState) {
        await this.$store.dispatch(new LoadTaskComments(this.task));
      }
      if (this.previousState !== this.state) this.previousState = this.state;
    },
  },
  async mounted() {
    if (this.force) {
      await this.$store.dispatch(new LoadTaskComments(this.task));
      this.showComments = true;
    }
  },
});
