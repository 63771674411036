














































import Vue from 'vue';
import { mapState } from 'vuex';
import { UsersState } from '@/store/users';

export default Vue.extend({
  name: 'FiltersSidebar',
  props: {
    isFiltersVisible: Boolean,
  },
  data() {
    return {
      filters: ['location', 'division', 'department', 'role', 'skills'],
      filterValues: {
        location: '',
        division: '',
        department: '',
        role: '',
        skills: '',
      },
    };
  },
  computed: {
    ...mapState<UsersState>('users', {
      sources: (state: UsersState) => state.filterSources,
    }),
  },
  methods: {
    getFilterSource(name: string) {
      if (this.sources[name]) {
        return this.sources[name];
      }
    },
    applyFilters() {
      this.$emit('setFilters', this.filterValues);
    },
    selectFilter(filterName: string, data) {
      this.filterValues[filterName] = data;
      this.applyFilters();
    },
    closeFilters(): void {
      this.$emit('closeFilters');
    },
    clearFilters() {
      for (const value in this.filterValues) {
        this.filterValues[value] = '';
      }
    },
  },
});
