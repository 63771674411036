























import { VTextField } from 'vuetify/lib';

export default VTextField.extend({
  name: 'TextField',
  props: {
    required: Boolean,
    hint: String,
    validationHint: String,
    value: String,
  },
});
