





















































import { MentoringType, UserCardConfig, UserCardField, UserEntity } from '@mentessa/types';
import Vue, { PropType } from 'vue';
import TextArea from '@/components/Inputs/TextArea.vue';
import TextField from '@/components/Inputs/TextField.vue';
import Language from '@/components/Inputs/Language.vue';
import SelectMultiple from '@/components/Inputs/SelectMultiple.vue';
import ComboboxMultiple from '@/components/Inputs/ComboboxMultiple.vue';
import ImagePicker from '@/components/Inputs/ImagePicker.vue';
import Switcher from '@/components/Inputs/Switcher.vue';
import Location from '@/components/Inputs/Location.vue';
import { email, length, max, required } from 'vee-validate/dist/rules';
import { extend, setInteractionMode, ValidationObserver, ValidationProvider } from 'vee-validate';
import { SaveUser, SetUserField, UpdateUserField, UsersState } from '@/store/users';
import { mapState } from 'vuex';
import { ShowNotificationSnackbarAction } from '@/store/ui';
import BoxMentoringTransfer from '@/components/BoxMentoringTransfer.vue';
import { TenantState } from '@/store/tenant';

// Note: eager is better here, but with it we have valueChanged event before invalid state changed to valid for initially invalid fields.
setInteractionMode('aggressive');

export default Vue.extend({
  name: 'ProfileEdit',
  components: {
    TextArea,
    TextField,
    ImagePicker,
    SelectMultiple,
    ComboboxMultiple,
    Switcher,
    Language,
    Location,
    ValidationProvider,
    ValidationObserver,
    BoxMentoringTransfer,
  },
  props: {
    user: Object as PropType<UserEntity>,
    cardConfig: Object as PropType<UserCardConfig>,
  },
  data: () => ({
    values: {},
    notificationsSettings: {
      name: 'Notifications',
      items: [],
      value: [],
      search: '',
    },
    notifications: [{ name: 'Corporate Division' }, { name: 'Eva Weber' }],
  }),
  computed: {
    fields(): Array<UserCardField> {
      return this.cardConfig?.fields;
    },
    ...mapState<TenantState>('tenant', {
      names: (state: TenantState) => state.usernames,
    }),
    ...mapState<UsersState>('users', {
      isComplete: (state: UsersState) => state.me.complete,
      sources: (state: UsersState) => state.filterSources,
    }),
    isMentor() {
      return this.user?.attributes.mentoringType === MentoringType.Mentor;
    },
    isAdmin() {
      return this.user?.attributes.isHR;
    },
    notificationFilterValues() {
      return [
        ...this.sources.division.map((division) => ({ entity: 'division', name: division })),
        ...this.names.map((user) => ({ entity: 'user', ...user })),
      ];
    },
  },

  methods: {
    resolvePath(object, path: string, defaultValue = undefined) {
      return path.split('.').reduce((o, p) => (o ? o[p] : defaultValue), object);
    },
    async valueChanged(path: string, value, valid: boolean) {
      if (valid) {
        // console.log('ProfileEdit::value changed', path, value, valid);
        if (Array.isArray(value)) {
          value.forEach((v, i, s) => {
            if (v.name == null) {
              s[i] = { name: v };
            }
          });
        }
        if (this.isComplete) {
          await this.$store.dispatch(new UpdateUserField(path, value));
          await this.$store.dispatch(new ShowNotificationSnackbarAction(this.$t('user.profileSaved')));
        } else {
          await this.$store.dispatch(new SetUserField(path, value));
        }
      }
    },
    loadValues() {
      const values = {};
      this.fields.forEach((field) => {
        values[field.name] = this.resolvePath(this.user, field.path);
      });
      if (this.values) {
        Object.assign(this.values, values);
      } else {
        this.values = values;
      }
      const filters = this.user.attributes.notificationFilter;
      const divisionFilters =
        filters?.division?.map((division) => ({ entity: 'division', name: division, id: division })) ?? [];
      const userFilters = filters?.user?.map((user) => ({ entity: 'user', id: user })) ?? [];
      this.notificationsSettings.value = [...divisionFilters, ...userFilters];
    },
    async save() {
      await this.$store.dispatch(new SaveUser());
      // await this.$store.dispatch(new ShowNotificationSnackbarAction(this.$t('user.profileSaved')));
      await this.$router.push('/');
    },
    async onNotificationFiltersChanged(items) {
      this.notificationsSettings.search = '';
      const filters = {};
      items.forEach((filter) => {
        if (!filters[filter.entity]) filters[filter.entity] = [];
        filters[filter.entity].push(filter.entity === 'user' ? filter.id : filter.name);
      });
      if (this.isComplete) {
        await this.$store.dispatch(new UpdateUserField('attributes.notificationFilter', filters));
        await this.$store.dispatch(new ShowNotificationSnackbarAction(this.$t('user.profileSaved')));
      } else {
        await this.$store.dispatch(new SetUserField('attributes.notificationFilter', filters));
      }
    },
  },
  async created() {
    this.loadValues();
    extend('required', {
      ...required,
      message: this.$t('validation.required'),
    });

    extend('max', {
      ...max,
      message: this.$t('validation.max'),
    });

    extend('email', {
      ...email,
      message: this.$t('validation.email'),
    });

    extend('length', {
      ...length,
      validate(value, { length }) {
        return value.length <= length;
      },
      message: this.$t('validation.length'),
    });
  },
});
