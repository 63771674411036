


























































































































import Vue from 'vue';
import DashboardTable from '@/components/DashboardTable.vue';
import { mapGetters, mapState } from 'vuex';
import { LoadManagedUsers, LoadNextStreamPage, UsersState } from '@/store/users';
import { MentoringType, TaskSetType, UserEntity } from '@mentessa/types';
import DashboardCommunityStatistics from '@/components/DashboardCommunityStatistics.vue';
import DashboardCoupleStatistics from '@/components/DashboardCoupleStatistics.vue';
import Search from '@/components/Search.vue';
import { format, parseISO } from 'date-fns';
import MonthPicker from '@/components/MonthPicker.vue';
import FiltersSidebar from '@/components/FiltersSidebar.vue';
import ModalTransferMentors from '@/components/ModalTransferMentors.vue';
import ModalTransferManager from '@/components/ModalTransferManager.vue';
import { TasksState } from '@/store/tasks';
import { getGoals, getRatingEvaluation, getRatingTotal, getTasks } from '@/utils/ratings';

export default Vue.extend({
  name: 'Dashboard',
  components: {
    DashboardCoupleStatistics,
    DashboardCommunityStatistics,
    DashboardTable,
    Search,
    MonthPicker,
    FiltersSidebar,
    ModalTransferMentors,
    ModalTransferManager,
  },
  props: {
    mentorId: Number,
    menteeId: Number,
    managerId: Number,
    month: String,
    details: String,
  },
  data: () => ({
    userFilter: null,
    selectedUserId: undefined,
    searchFilterValues: {
      location: '',
      division: '',
      department: '',
      role: '',
      skills: '',
    },
    isFiltersVisible: false,
    isTransferModalVisible: false,
    isManagerAssignModalVisible: false,
    modalData: null,

    selectedMentor: null,
    filterDate: new Date(),
    coupleCarouselIndex: 0,
    isManagerSelected: false,
    managedMentoringPairs: [],
    selectedPairs: [],
  }),
  computed: {
    ...mapState<UsersState>('users', {
      users: (state: UsersState) => state.stream.users,
      managedUsers: (state: UsersState) => state.managedUsersId,
      me: (state: UsersState) => state.me,
    }),
    ...mapState<TasksState>('tasks', {
      period: (state: TasksState) => state.period,
    }),
    ...mapGetters('users', {
      getUserById: 'getUserById',
      getUserMentees: 'getUserMentees',
      getMentoringPairs: 'getMentoringPairs',
    }),
    usersFiltered() {
      const searchValues = this.searchFilterValues;
      let result = this.users;
      if (this.me.attributes.isManager && !this.me.attributes.isHR) {
        result = result.filter((user) => this.managedUsers.includes(user.id));
      }
      if (this.filterDate) {
        result = result.filter((user) => user.taskSets?.some((taskSet) => this.compareMonth(taskSet.due as string)));
        const usersWithTaskSetsIds = result.map((user) => user.id);

        if (this.me.attributes.isHR) {
          result.push(
            ...this.users.filter(
              (user) => (user.attributes.isHR || user.attributes.isManager) && !usersWithTaskSetsIds.includes(user.id),
            ),
          );
        }
      }
      if (searchValues?.role) {
        switch (searchValues.role) {
          case 'Mentor':
            result = result.filter((user) => user.attributes.mentoringType === MentoringType.Mentor);
            break;
          case 'Menti':
            result = result.filter((user) => user.attributes.mentoringType === MentoringType.Mentee);
            break;
          case 'HR':
            result = result.filter((user) => user.attributes.isHR === true);
            break;
          case 'Menadžer':
            result = result.filter((user) => user.attributes.isManager === true);
            break;
        }
      }
      if (searchValues?.location) {
        result = result.filter(
          (user) => user.attributes.location?.toLowerCase() === searchValues.location.toLowerCase(),
        );
      }
      if (searchValues?.department) {
        result = result.filter((user) =>
          user.attributes.department?.toLowerCase().includes(searchValues.department.toLowerCase()),
        );
      }
      if (searchValues?.division) {
        result = result.filter((user) =>
          user.attributes.division?.toLowerCase().includes(searchValues.division.toLowerCase()),
        );
      }
      if (searchValues?.skills) {
        result = result.filter(
          (user) =>
            user.expertiseTags.some((tag) => tag.name.toLowerCase() === searchValues.skills.toLowerCase()) ||
            user.interestTags.some((tag) => tag.name.toLowerCase() === searchValues.skills.toLowerCase()),
        );
      }
      if (searchValues?.name) {
        const getFullName = (user) => `${user.identity.attributes.firstName} ${user.identity.attributes.lastName}`;
        result = result.filter((user) => getFullName(user).includes(searchValues.name));
      }

      if ([0, 1].includes(this.userFilter)) {
        result = result.filter(
          (user) =>
            user.attributes.mentoringType === (this.userFilter === 0 ? MentoringType.Mentor : MentoringType.Mentee),
        );
      }

      return result;
    },
    topMentors() {
      const results: { user: UserEntity; score: number }[] = [];
      this.users.forEach((user) => {
        if (user.attributes.mentoringType !== MentoringType.Mentor) return;
        results.push({ user, score: getRatingTotal(user, this.filterDate) });
      });
      return results
        .sort((l, r) => r.score - l.score)
        .slice(0, 3)
        .map((result) => result.user);
    },
    compareMonth() {
      return (dateStr: string, monthShift = 0) => {
        if (!this.filterDate) return true;
        const parsedDate = parseISO(dateStr);
        if (monthShift) {
          parsedDate.setMonth(parsedDate.getMonth() - monthShift);
        }
        return (
          parsedDate.getFullYear() === this.filterDate.getFullYear() &&
          parsedDate.getMonth() === this.filterDate.getMonth()
        );
      };
    },
    isHR() {
      return this.me?.attributes.isHR;
    },
  },
  methods: {
    toMinutes(ms) {
      return this.$t('dashboard.minutes', { count: Math.floor(ms / (1000 * 60)).toFixed(1) });
    },
    async selectUser(user: UserEntity, selected: boolean, pairedUser: UserEntity = undefined) {
      if (selected) {
        this.selectedUserId = user.id;
        if (user.attributes.isManager) {
          this.isManagerSelected = true;
          const userIds = await this.$store.dispatch(new LoadManagedUsers(user));
          if (this.$route.query.manager !== user.id.toString()) {
            await this.$router.replace({
              path: '/dashboard',
              query: {
                ...this.$route.query,
                manager: user.id.toString(),
                details: undefined,
                mentee: undefined,
                mentor: undefined,
              },
            });
          }
          this.managedMentoringPairs = this.getMentoringPairs(userIds);
        } else {
          const selectedTaskSets = user.taskSets.filter((taskSet) =>
            this.compareMonth((taskSet.due as unknown) as string),
          );

          // Make pairs from DB state. Only if date filter is disabled
          if (!this.filterDate) {
            if (user.attributes.mentoringType === MentoringType.Mentor) {
              const mentees = this.usersFiltered.filter((u) => u.mentorId === user.id);
              this.selectedPairs = mentees.map((mentee) => ({ mentee, mentor: user }));
            } else {
              const mentor = this.users.find((u) => u.id === user.mentorId);
              if (mentor) {
                this.selectedPairs = [
                  {
                    mentee: user,
                    mentor,
                  },
                ];
              } else {
                // It is possible if mentor is hidden
                console.log(`Cannot find mentor for ${user.id} with ${user.mentorId}`);
              }
            }
          } else {
            this.selectedPairs = [];
          }

          console.log(this.selectedPairs);

          // Add pairs from task-sets
          const pairs = [
            ...new Set(
              selectedTaskSets.map((taskSet) => {
                const id = user.attributes.mentoringType === MentoringType.Mentor ? taskSet.menteeId : taskSet.mentorId;
                if (id === this.me.id) return this.me;
                return this.users.find((user) => user.id === id);
              }),
            ),
          ];

          if (pairs.length) {
            pairs.forEach((pair) => {
              const testPair =
                user.attributes.mentoringType === MentoringType.Mentor
                  ? { mentor: user, mentee: pair }
                  : { mentor: pair, mentee: user };
              if (
                !this.selectedPairs.some(
                  (p) => p.mentee.id === testPair.mentee?.id && p.mentor.id === testPair.mentor?.id,
                )
              ) {
                if (testPair.mentee && testPair.mentor) {
                  this.selectedPairs.push(testPair);
                } else {
                  console.log('Cannot add pair without mentor or mentee', testPair);
                }
              }
            });
          }

          if (this.selectedPairs.length) {
            let pairIndex = undefined;
            if (pairedUser) {
              pairIndex = this.selectedPairs.findIndex(
                (pair) => pair.mentor.id === pairedUser.id && pair.mentee.id === user.id,
              );
            } else {
              pairIndex = 0;
            }
            this.coupleCarouselIndex = pairIndex;
            await this.setSelectedCouple(this.selectedPairs[pairIndex]);
          } else {
            await this.$router.replace({
              path: '/dashboard',
              query: {
                ...this.$route.query,
                details: undefined,
                mentee: undefined,
                mentor: undefined,
                manager: undefined,
              },
            });
          }
          this.isManagerSelected = false;
        }
      } else {
        this.selectedUserId = undefined;
        this.isManagerSelected = false;
        this.selectedPairs = [];
        await this.$router.replace({
          path: '/dashboard',
          // query: { ...this.$route.query, manager: user.id.toString() },
        });
      }
    },
    async setSelectedCouple(pair) {
      // this.selectedUser = user;
      // this.selectedUserId = user.id;
      if (
        this.$route.query.mentor !== pair.mentor.id.toString() ||
        this.$route.query.mentee !== pair.mentee.id.toString()
      ) {
        await this.$router.replace({
          path: '/dashboard',
          query: {
            ...this.$route.query,
            mentor: pair.mentor.id.toString(),
            mentee: pair.mentee.id.toString(),
            manager: undefined,
          },
        });
      }
    },
    async coupleCarouselChangeItem(index) {
      if (index == null || index === -1 || !this.selectedPairs?.length) {
        return;
      }
      await this.setSelectedCouple(this.selectedPairs[index]);
    },
    setSearchFilters(data, clearFilters = false) {
      this.filterDate = undefined;
      if (clearFilters) {
        this.$refs.filters.clearFilters();
      } else {
        this.$refs.search.clearValue();
      }
      this.searchFilterValues = data;
    },
    clearSearchFilters() {
      this.searchFilterValues = undefined;
    },

    getRoleTitle(user: UserEntity) {
      switch (user.attributes.mentoringType) {
        case MentoringType.Mentor:
          return this.$t('stream.card.mentor');
        case MentoringType.Mentee:
          return this.$t('stream.card.mentee');
      }
      if (user.attributes.isHR) this.$t('stream.card.hr');
      else if (user.attributes.isManager) this.$t('stream.card.manager');

      return '';
    },
    getStartDate(user: UserEntity) {
      try {
        return format(parseISO(user.attributes.startDate), 'd.M.yyyy');
      } catch (e) {
        return '-';
      }
    },
    getNotNull<T>(value: T) {
      if (value != null) return value;
      return '';
    },

    userToRow(user: UserEntity) {
      const row = [
        user.identity.attributes.firstName,
        user.identity.attributes.lastName,
        this.getNotNull(user.attributes.jobTitle),
        this.getRoleTitle(user),
        this.getStartDate(user),
        getRatingTotal(user, this.filterDate)?.toFixed(1) ?? '-',
        getRatingEvaluation(user, this.filterDate)?.toFixed(1) ?? '-',
        getTasks(user, this.filterDate),
        getGoals(user, this.filterDate),
      ];

      return row.join(',');
    },

    async exportCSV() {
      const header =
        'Ime,Prezime,Pozicija,Uloga,Datum početka,Rangiranje - total,Rangiranje – evaluation,Zadaci,Ciljevi';
      const csvContent =
        'data:text/csv;charset=utf-8,' + [header, ...this.usersFiltered.map((user) => this.userToRow(user))].join('\n');
      const encodedUri = encodeURI(csvContent);
      const link = document.createElement('a');
      link.setAttribute('href', encodedUri);
      link.setAttribute('download', 'export.csv');
      document.body.appendChild(link); // Required for FF
      link.click();
    },
    toggleFilters() {
      this.isFiltersVisible = !this.isFiltersVisible;
    },
    closeFilters() {
      this.isFiltersVisible = false;
    },
    showTransferModal(item) {
      this.modalData = item;
      this.isTransferModalVisible = true;
    },
    closeModal() {
      this.isTransferModalVisible = false;
      this.isManagerAssignModalVisible = false;
    },
    showChangeManagerDialog(data) {
      // ToDo here we have to set the correct data
      console.log(data.mentor);
      this.selectedMentor = data.mentor;
      this.isManagerAssignModalVisible = true;
    },
    // changeMonth(date: Date) {
    //   this.filterDate = date;
    // },
    getMonthlyTaskSet(user: UserEntity, type: TaskSetType) {
      const taskSets = user.taskSets;
      // user.attributes.mentoringType === MentoringType.Mentor
      //   ? user.mentorTaskSets
      //   : user.attributes.mentoringType === MentoringType.Mentee
      //   ? user.menteeTaskSets
      //   : undefined;
      if (!taskSets) return null;
      return taskSets.find((taskSet) => taskSet.type === type && this.compareMonth(taskSet.due.toString())); // ToDo: Typing and real value here are different
    },
    async closeEvaluation() {
      await this.$router.replace({ path: '/dashboard', query: { ...this.$route.query, details: undefined } });
      this.showEvaluationDialog = false;
    },
  },
  async mounted() {
    await this.$store.dispatch(new LoadNextStreamPage());
    const manager = this.getUserById(this.managerId);
    if (manager) {
      await this.selectUser(manager, true);
    }

    if (this.menteeId || this.mentorId) {
      if (!this.menteeId || !this.mentorId) {
        await this.$router.replace({
          path: '/dashboard',
          query: { ...this.$route.query, details: undefined, mentee: undefined, mentor: undefined },
        });
      } else {
        const mentee = this.getUserById(this.menteeId);
        const mentor = this.getUserById(this.mentorId);
        if (!mentee || !mentor) {
          await this.$router.replace({
            path: '/dashboard',
            query: { ...this.$route.query, details: undefined, mentee: undefined, mentor: undefined },
          });
        } else {
          // Select entire time period if we want to show specified user
          this.filterDate = undefined;
          await this.selectUser(mentee, true, mentor);
        }
      }
    }
  },
});
