


























import Vue from 'vue';
import { mapState } from 'vuex';
import { ShowNotificationDialogAction, UIState } from '@/store/ui';

export default Vue.extend({
  name: 'NotificationDialog',
  computed: {
    ...mapState<UIState>('ui', {
      notificationDialog: (state: UIState) => state.notificationDialog,
    }),

    icon() {
      const icons = {
        success: 'check',
        warning: 'warning',
        pending: 'hourglass_top',
        error: 'thumb_down',
      };
      return icons[this.notificationDialog.variant] || 'check';
    },

    buttonText() {
      return this.notificationDialog.buttonText || 'Ok';
    },

    show: {
      get: function () {
        return this.notificationDialog.message;
      },
      set: async function (val) {
        if (val === false) {
          await this.$store.dispatch(new ShowNotificationDialogAction('', '', ''));
        }
      },
    },
  },
});
