import { GetterTree } from 'vuex';
import { TasksState } from './types';
import { RootState } from '@/store/types';
import { MentoringType, UserEntity } from '@mentessa/types';

export const getters: GetterTree<TasksState, RootState> = {
  getTaskById(state: TasksState) {
    return (id: number) => {
      let task = state.mentor?.tasks.goals?.tasks?.find((task) => task.id === id);
      task = task || state.mentor?.tasks.weekly?.tasks?.find((task) => task.id === id);
      if (task) return task;
      state.mentees?.forEach((mentee) => {
        task = mentee.tasks.goals?.tasks?.find((task) => task.id === id);
        task = task || mentee.tasks.weekly?.tasks?.find((task) => task.id === id);
        if (task) return task;
      });
      return task;
    };
  },
  getTaskSetsForUser(state: TasksState) {
    return (user: UserEntity) => {
      return state.all.filter(
        (taskSet) =>
          user.id === (user.attributes.mentoringType === MentoringType.Mentee ? taskSet.menteeId : taskSet.mentorId),
      );
    };
  },
};
