import { MutationTree } from 'vuex';
import { EventsMutations, EventsState } from './types';

export const mutations: MutationTree<EventsState> = {
  [EventsMutations.Connect](state: EventsState) {
    state.isConnected = true;
    console.log('Socket connected');
  },
  [EventsMutations.Disconnect](state: EventsState) {
    state.isConnected = false;
    state.isRegistered = false;
    console.log('Socket disconnected');
  },
  [EventsMutations.SetRegisterStatus](state: EventsState, status: boolean) {
    state.isRegistered = status;
  },
};
