var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-hover',{staticClass:"m-user-hover",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"m-user-card",attrs:{"elevation":hover ? 5 : 1},on:{"click":function($event){return _vm.openUserDetail(_vm.user.id)}}},[_c('v-img',{staticClass:"white--text align-end pa-3",staticStyle:{"border-radius":"8px","flex-grow":"0"},attrs:{"src":_vm.user.identity.attributes.image,"aspect-ratio":"1","gradient":"to top, rgba(0,0,0,.7) 0%, transparent 100px","contain":""}},[_c('v-row',[(_vm.isMentor)?_c('v-tooltip',{attrs:{"right":"","color":"rgba(0,0,0,.9)","transition":"slide-x-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"top":"10px","left":"10px","border-radius":"50%"},attrs:{"fab":"","x-small":"","width":"24","height":"24","absolute":"","color":"primary","depressed":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("$handshake")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('stream.card.mentor')))])]):_vm._e(),_c('v-list-item',{staticClass:"px-3",staticStyle:{"max-width":"100%"}},[_c('v-list-item-content',{staticClass:"pb-3"},[_c('v-list-item-title',{staticClass:"white--text text-h6 m-font-headline"},[_vm._v(" "+_vm._s(_vm.user.identity.attributes.firstName)+" "+_vm._s(_vm.user.identity.attributes.lastName)+" ")]),_c('v-list-item-subtitle',{staticClass:"white--text"},[_c('span',[_vm._v(_vm._s(_vm.user.attributes.jobTitle))])])],1),_c('v-list-item-action',{staticClass:"d-none"},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ma-0",attrs:{"text":"","icon":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.toggleFavorite(_vm.user)}}},on),[_c('v-icon',{attrs:{"color":"#fff"}},[_vm._v(_vm._s(_vm.user.isFavorite ? 'star' : 'star_border'))])],1)]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s(_vm.user.isFavorite ? _vm.$t('user.favoritesRemove') : _vm.$t('user.favoritesAdd'))}})])],1)],1)],1)],1),_c('div',{staticClass:"pa-3"},[(_vm.user.attributes.location)?_c('p',{staticClass:"mt-0 mb-1"},[_c('v-icon',{staticStyle:{"left":"-4px"},attrs:{"color":"accent"}},[_vm._v("place")]),_c('small',[_vm._v(_vm._s(_vm.user.attributes.location))])],1):_vm._e(),(_vm.user.attributes.startDate)?_c('p',{staticClass:"mt-0 mb-3"},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("$start_date")]),_c('small',[_vm._v(_vm._s(_vm.user.attributes.startDate))])],1):_vm._e(),(_vm.user.attributes.department)?_c('p',{staticClass:"mt-0 mb-3"},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("$hierarchy")]),_c('small',[_vm._v(_vm._s(_vm.user.attributes.department))])],1):_vm._e(),(_vm.user.expertiseTags && _vm.user.expertiseTags.length)?_c('div',{staticClass:"mb-2"},[_c('p',{staticClass:"m-tags-heading"},[_vm._v(_vm._s(_vm.$t('user.skills')))]),_vm._l((_vm.user.expertiseTags),function(tag){return _c('v-chip',{key:tag.id,staticClass:"m-chip",attrs:{"small":""}},[_vm._v(_vm._s(tag.name))])})],2):_vm._e(),(_vm.user.interestTags && _vm.user.interestTags.length)?_c('div',[_c('p',{staticClass:"m-tags-heading"},[_vm._v(_vm._s(_vm.$t('user.improving')))]),_vm._l((_vm.user.interestTags),function(tag){return _c('v-chip',{key:tag.id,staticClass:"m-chip",attrs:{"small":""}},[_vm._v(_vm._s(tag.name))])})],2):_vm._e()])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }