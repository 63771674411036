import { ActionTree } from 'vuex';
import {
  CheckNewMessages,
  LoadMessages,
  LoadNextRequestsPage,
  LoadRooms,
  MessagesActions,
  MessagesMutations,
  MessagesState,
  ReadWholeRoom,
} from './types';
import { RootState } from '@/store/types';
import { api } from '@/store/api';
import { ShowNotificationSnackbarAction } from '@/store/ui';
import i18n from '@/plugins/i18n';
import { MessageEntity, RoomEntity } from '@mentessa/types';

export const actions: ActionTree<MessagesState, RootState> = {
  async [MessagesActions.LoadRooms]({ commit }) {
    console.log(`Load rooms`);
    const response = await api.get('messages/rooms');
    const rooms = response?.data;
    commit(MessagesMutations.SetRooms, rooms);
  },

  async [MessagesActions.LoadMessages]({ commit }, { roomId }) {
    console.log(`Load messages for room ${roomId}`);
    const response = await api.get(`messages/rooms/${roomId}/messages`);
    const messages = response?.data;
    commit(MessagesMutations.SetMessages, messages);
  },

  async [MessagesActions.SendMessage]({ commit }, { roomId, text }) {
    console.log(`Send message to ${roomId}: ${text}`);
    const response = await api.post(`messages/rooms/${roomId}/messages`, { text });
    const messages = response?.data;
    commit(MessagesMutations.AppendMessage, messages);
  },

  async [MessagesActions.SendDirectMessage]({ dispatch }, { toUserId, text }) {
    console.log(`Send direct message to ${toUserId}`);
    try {
      const response = await api.post(`messages/direct/${toUserId}/`, { text });
      const room: RoomEntity = response?.data;
      if (room?.id) {
        await dispatch(new LoadRooms(), { root: true });
        return room;
      }
      return response != null && response.status === 201;
    } catch (e) {
      console.error('Error while send direct message.', e);
      await dispatch(new ShowNotificationSnackbarAction(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
    return false;
  },
  async [MessagesActions.StartFetchMessages]({ commit, dispatch }, { interval }) {
    console.log(`Start fetch messages with interval: ${interval}`);
    const timer = setInterval(async () => {
      await dispatch(new CheckNewMessages(), { root: true });
    }, interval);
    commit(MessagesMutations.SetTimer, timer);
  },

  async [MessagesActions.StopFetchMessages]({ commit }) {
    console.log('Stop fetch messages');
    commit(MessagesMutations.SetTimer, undefined);
  },

  async [MessagesActions.CheckNewMessages]({ state, commit, dispatch }) {
    const time = new Date();
    const response = await api.get('messages/unread', { params: { from: state.lastFetchTime } });
    commit(MessagesMutations.SetLastFetchTime, time);
    const newCount = Number(response?.data);
    if (newCount !== state.unreadMessageCount) {
      const exactlyNewCount = newCount - state.unreadMessageCount;
      commit(MessagesMutations.SetUnreadMessageCount, response?.data || 0);
      if (exactlyNewCount > 0) {
        await dispatch(new ShowNotificationSnackbarAction(`You have new message`), { root: true });
      }
    }
    return newCount;
  },

  async [MessagesActions.SelectRoom]({ commit, dispatch }, { room }) {
    console.log(`Select room: ${room.id}`);
    commit(MessagesMutations.SetActiveRoom, room);
    if (room.id !== 'fake') {
      await dispatch(new LoadMessages(room.id), { root: true });
      await dispatch(new ReadWholeRoom(room), { root: true });
    } else {
      commit(MessagesMutations.SetMessages, []);
    }
  },

  async [MessagesActions.ReadWholeRoom]({ commit }, { room }) {
    if (!room.hasUnread) {
      return;
    }
    console.log(`Read Whole Room: ${room.id}`);
    const response = await api.post(`messages/rooms/${room.id}/read`);
    if (response.status !== 201) {
      console.log('Fail mark room messages as read');
    }
    commit(MessagesMutations.ReadActiveRoom);
  },
  async [MessagesActions.LoadNextRequestsPage]({ commit, state, getters, dispatch }) {
    const shouldLoad = getters.isNewRequestsPageAvailable;
    const {
      requests: {
        pagination: { currentPage },
        onlyMy,
      },
    } = state;
    if (shouldLoad) {
      await commit(MessagesMutations.SetRequestsLoadingFlag, true);
      const params = {
        page: currentPage + 1,
      };
      try {
        const url = onlyMy ? 'messages/requests/my' : 'messages/requests';
        const response = await api.get(url, { params });
        const data = response?.data;
        // ToDo: Maybe should be fixed on backend side
        data.meta.currentPage = Number(data.meta.currentPage);
        commit(MessagesMutations.AddRequestsPage, data);
      } catch (e) {
        console.error('Error while loading requests.', e);
        await dispatch(new ShowNotificationSnackbarAction(i18n.t('notifications.generalError').toString()), {
          root: true,
        });
      }
      await commit(MessagesMutations.SetRequestsLoadingFlag, false);
    }
  },
  async [MessagesActions.CreateRequest]({ commit, dispatch }, { options }) {
    console.log(`Create request:`, options);
    try {
      const msg: MessageEntity = {
        ...options,
      };
      if (msg.attrs) {
        for (const [attrsKey] of Object.entries(msg.attrs)) {
          if (!msg.attrs[attrsKey]) delete msg.attrs[attrsKey];
        }
      }

      if (msg.attrs && Object.keys(msg.attrs).length === 0) delete msg.attrs;

      const response = await api.post('messages/requests/my', msg);
      commit(MessagesMutations.AppendRequest, response.data);
      await dispatch(new ShowNotificationSnackbarAction(i18n.t('requests.created').toString()), {
        root: true,
      });
    } catch (e) {
      console.error('Error while create request.', e);
      await dispatch(new ShowNotificationSnackbarAction(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },
  async [MessagesActions.DeleteRequest]({ commit, dispatch }, { request }) {
    try {
      const response = await api.delete(`messages/requests/my/${request.id}`);
      if (response.data.affected > 0) {
        commit(MessagesMutations.RemoveRequest, request);
        await dispatch(new ShowNotificationSnackbarAction(i18n.t('requests.deleted').toString()), {
          root: true,
        });
      } else {
        console.error('Access deny while delete request.');
        await dispatch(new ShowNotificationSnackbarAction(i18n.t('notifications.accessDeny').toString()), {
          root: true,
        });
      }
    } catch (e) {
      console.error('Error while delete request.', e);
      await dispatch(new ShowNotificationSnackbarAction(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },
  async [MessagesActions.ChangeRequest]({ dispatch, commit }, { request, dto }) {
    try {
      await api.put(`messages/requests/my/${request.id}`, dto);
      commit(MessagesMutations.SetRequest, { request, dto });
      await dispatch(new ShowNotificationSnackbarAction(i18n.t('requests.saved').toString()), {
        root: true,
      });
    } catch (e) {
      console.error('Error while delete request.', e);
      await dispatch(new ShowNotificationSnackbarAction(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
      return false;
    }
    return true;
  },
  async [MessagesActions.FilterMyRequests]({ commit, dispatch, state }, { enabled }) {
    if (state.requests.onlyMy !== enabled) {
      commit(MessagesMutations.SetOnlyMyRequests, enabled);
      commit(MessagesMutations.ClearRequests);
      await dispatch(new LoadNextRequestsPage(), { root: true });
    }
  },
  // eslint-disable-next-line
  async [MessagesActions.UploadRequestImage]({commit}, {image}) {
    const formData = new FormData();
    formData.append('file', image);
    const result = await api.post('public/image/request', formData);
    return result?.data;
  },
};
