import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { getters } from './getters';
import { mutations } from './mutations';
import { actions } from './actions';
import { EventsState } from './types';

export const initialState: EventsState = {
  isConnected: undefined,
  isRegistered: false,
};

export const module: Module<EventsState, RootState> = {
  namespaced: false,
  state: initialState,
  getters,
  actions,
  mutations,
};

export * from './types';
