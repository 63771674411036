import { Action } from '@/store/types';
import { GeonamesInstance } from 'geonames.js/src/geonames-types';
import { UserEntity } from '@mentessa/types';

export const moduleName = 'utils';

export interface Language {
  code: string;
  name: string;
}

export interface UtilsState {
  languages: Language[];
  geonames: GeonamesInstance;
}

export enum UtilsActions {
  LoadLanguages = '[Utils] LoadLanguages',
  InitGeonames = '[Utils] InitGeonames',
  SendQuestionnaire = '[Utils] Send Questionnaire',
}

export enum UtilsMutations {
  SetLanguages = '[Utils] Set Languages',
  SetGeonames = '[Utils] Set Geonames',
}

export class LoadLanguages implements Action {
  constructor(public locale: string) {
    this.locale = locale;
  }
  type = `${moduleName}/${UtilsActions.LoadLanguages}`;
}

export class InitGeonames implements Action {
  constructor(public locale: string) {
    this.locale = locale;
  }
  type = `${moduleName}/${UtilsActions.InitGeonames}`;
}

export class SendQuestionnaire implements Action {
  constructor(
    public sender: UserEntity,
    public target: UserEntity,
    public taskSetId: number,
    public answers: (string | number)[],
    public text: string,
  ) {
    this.sender = sender;
    this.target = target;
    this.taskSetId = taskSetId;
    this.answers = answers;
    this.text = text;
  }
  type = `${moduleName}/${UtilsActions.SendQuestionnaire}`;
}
