
































































































import Vue from 'vue';
import { mapState } from 'vuex';
import { UsersState } from '@/store/users';
import { LoadRooms, MessagesState, SelectRoom, SendDirectMessage, SendMessage } from '@/store/messages';
import { RoomEntity, UserEntity } from '@mentessa/types';
import ChatSearch from '../components/ChatSearch.vue';
import { TenantState } from '@/store/tenant';
import { ShowNotificationSnackbarAction } from '@/store/ui';

export default Vue.extend({
  components: { ChatSearch },
  data: () => ({
    message: '',
    filterName: '',
    fakeRoom: undefined,
  }),
  computed: {
    ...mapState<UsersState>('users', {
      me: (state: UsersState) => state.me,
      users: (state: UsersState) => state.stream.users,
    }),
    ...mapState<TenantState>('tenant', {
      names: (state: TenantState) => state.usernames,
    }),
    ...mapState<MessagesState>('messages', {
      rooms: (state: MessagesState) => state.rooms,
      messages: (state: MessagesState) => state.messages,
      activeRoom: (state: MessagesState) => state.activeRoom,
      unreadMessageCount: (state: MessagesState) => state.unreadMessageCount,
    }),
    room() {
      return this.activeRoom;
    },
    filteredRooms() {
      if (this.fakeRoom) {
        return [this.fakeRoom, ...this.rooms];
      }
      return this.rooms;
    },
  },
  watch: {
    async filterName(value) {
      if (!value) {
        this.fakeRoom = null;
        return;
      }
      const room = this.rooms.find((room) => this.getFullName(room.members[0].user) === value);
      if (room) {
        await this.selectRoom(room);
        return;
      }
      const user = this.users.find((user) => this.getFullName(user) === value);
      if (user) {
        if (!user.identity.attributes.image) user.identity.attributes.image = '/user-empty.png';
        this.fakeRoom = {
          members: [{ user }],
          id: 'fake',
          hasUnread: false,
        };
        await this.selectRoom(this.fakeRoom);
      } else {
        this.fakeRoom = null;
      }
    },
    async unreadMessageCount(count) {
      if (count === 0) {
        return;
      }
      await this.$store.dispatch(new LoadRooms());
      const updatedActiveRoom = this.rooms.find((room) => room.id === this.activeRoom?.id);
      if (updatedActiveRoom?.hasUnread) {
        await this.$store.dispatch(new SelectRoom(updatedActiveRoom));
      }
    },
  },
  methods: {
    async sendMessage() {
      if (this.message) {
        if (this.room.id === 'fake') {
          const newRoom = await this.$store.dispatch(new SendDirectMessage(this.room.members[0].user.id, this.message));
          this.fakeRoom = null;
          const room = this.rooms.find((room) => room.id === newRoom.id);
          if (room) {
            await this.selectRoom(room);
          }
        } else {
          await this.$store.dispatch(new SendMessage(this.activeRoom.id, this.message));
        }
        this.message = '';
      }
    },
    isActive(room: RoomEntity): boolean {
      return room.id === this.room?.id;
    },
    async selectRoom(room: RoomEntity) {
      // if (room.id.toString().startsWith('fake')) {
      //   this.fakeRoom = room;
      // } else {
      await this.$store.dispatch(new SelectRoom(room));
      if (this.$route.path !== `/inbox/${room.id}`) {
        await this.$router.push(`/inbox/${room.id}`);
        // }
      }
    },
    getFullName(user: UserEntity) {
      return `${user.identity.attributes.firstName} ${user.identity.attributes.lastName}`;
    },
  },
  async created() {
    await this.$store.dispatch(new LoadRooms());

    if (this.$route.params.roomId) {
      const room = this.rooms.find((r) => r.id === Number(this.$route.params.roomId));
      if (room) {
        await this.selectRoom(room);
      } else {
        await this.$router.push('/inbox');
      }
    }
  },
});
