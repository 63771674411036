var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"m-dashboard-table",attrs:{"headers":_vm.headers,"items":_vm.users,"items-per-page":6,"calculate-widths":"","custom-sort":_vm.customSort,"footer-props":{ itemsPerPageText: _vm.$t('dashboard.table.rowsPerPage') }},on:{"current-items":_vm.getFiltered},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center py-2"},[(_vm.isUserSelectable(item))?_c('v-checkbox',{attrs:{"value":item.id === _vm.selectedUserId,"on-icon":"lens","off-icon":"panorama_fish_eye"},on:{"change":function($event){return _vm.$emit('selectUser', item, $event)}}}):_vm._e()],1)]}},{key:"item.namePosition",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center py-2"},[_c('v-img',{staticClass:"mr-3",staticStyle:{"border-radius":"50%"},attrs:{"src":item.identity.attributes.image,"max-width":"48","max-height":"48"}}),_c('div',{staticClass:"d-flex flex-column justify-center"},[_c('p',{staticClass:"m-font-s-14 font-weight-bold ma-0"},[_vm._v(" "+_vm._s(item.identity.attributes.firstName)+" "+_vm._s(item.identity.attributes.lastName)+" ")]),_c('p',{staticClass:"m-font-s-10 m-font-c-secondary ma-0"},[_vm._v(_vm._s(item.attributes.jobTitle))])])],1)]}},{key:"item.role",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center justify-center py-2"},[_c('p',{staticClass:"ma-0"},[_vm._v(_vm._s(_vm.getRoleTitle(item)))])])]}},{key:"item.start",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center justify-center py-2"},[_c('p',{staticClass:"ma-0"},[_vm._v(_vm._s(_vm.getStartDate(item)))])])]}},{key:"item.rating",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center justify-center py-2"},[_c('p',{staticClass:"ma-0"},[_vm._v(_vm._s(_vm.getRating(item)))])])]}},{key:"item.rating2",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center justify-center py-2"},[_c('p',{staticClass:"ma-0"},[_vm._v(_vm._s(_vm.getRating2(item)))])])]}},{key:"item.tasks",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center justify-center py-2"},[_c('p',{staticClass:"ma-0"},[_vm._v(_vm._s(_vm.getTasks(item)))])])]}},{key:"item.goals",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center justify-center py-2"},[_c('p',{staticClass:"ma-0"},[_vm._v(_vm._s(_vm.getFormattedGoals(item)))])])]}},{key:"item.menu",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('div',{staticClass:"d-flex align-center justify-center py-2"},[_c('div',{staticClass:"actions"},[_c('a',{staticClass:"btn-toggle-dropdown skip-outside-events pl-3 pr-1",attrs:{"href":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.showMenu(item)}}},[_c('svg',{staticClass:"skip-outside-events",attrs:{"width":"4","height":"16","viewBox":"0 0 4 16","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{staticClass:"skip-outside-events",attrs:{"d":"M2.83464 8.00004C2.83464 7.5398 2.46154 7.16671 2.0013 7.16671C1.54106 7.16671 1.16797 7.5398 1.16797 8.00004C1.16797 8.46028 1.54106 8.83337 2.0013 8.83337C2.46154 8.83337 2.83464 8.46028 2.83464 8.00004Z","stroke":"#6B7280","stroke-width":"1.5","stroke-linecap":"round","stroke-linejoin":"round"}}),_c('path',{staticClass:"skip-outside-events",attrs:{"d":"M2.83464 2.16667C2.83464 1.70643 2.46154 1.33333 2.0013 1.33333C1.54106 1.33333 1.16797 1.70643 1.16797 2.16667C1.16797 2.6269 1.54106 3 2.0013 3C2.46154 3 2.83464 2.6269 2.83464 2.16667Z","stroke":"#6B7280","stroke-width":"1.5","stroke-linecap":"round","stroke-linejoin":"round"}}),_c('path',{staticClass:"skip-outside-events",attrs:{"d":"M2.83464 13.8333C2.83464 13.3731 2.46154 13 2.0013 13C1.54106 13 1.16797 13.3731 1.16797 13.8333C1.16797 14.2935 1.54106 14.6666 2.0013 14.6666C2.46154 14.6666 2.83464 14.2935 2.83464 13.8333Z","stroke":"#6B7280","stroke-width":"1.5","stroke-linecap":"round","stroke-linejoin":"round"}})])]),_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(function (e) { return _vm.handleClickOutside(e, item); }),expression:"(e) => handleClickOutside(e, item)"}],staticClass:"dropdown",class:{
            show: _vm.isDropdownVisible && item.id === _vm.visibleDropdownID,
            oposite: index > _vm.returnVisibleItems.length - 3,
            first: index === 0,
          }},[_c('ul',[(_vm.isUserMentee(item) || _vm.isUserMentor(item))?_c('li',[_c('a',{attrs:{"href":""},on:{"click":function($event){$event.preventDefault();return _vm.showMentorTransferModal(item)}}},[_vm._v(" "+_vm._s(_vm.$t('dashboard.context-menu.transfer'))+" ")])]):_vm._e(),(_vm.isHR)?_c('li',[_c('a',{attrs:{"href":""},on:{"click":function($event){$event.preventDefault();return _vm.toggleHR(item)}}},[(_vm.isUserHR(item))?[_vm._v(" "+_vm._s(_vm.$t('dashboard.context-menu.removeAdminRights')))]:[_vm._v(" "+_vm._s(_vm.$t('dashboard.context-menu.giveAdminRights')))]],2)]):_vm._e(),(_vm.isHR)?_c('li',[_c('LinkWithConfirmation',{attrs:{"activatorButtonText":_vm.$t('dashboard.context-menu.deleteUser'),"warningMessage":_vm.$t('dashboard.context-menu.deleteUserQuestion'),"confirmButtonText":_vm.$t('dashboard.context-menu.deleteUserConfirmText')},on:{"confirmed":_vm.deleteUser}})],1):_vm._e()])])])])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }