import Vue from 'vue';
import Vuex from 'vuex';
import { module as tenantModule } from '@/store/tenant';
import { module as usersModule } from '@/store/users';
import { module as utilsModule } from '@/store/utils';
import { module as uiModule } from '@/store/ui';
import { module as messagesModule } from '@/store/messages';
import { module as tasksModule } from '@/store/tasks';
import { module as eventsModule } from '@/store/events';
import { RootState } from '@/store/types';
import { actions } from './actions';
import { mutations } from './mutations';

Vue.use(Vuex);

export const initialState: RootState = {
  initialized: false,
  forceLogout: false,
};

export default new Vuex.Store<RootState>({
  state: initialState,
  actions,
  mutations,
  modules: {
    tenant: tenantModule,
    users: usersModule,
    utils: utilsModule,
    ui: uiModule,
    messages: messagesModule,
    tasks: tasksModule,
    events: eventsModule,
  },
  strict: process.env.NODE_ENV !== 'production',
});
