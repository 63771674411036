<template>
  <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.29951 4.19976C7.8072 4.19976 6.59951 5.40744 6.59951 6.89976C6.59951 8.39207 7.8072 9.59976 9.29951 9.59976C10.7918 9.59976 11.9995 8.39207 11.9995 6.89976C11.9995 5.40744 10.7918 4.19976 9.29951 4.19976ZM10.4995 7.01769C10.4995 7.11782 10.4176 7.19976 10.3174 7.19976H9.18176C9.08164 7.19976 8.9997 7.11782 8.9997 7.01769V5.58201C8.9997 5.48188 9.08164 5.39994 9.18176 5.39994H9.41745C9.51757 5.39994 9.59951 5.48188 9.59951 5.58201V6.59976H10.3174C10.4176 6.59976 10.4995 6.68169 10.4995 6.78182V7.01769ZM9.29951 3.59976C9.40076 3.59976 9.50051 3.60594 9.59951 3.61494V2.69976C9.59951 2.21976 9.17951 1.79976 8.69951 1.79976H7.19951V0.899756C7.19951 0.419756 6.77951 -0.000244141 6.29951 -0.000244141H3.29951C2.81951 -0.000244141 2.39951 0.419756 2.39951 0.899756V1.79976H0.899512C0.419512 1.79976 -0.000488281 2.21976 -0.000488281 2.69976V4.19976H7.40801C7.94426 3.82307 8.59582 3.59976 9.29951 3.59976ZM5.99951 1.79976H3.59951V1.19976H5.99951V1.79976ZM6.12739 5.99976H3.89951C3.73376 5.99976 3.59951 5.86551 3.59951 5.69976V4.79976H-0.000488281V7.49976C-0.000488281 7.97976 0.419512 8.39976 0.899512 8.39976H6.36382C6.13264 7.94901 5.99951 7.44013 5.99951 6.89976C5.99951 6.58738 6.04601 6.28626 6.12739 5.99976Z"
      fill="var(--v-accent-base)"
    />
  </svg>
</template>

<script>
export default {
  name: 'StartDate',
};
</script>

<style scoped></style>
