import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { getters } from './getters';
import { mutations } from './mutations';
import { actions } from './actions';
import { UtilsState } from '@/store/utils/types';

export const initialState: UtilsState = {
  languages: [],
  geonames: undefined,
};

export const module: Module<UtilsState, RootState> = {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};

export * from './types';
