

























import { VAutocomplete } from 'vuetify/lib';
import { mapState } from 'vuex';
import { UtilsState } from '@/store/utils';

export default VAutocomplete.extend({
  name: 'Location',
  props: {
    required: Boolean,
    hint: String,
    validationHint: String,
    value: String,
  },
  data: () => ({
    search: null,
    locations: [],
    isLoading: false,
  }),
  watch: {
    search(value) {
      if (value == null) {
        return;
      }
      this.isLoading = true;
      this.geonames
        .search({
          name_startsWith: value,
          searchlang: 'en',
          featureClass: ['P'],
          cities: 'cities1000',
          style: 'short',
          maxRows: 16,
        } as never)
        .then((result) => {
          const filtered = result.geonames
            .filter((x) => ['C', 'A', '', undefined].includes(x.fcode[3]))
            .map((x) => x.name);
          if (this.value) {
            this.locations = [this.value, ...filtered];
          } else {
            this.locations = [...filtered];
          }
        })
        .finally(() => (this.isLoading = false));
    },
  },
  computed: {
    ...mapState<UtilsState>('utils', {
      geonames: (state: UtilsState) => state.geonames,
    }),
  },
  methods: {
    removeChip: function () {
      this.$emit('input', null);
      this.$emit('change', null);
    },
  },
  created() {
    if (this.value) {
      this.locations = [this.value];
    }
  },
});
