import { TenantEntity } from '@mentessa/types';
import { Action } from '@/store/types';

export const moduleName = 'tenant';

export interface TenantState {
  tenant?: TenantEntity;
  tags?: { id: number; name: string }[];
  locations: { name: string }[];
  usernames: { id?: number; name: string }[];
}

export enum TenantActions {
  Load = '[Tenant] Load',
  LoadLocations = '[Tenant] Load Locations',
  LoadUsernames = '[Tenant] Load Usernames',
}

export enum TenantMutations {
  SetTenant = '[Tenant] Set Tenant',
  LoadSuccess = '[Tenant] Load Success',
  LoadFailed = '[Tenant] Load Failed',
  SetLocations = '[Tenant] Set Locations',
  SetUsernames = '[Tenant] Set Usernames',
}

export class LoadTenant implements Action {
  type = `${moduleName}/${TenantActions.Load}`;
}

export class LoadLocations implements Action {
  type = `${moduleName}/${TenantActions.LoadLocations}`;
}

export class LoadUsernames implements Action {
  type = `${moduleName}/${TenantActions.LoadUsernames}`;
}
