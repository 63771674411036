






















































































































































import Vue, { PropType } from 'vue';
import { mapState } from 'vuex';
import { MakeHR, MakeManager, RevokeHR, RevokeManager, UsersState } from '@/store/users';
import { MentoringType, TaskSetType, UserEntity } from '@mentessa/types';
import { format, parseISO } from 'date-fns';
import LinkWithConfirmation from '@/components/LinkWithConfirmation.vue';
import { compareMonth, getGoals, getRatingEvaluation, getRatingTotal, getTasks } from '@/utils/ratings';

export default Vue.extend({
  props: {
    users: { type: Array as PropType<UserEntity[]> },
    selectedUserId: Number,
    filterDate: Date,
  },
  name: 'DashboardTable',
  components: {
    LinkWithConfirmation,
  },
  data: () => ({
    headers: [
      { value: 'id', width: 10, sortable: false },
      { value: 'namePosition', align: 'center', width: 180, sortable: true },
      { value: 'role', width: 90, align: 'center', sortable: true },
      { value: 'start', width: 140, align: 'center', sortable: true },
      { value: 'rating2', width: 120, align: 'center', sortable: true },
      { value: 'rating', width: 110, align: 'center', sortable: true },
      { value: 'tasks', width: 90, align: 'center', sortable: false },
      { value: 'goals', width: 90, align: 'center', sortable: true },
      { value: 'menu', width: 10, sortable: false },
    ],
    isDropdownVisible: false,
    visibleDropdownIndex: null,
    visibleDropdownID: null,
    visibleItems: [],
  }),
  mounted() {
    this.headers.forEach((h) => (h.text = this.$t(`dashboard.table.${h.value}`)));
  },
  computed: {
    ...mapState<UsersState>('users', {
      me: (state: UsersState) => state.me,
    }),

    isMentor() {
      return this.me?.attributes.mentoringType === MentoringType.Mentor;
    },
    isMentee() {
      return this.me?.attributes.mentoringType === MentoringType.Mentee;
    },
    isHR() {
      return this.me?.attributes.isHR;
    },
    isManager() {
      return this.me?.attributes.isManager;
    },
    returnVisibleItems() {
      return this.visibleItems;
    },
    compareMonth() {
      return compareMonth(this.filterDate);
    },
  },
  methods: {
    getFullName(user: UserEntity) {
      return `${user.identity.attributes.firstName} ${user.identity.attributes.lastName}`;
    },

    customSort: function (items: UserEntity[], index, isDesc) {
      switch (index[0]) {
        case 'namePosition':
          items.sort((l, r) => {
            return isDesc[0]
              ? this.getFullName(r).localeCompare(this.getFullName(l))
              : this.getFullName(l).localeCompare(this.getFullName(r));
          });
          break;
        case 'role':
          items.sort((l, r) => {
            let lAttr = l.attributes.mentoringType;
            let rAttr = r.attributes.mentoringType;
            if (lAttr == null && rAttr == null) return 0;
            if (lAttr == null) return 1;
            if (rAttr == null) return -1;
            if (isDesc[0]) {
              [lAttr, rAttr] = [rAttr, lAttr];
            }
            return Number(lAttr) - Number(rAttr);
          });
          break;
        case 'start':
          items.sort((l, r) => {
            let lAttr = l.attributes.startDate;
            let rAttr = r.attributes.startDate;
            if (lAttr == null && rAttr == null) return 0;
            if (lAttr == null) return 1;
            if (rAttr == null) return -1;
            if (isDesc[0]) {
              [lAttr, rAttr] = [rAttr, lAttr];
            }
            return parseISO(lAttr).getTime() - parseISO(rAttr).getTime();
          });
          break;
        case 'rating':
          items.sort((l, r) => {
            let lAttr = this.getRating(l);
            let rAttr = this.getRating(r);

            if (lAttr === rAttr) return 0;
            if (lAttr === '-') return 1;
            if (rAttr === '-') return -1;

            if (isDesc[0]) {
              [lAttr, rAttr] = [rAttr, lAttr];
            }
            return lAttr - rAttr;
          });
          break;
        case 'rating2':
          items.sort((l, r) => {
            let lAttr = this.getRating2(l);
            let rAttr = this.getRating2(r);

            if (lAttr === rAttr) return 0;
            if (lAttr === '-') return 1;
            if (rAttr === '-') return -1;

            if (isDesc[0]) {
              [lAttr, rAttr] = [rAttr, lAttr];
            }
            return lAttr - rAttr;
          });
          break;
        case 'goals':
          items.sort((l, r) => {
            let lAttr = this.getGoals(l);
            let rAttr = this.getGoals(r);

            if (lAttr === rAttr) return 0;
            if (lAttr == '-') return 1;
            if (rAttr == '-') return -1;

            if (isDesc[0]) {
              [lAttr, rAttr] = [rAttr, lAttr];
            }
            return lAttr - rAttr;
          });
          break;
      }
      return items;
    },
    getRoleTitle(user: UserEntity) {
      const roles = [];
      switch (user.attributes.mentoringType) {
        case MentoringType.Mentor:
          roles.push(this.$t('stream.card.mentor'));
          break;
        case MentoringType.Mentee:
          roles.push(this.$t('stream.card.mentee'));
          break;
      }
      if (user.attributes.isHR) roles.push(this.$t('stream.card.hr'));
      if (user.attributes.isManager) roles.push(this.$t('stream.card.manager'));

      return roles.join(', ');
    },
    isUserHR(user: UserEntity) {
      return user.attributes.isHR ?? false;
    },
    isUserMentee(user: UserEntity) {
      return user.attributes.mentoringType === MentoringType.Mentee;
    },
    isUserMentor(user: UserEntity) {
      return user.attributes.mentoringType === MentoringType.Mentor;
    },
    isUserManager(user: UserEntity) {
      return user.attributes.isManager;
    },
    getUserRole(user: UserEntity) {
      switch (user.attributes.mentoringType) {
        case MentoringType.Mentor:
          return 'Mentor';
        case MentoringType.Mentee:
          return 'Mentee';
      }

      if (user.attributes.isHR) return 'HR';
      else if (user.attributes.isManager) return 'Manager';

      return '';
    },
    getStartDate(user: UserEntity) {
      try {
        return format(parseISO(user.attributes.startDate), 'd.M.yyyy');
      } catch (e) {
        return '-';
      }
    },
    getMonthlyTaskSet(user: UserEntity, type: TaskSetType) {
      const taskSets = user.taskSets;
      if (!taskSets) return null;
      return taskSets.find((taskSet) => taskSet.type === type && this.compareMonth(taskSet.due.toString())); // ToDo: Typing and real value here are different
    },
    getRating(user: UserEntity) {
      return getRatingEvaluation(user, this.filterDate)?.toFixed(1) ?? '-';
    },
    getRating2(user: UserEntity) {
      return getRatingTotal(user, this.filterDate)?.toFixed(1) ?? '-';
    },
    getTasks(user: UserEntity) {
      return getTasks(user, this.filterDate) ?? '-';
    },
    getGoals(user: UserEntity) {
      return getGoals(user, this.filterDate) ?? '-';
    },
    getFormattedGoals(user: UserEntity) {
      const score = this.getGoals(user);
      return score === '-' ? score : `${score}%`;
    },
    isUserSelectable(user: UserEntity) {
      return user.attributes.mentoringType || user.attributes.isManager;
    },
    showMenu(obj) {
      //console.log(obj);
      const index = this.users.findIndex((user) => user.id === obj.id);
      //console.log(index);
      this.visibleDropdownIndex = index;
      this.visibleDropdownID = obj.id;
      this.isDropdownVisible = true;
    },
    closeDropdown() {
      if (this.isDropdownVisible) this.isDropdownVisible = false;
      this.visibleDropdownID = null;
    },

    handleClickOutside(e) {
      //console.log(e);
      //console.log(obj.id);
      //console.log(this.visibleDropdownID);
      let hasClass = e.target.classList.contains('skip-outside-events');

      //console.log(hasClass);
      //console.log(parentHasClass);
      if (hasClass) {
        return false;
      } else {
        this.isDropdownVisible = false;
        this.visibleDropdownID = null;
      }
    },
    deleteUser() {
      console.log('deleteUser');
    },
    showMentorTransferModal(item) {
      this.$emit('showTransferModal', item);
    },
    getFiltered(e) {
      this.visibleItems = e;
    },
    async toggleHR(user: UserEntity) {
      await this.$store.dispatch(user.attributes.isHR ? new RevokeHR(user) : new MakeHR(user));
    },
    async toggleManager(user: UserEntity) {
      await this.$store.dispatch(user.attributes.isHR ? new RevokeManager(user) : new MakeManager(user));
    },
  },
});
