<template>
  <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13 5C13 2.2375 10.0906 0 6.5 0C2.90937 0 0 2.2375 0 5C0 6.07188 0.440625 7.05938 1.1875 7.875C0.76875 8.81875 0.078125 9.56875 0.06875 9.57812C0 9.65 -0.01875 9.75625 0.021875 9.85C0.0625 9.94375 0.15 10 0.25 10C1.39375 10 2.34062 9.61563 3.02187 9.21875C4.02812 9.70938 5.21875 10 6.5 10C10.0906 10 13 7.7625 13 5ZM16.8125 11.875C17.5594 11.0625 18 10.0719 18 9C18 6.90938 16.3281 5.11875 13.9594 4.37187C13.9875 4.57812 14 4.7875 14 5C14 8.30937 10.6344 11 6.5 11C6.1625 11 5.83437 10.975 5.50938 10.9406C6.49375 12.7375 8.80625 14 11.5 14C12.7812 14 13.9719 13.7125 14.9781 13.2188C15.6594 13.6156 16.6063 14 17.75 14C17.85 14 17.9406 13.9406 17.9781 13.85C18.0187 13.7594 18 13.6531 17.9312 13.5781C17.9219 13.5688 17.2313 12.8219 16.8125 11.875Z"
      fill="var(--v-secondary-base)"
    />
  </svg>
</template>

<script>
export default {
  name: 'Comments',
};
</script>
