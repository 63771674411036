<template>
  <v-textarea
    class="m-field"
    :value="value"
    v-on:input="$emit('input', $event)"
    v-on:change="$emit('change', $event)"
    :rows="$vuetify.breakpoint.xsOnly ? 1 : 3"
    v-bind="$props"
    auto-grow
  >
    <template v-slot:label>
      {{ label }} {{ required ? '*' : '' }}
      <v-tooltip
        color="primary"
        max-width="320"
        style="border-radius: 12px"
        right
        v-if="(hint || validationHint) && $vuetify.breakpoint.smAndUp"
      >
        <template v-slot:activator="{ on }">
          <v-icon small style="vertical-align: middle" v-on="on">info</v-icon>
        </template>
        <div class="px-4 py-2">
          <p v-if="hint" v-html="hint" class="ma-0 font-weight-medium"></p>
          <p v-if="validationHint" v-html="'(' + validationHint + ')'" class="ma-0 font-italic"></p>
        </div>
      </v-tooltip>
    </template>
  </v-textarea>
</template>

<script>
import { VTextarea } from 'vuetify/lib';

export default VTextarea.extend({
  name: 'TextArea',
  props: {
    required: Boolean,
    hint: String,
    validationHint: String,
    value: String,
  },
});
</script>

<style>
.m-field .v-label {
  pointer-events: auto;
}
.text-field-snackbar .v-snack__wrapper {
  min-width: calc(100% - 16px);
  margin-top: 8px;
}
</style>
