import { MessagesState } from './types';
import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { getters } from './getters';
import { mutations } from './mutations';
import { actions } from './actions';

export const initialState: MessagesState = {
  rooms: undefined,
  messages: [],
  lastFetchTime: undefined,
  fetchTimer: undefined,
  unreadMessageCount: 0,
  activeRoom: undefined,
  requests: {
    items: [],
    pagination: {
      currentPage: 0,
      totalItems: 0,
      totalPages: 0,
    },
    isLoading: false,
    onlyMy: false,
  },
};

export const module: Module<MessagesState, RootState> = {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};

export * from './types';
