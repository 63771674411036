



















import DashboardStatisticsDigits from '@/components/DashboardStatisticsDigits.vue';
import TaskList from '@/components/DashboardTaskList/TaskList.vue';
import { TaskSetStage, TaskSetType, UserEntity } from '@mentessa/types';
import Vue, { PropType } from 'vue';
import { LoadCoupleStats } from '@/store/ui';
import MentorMenteePersons from '@/components/MentorMenteePersons.vue';
import Evaluation from '@/components/Evaluation.vue';
import { parseISO } from 'date-fns';

export default Vue.extend({
  name: 'DashboardCoupleStatistics',
  components: { MentorMenteePersons, TaskList, DashboardStatisticsDigits, Evaluation },
  props: {
    mentor: Object as PropType<UserEntity>,
    mentee: Object as PropType<UserEntity>,
    noTasks: { type: Boolean, default: false },
    filterDate: Date,
  },
  data: () => ({
    showEvaluationDialog: false,
    stats: {
      time: { total: 0, week: 0 },
      goals: { total: 0, completed: 0 },
      tasks: { total: 0, completed: 0 },
    },
    updateRequired: false,
  }),
  computed: {
    compareMonth() {
      return (dateStr: string) => {
        if (!this.filterDate) return true;
        const parsedDate = parseISO(dateStr);
        return (
          parsedDate.getFullYear() === this.filterDate.getFullYear() &&
          parsedDate.getMonth() === this.filterDate.getMonth()
        );
      };
    },
    goalsSet() {
      return this.mentee?.taskSets.find(
        (taskSet) =>
          taskSet.type === TaskSetType.Monthly &&
          taskSet.stage !== TaskSetStage.Close &&
          taskSet.mentorId === this.mentor.id &&
          this.compareMonth(taskSet.due as string),
      );
    },
    tasksSet() {
      return this.mentee?.taskSets.find(
        (taskSet) =>
          taskSet.type === TaskSetType.Weekly &&
          taskSet.stage !== TaskSetStage.Close &&
          taskSet.mentorId === this.mentor.id &&
          this.compareMonth(taskSet.due as string),
      );
    },
    statistics() {
      if (!this.stats) {
        return undefined;
      }
      return [
        { itemTitle: this.$t('dashboard.statistics.timeCoupleTotal'), value: this.toMinutes(this.stats.time.total) },
        { itemTitle: this.$t('dashboard.statistics.goalsCoupleCount'), value: this.stats.goals.total },
        { itemTitle: this.$t('dashboard.statistics.tasksCoupleCount'), value: this.stats.tasks.total },
        { itemTitle: this.$t('dashboard.statistics.timeCoupleWeek'), value: this.toMinutes(this.stats.time.week) },
        {
          itemTitle: this.$t('dashboard.statistics.goalsCoupleCompleted'),
          value: `${this.stats.goals.completed}`,
        },
        {
          itemTitle: this.$t('dashboard.statistics.tasksCoupleCompleted'),
          value: `${this.stats.tasks.completed}`,
        },
      ];
    },
  },
  watch: {
    async filterDate() {
      this.updateStats();
    },
    async mentee() {
      this.updateStats();
    },
    async mentor() {
      this.updateStats();
    },
  },
  methods: {
    updateStats() {
      this.updateRequired = true;
      this.$nextTick(async () => {
        if (this.updateRequired) {
          this.updateRequired = false;
          this.stats = await this.$store.dispatch(new LoadCoupleStats(this.mentor, this.mentee, this.filterDate));
        }
      });
    },
    getAverage(value, count) {
      return ((count > 0 ? value / count : 0) * 100).toFixed(1);
    },
    async navigate(targetPath) {
      if (this.$route.path !== targetPath) await this.$router.push(targetPath);
    },
    async openEvaluation(target) {
      const validatedTarget = ['mentor', 'mentee'].includes(target) ? target : undefined;
      await this.$router.replace({ path: '/dashboard', query: { ...this.$route.query, details: validatedTarget } });
      this.showEvaluationDialog = validatedTarget != null;
    },
    async closeEvaluation() {
      await this.$router.replace({ path: '/dashboard', query: { ...this.$route.query, details: undefined } });
      this.showEvaluationDialog = false;
    },
    getFullName(user: UserEntity) {
      return `${user.identity.attributes.firstName} ${user.identity.attributes.lastName}`;
    },
    toMinutes(ms) {
      return this.$t('dashboard.minutes', { count: Math.floor(ms / (1000 * 60)) });
    },
  },
  async mounted() {
    if (['mentor', 'mentee'].includes(this.$route.query.details)) {
      this.showEvaluationDialog = true;
    }
    this.updateStats();
  },
});
