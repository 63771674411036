



































import Vue from 'vue';

export default Vue.extend({
  name: 'LinkWithConfirmation',
  props: {
    activatorButtonText: String,
    activatorButtonDisabled: {
      type: Boolean,
      default: false,
    },
    warningMessage: String,
    confirmButtonText: String,
  },
  data: () => ({
    dialog: false,
  }),
});
