














import Vue from 'vue';

export default Vue.extend({
  name: 'DashboardStatisticsDigits',
  props: {
    statistics: Array,
    title: String,
  },
});
