











































import Vue from 'vue';
import { mapState } from 'vuex';
import { UsersState } from '@/store/users';
import { api } from '@/store/api';
import { ShowNotificationSnackbarAction } from '@/store/ui';

function readFileAsync(file): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result as string);
    };
    reader.onerror = reject;
    reader.readAsText(file);
  });
}

function readUserData(line, position) {
  return {
    firstName: line[position + 0].trim(),
    lastName: line[position + 1].trim(),
    employeeId: line[position + 2].trim(),
    jobTitle: line[position + 3].trim(),
    location: line[position + 4].trim(),
    department: line[position + 5].trim(),
    email: line[position + 6].trim(),
    division: line[22]?.trim() ?? line[7].trim(),
  };
}

function readUserDataWithoutId(line, position, hasStartDate?: boolean) {
  const data = {
    firstName: line[position + 0].trim(),
    lastName: line[position + 1].trim(),
    jobTitle: line[position + 2].trim(),
    location: line[position + 3].trim(),
    department: line[position + 4].trim(),
    division: line[position + 5].trim(),
  };

  if (hasStartDate) {
    return { ...data, startDate: line[position + 6].trim(), email: line[position + 7].trim() };
  } else {
    return { ...data, email: line[position + 6].trim() };
  }
}

export default Vue.extend({
  name: 'Admin',
  data: () => ({
    tab: 'invite',
    hr: {
      firstName: '',
      lastName: '',
      email: '',
    },
    usersCSV: undefined,
    usersContent: undefined,
    newUsers: {},
    conflicts: [],
    selectedUsers: [],
    ignoreConflicts: false,
    headers: [
      { text: 'id', value: 'id' },
      { text: 'conflicts', value: 'conflicts' },
      { text: 'firstName', value: 'firstName' },
      { text: 'lastName', value: 'lastName' },
      { text: 'employeeId', value: 'employeeId' },
      { text: 'jobTitle', value: 'jobTitle' },
      { text: 'location', value: 'location' },
      { text: 'department', value: 'department' },
      { text: 'email', value: 'email' },
      { text: 'division', value: 'division' },
      { text: 'roles', value: 'roles' },
      { text: 'mentor', value: 'mentor' },
      { text: 'manager', value: 'manager' },
    ],
  }),

  computed: {
    ...mapState<UsersState>('users', {
      me: (state: UsersState) => state.me,
      users: (state: UsersState) => state.stream.users,
    }),
    fileRepresentation: {
      get() {
        return this.usersContent?.join('\n') ?? '';
      },
      set(value: string) {
        this.usersContent = value.split('\n');
      },
    },
    usersToCreate() {
      return Object.entries(this.newUsers).map(([, v]) => v);
    },
    hasConflicts() {
      return this.selectedUsers.some((user) => user.conflicts?.length > 0 || user.id);
    },
  },
  watch: {
    async usersCSV(file) {
      try {
        const content = await readFileAsync(file);
        this.usersContent = content.split('\n');
        this.clean();
      } catch (e) {
        console.log(e);
      }
    },
  },
  methods: {
    clean() {
      this.conflicts = [];
      this.newUsers = {};
      this.selectedUsers = [];
      this.ignoreConflicts = false;
    },
    async processUsers() {
      this.clean();
      const firstLine = this.usersContent?.[0].trim() ?? '';
      switch (firstLine) {
        case 'Mentee;;;;;;;;Mentor;;;;;;;Manager;;;;;;;;': {
          this.processRegularUsers();
          break;
        }
        case 'Admins;;;;;;;': {
          this.processAdmins();
          break;
        }
        case 'Mentee;;;;;;;;Mentor;;;;;;;Manager;;;;;;;;;;': {
          this.processRegularUsersWithoutId();
          break;
        }
        default: {
          console.log(`Wrong format`);
          await this.$store.dispatch(new ShowNotificationSnackbarAction(this.$t('notifications.wrongFormat')));
          return;
        }
      }
    },
    processRegularUsers() {
      for (let i = 2; i < this.usersContent.length; ++i) {
        const line = this.usersContent[i].split(';');
        if (line.length < 23) continue;
        const manager = {
          ...readUserData(line, 15),
          roles: ['manager'],
        };
        const mentor = {
          ...readUserData(line, 8),
          roles: ['mentor'],
          manager: manager.employeeId,
        };
        const mentee = {
          ...readUserData(line, 0),
          startDate: line[6].trim(),
          email: line[7].trim(),
          roles: ['mentee'],
          manager: manager.employeeId,
          mentor: mentor.employeeId,
        };

        this.addUser(mentee);
        this.addUser(mentor);
        this.addUser(manager);
      }
    },
    processRegularUsersWithoutId() {
      for (let i = 2; i < this.usersContent.length; ++i) {
        const line = this.usersContent[i].split(';');
        // Skip empty and wrong lines
        if (line.length < 23 || line[0] === '') continue;
        const manager = {
          ...readUserDataWithoutId(line, 15),
          roles: ['manager'],
        };
        const mentor = {
          ...readUserDataWithoutId(line, 8),
          roles: ['mentor'],
          manager: manager.email,
        };
        const mentee = {
          ...readUserDataWithoutId(line, 0, true),
          roles: ['mentee'],
          manager: manager.email,
          mentor: mentor.email,
        };
        this.addUser(mentee);
        this.addUser(mentor);
        this.addUser(manager);
      }
    },
    processAdmins() {
      for (let i = 2; i < this.usersContent.length; ++i) {
        const line = this.usersContent[i].split(';');
        if (line.length < 8) continue;
        const hr = {
          ...readUserData(line, 0),
          roles: ['HR'],
        };
        this.addUser(hr);
      }
    },
    addUser(user) {
      if (Object.prototype.hasOwnProperty.call(this.newUsers, user.email)) {
        const existingUser = this.newUsers[user.email];
        for (const prop in existingUser) {
          if (!['roles', 'manager', 'mentor'].includes(prop) && existingUser[prop] !== user[prop]) {
            this.conflicts.push(`${user.email}[${prop}] ${existingUser[prop]} !== ${user[prop]}\n`);
            if (existingUser.conflicts == null) existingUser.conflicts = [];
            existingUser.conflicts.push(prop);
          }
        }
        const role = user.roles[0];
        if (!existingUser.roles.includes(role)) {
          existingUser.roles.push(role);
        }
      } else {
        const mentessaUser = this.users.find(
          (mU) => mU.identity.attributes.email.toLowerCase() === user.email.toLowerCase(),
        );
        if (mentessaUser) {
          this.conflicts.push(
            `User ${user.email} already exists with id ${mentessaUser.id} ${mentessaUser.identity.attributes.email}\n`,
          );
          user.id = mentessaUser.id;
        }
        this.newUsers[user.email] = user;
      }
    },
    async createUsers() {
      console.log('Create users', this.selectedUsers);
      try {
        await api.post('/users/upload', this.selectedUsers);
        await this.$store.dispatch(new ShowNotificationSnackbarAction(this.$t('notifications.ok')));
      } catch (e) {
        console.log('Error while upload users', e);
        await this.$store.dispatch(new ShowNotificationSnackbarAction(this.$t('notifications.generalError')));
      }
    },
    async createHR() {
      console.log('Create HR', this.hr);
      try {
        await api.post('/users/upload', [{ ...this.hr, roles: ['HR'] }]);
        await this.$store.dispatch(new ShowNotificationSnackbarAction(this.$t('notifications.ok')));
        this.hr.firstName = '';
        this.hr.lastName = '';
        this.hr.email = '';
      } catch (e) {
        console.log('Error while upload users', e);
        await this.$store.dispatch(new ShowNotificationSnackbarAction(this.$t('notifications.generalError')));
      }
    },
  },
});
