<template>
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6 0.75C2.68594 0.75 0 2.93203 0 5.625C0 6.7875 0.501563 7.85156 1.33594 8.68828C1.04297 9.86953 0.0632813 10.9219 0.0515625 10.9336C0 10.9875 -0.0140625 11.0672 0.0164062 11.1375C0.046875 11.2078 0.1125 11.25 0.1875 11.25C1.74141 11.25 2.90625 10.5047 3.48281 10.0453C4.24922 10.3336 5.1 10.5 6 10.5C9.31406 10.5 12 8.31797 12 5.625C12 2.93203 9.31406 0.75 6 0.75Z"
      fill="var(--v-secondary-base)"
    />
  </svg>
</template>

<script>
export default {
  name: 'Comment',
};
</script>
