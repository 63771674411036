import { UsersState } from './types';
import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { getters } from './getters';
import { mutations } from './mutations';
import { actions } from './actions';

export const initialState: UsersState = {
  seed: Math.floor(Math.random() * 10000),
  stream: {
    users: [],
    pagination: {
      currentPage: 0,
      totalItems: 0,
      totalPages: 0,
    },
    isLoading: false,
    filters: {},
    onlyFavorites: false,
  },
  search: {
    users: [],
    pagination: {
      currentPage: 0,
      totalItems: 0,
      totalPages: 0,
    },
    isLoading: false,
    filters: {},
    onlyFavorites: false,
  },
  hidden: {
    users: [],
    pagination: {
      currentPage: 0,
      totalItems: 0,
      totalPages: 0,
    },
    isLoading: false,
    filters: {},
    onlyFavorites: false,
  },
  detailedUser: undefined,
  me: undefined,
  permissions: {
    canInvite: false,
    superAdmin: false,
  },
  filterSources: {
    location: [],
    division: [],
    department: [],
    role: [],
    skills: [],
    name: [],
  },
  managedUsersId: [],
};

export const module: Module<UsersState, RootState> = {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};

export * from './types';
