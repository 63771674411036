import { TasksState } from './types';
import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { getters } from './getters';
import { mutations } from './mutations';
import { actions } from './actions';

export const initialState: TasksState = {
  activityFeed: [],
  mentor: undefined,
  mentees: undefined,
  mentee: undefined,
  comments: [],
  canCreateNew: {
    goals: false,
    tasks: false,
  },
  period: {
    begin: new Date(),
    end: new Date(),
  },
  all: [],
  closed: [],
};

export const module: Module<TasksState, RootState> = {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};

export * from './types';
