















import { mapState } from 'vuex';
import { LoadTotalStats, UIState } from '@/store/ui';
import { UsersState } from '@/store/users';
import DashboardStatisticsDigits from '@/components/DashboardStatisticsDigits.vue';
import Feed from '@/components/DashboardFeed/Feed.vue';
import ModalTopMembers from '@/components/ModalTopMembers.vue';
import TopMentors from '@/components/TopMentors.vue';
import { MentoringType, UserEntity } from '@mentessa/types';
import Vue, { PropType } from 'vue';

export default Vue.extend({
  name: 'DashboardCommunityStatistics',
  components: { Feed, DashboardStatisticsDigits, TopMentors, ModalTopMembers },
  props: {
    filterDate: Date,
    disableFilter: { type: Boolean, default: false },
    topMentors: {
      type: Array as PropType<UserEntity[]>,
      default: () => [],
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    ...mapState<UIState>('ui', {
      totalStats: (state: UIState) => state.stats.total,
    }),
    ...mapState<UsersState>('users', {
      me: (state: UsersState) => state.me,
    }),
    statistics() {
      return [
        {
          itemTitle: this.$t('dashboard.statistics.timeTotal'),
          value: this.toMinutes(this.totalStats.time.total.toFixed(1)),
        },
        { itemTitle: this.$t('dashboard.statistics.goalsSetTotal'), value: this.totalStats.goals.total },
        { itemTitle: this.$t('dashboard.statistics.taskSetTotal'), value: this.totalStats.tasks.total },
        {
          itemTitle: this.$t('dashboard.statistics.timePerWeekAvg'),
          value: this.toMinutes(this.totalStats.time.weekAvg.toFixed(1)),
        },
        {
          itemTitle: this.$t('dashboard.statistics.goalsPerCoupleAvg'),
          value:
            this.totalStats.goals.monthly.couples > 0
              ? (this.totalStats.goals.monthly.total / this.totalStats.goals.monthly.couples).toFixed(1)
              : 0,
        },
        {
          itemTitle: this.$t('dashboard.statistics.tasksPerWeekAvg'),
          value:
            this.totalStats.tasks.weekly.couples > 0
              ? (this.totalStats.tasks.weekly.total / this.totalStats.tasks.weekly.couples).toFixed(1)
              : 0,
        },
      ];
    },
    isMentor() {
      return this.me?.attributes.mentoringType === MentoringType.Mentor;
    },
    isMentee() {
      return this.me?.attributes.mentoringType === MentoringType.Mentee;
    },
    isHR() {
      return this.me?.attributes.isHR;
    },
    isManager() {
      return this.me?.attributes.isManager;
    },
  },
  watch: {
    async filterDate(value: Date) {
      await this.$store.dispatch(new LoadTotalStats(value));
    },
    async disableFilter(value: boolean) {
      if (value) {
        await this.$store.dispatch(new LoadTotalStats());
      } else {
        await this.$store.dispatch(new LoadTotalStats(this.filterDate));
      }
    },
  },
  methods: {
    toMinutes(ms) {
      return this.$t('dashboard.minutes', { count: Math.floor(ms / (1000 * 60)) });
    },
    showModal() {
      this.dialog = true;
    },
    closeModal() {
      this.dialog = false;
    },
  },
});
