













import Vue from 'vue';
import UserStream from '@/components/UserStream.vue';
import Search from '@/components/Search.vue';
import { mapState } from 'vuex';
import { UsersState } from '@/store/users';
import NavigationDrawer from '@/components/NavigationDrawer.vue';
import { MentoringType } from '@mentessa/types';

export default Vue.extend({
  components: {
    NavigationDrawer,
    Search,
    UserStream,
  },
  data: () => ({
    filterValues: {
      location: '',
      division: '',
      department: '',
      role: '',
      skills: '',
    },
    searchFilterValues: {
      location: '',
      division: '',
      department: '',
      role: '',
      skills: '',
    },
  }),
  computed: {
    ...mapState<UsersState>('users', {
      users: (state: UsersState) => state.stream.users,
    }),

    hasFilters() {
      return this.users.length && this.filteredUsers.length !== this.users.length;
    },

    filteredUsers() {
      const values = this.filterValues;
      const searchValues = this.searchFilterValues;
      let result = this.users;
      // if (!values) return result;

      const parseRole = (name) => {
        switch (name) {
          case 'Mentor':
            return MentoringType.Mentor;
          case 'Menti':
            return MentoringType.Mentee;
        }
        return undefined;
        // return name === 'Mentor' ? MentoringType.Mentor : MentoringType.Mentee;
      };

      if (values?.location) {
        result = result.filter((user) => user.attributes.location?.toLowerCase() === values.location.toLowerCase());
      }
      if (searchValues?.location) {
        result = result.filter(
          (user) => user.attributes.location?.toLowerCase() === searchValues.location.toLowerCase(),
        );
      }
      if (values?.role) {
        const role = parseRole(values.role);
        if (role) {
          result = result.filter((user) => user.attributes.mentoringType === role);
        } else if (values.role === 'HR') {
          result = result.filter((user) => user.attributes.isHR === true);
        } else if (values.role === 'Menadžer') {
          result = result.filter((user) => user.attributes.isManager === true);
        }
      }
      if (searchValues?.role) {
        const role = parseRole(searchValues.role);
        // result = result.filter((user) => user.attributes.mentoringType === role);
        if (role) {
          result = result.filter((user) => user.attributes.mentoringType === role);
        } else if (searchValues.role === 'HR') {
          result = result.filter((user) => user.attributes.isHR === true);
        } else if (searchValues.role === 'Menadžer') {
          result = result.filter((user) => user.attributes.isManager === true);
        }
      }
      if (values?.department) {
        result = result.filter((user) =>
          user.attributes.department?.toLowerCase().includes(values.department.toLowerCase()),
        );
      }
      if (searchValues?.department) {
        result = result.filter((user) =>
          user.attributes.department?.toLowerCase().includes(searchValues.department.toLowerCase()),
        );
      }
      if (values?.division) {
        result = result.filter((user) =>
          user.attributes.division?.toLowerCase().includes(values.division.toLowerCase()),
        );
      }
      if (searchValues?.division) {
        result = result.filter((user) =>
          user.attributes.division?.toLowerCase().includes(searchValues.division.toLowerCase()),
        );
      }
      if (values?.skills) {
        result = result.filter(
          (user) =>
            user.expertiseTags.some((tag) => tag.name.toLowerCase() === values.skills.toLowerCase()) ||
            user.interestTags.some((tag) => tag.name.toLowerCase() === values.skills.toLowerCase()),
        );
      }
      if (searchValues?.skills) {
        result = result.filter(
          (user) =>
            user.expertiseTags.some((tag) => tag.name.toLowerCase() === searchValues.skills.toLowerCase()) ||
            user.interestTags.some((tag) => tag.name.toLowerCase() === searchValues.skills.toLowerCase()),
        );
      }
      if (values?.name) {
        const getFullName = (user) => `${user.identity.attributes.firstName} ${user.identity.attributes.lastName}`;
        result = result.filter((user) => getFullName(user).includes(values.name));
      }
      if (searchValues?.name) {
        const getFullName = (user) => `${user.identity.attributes.firstName} ${user.identity.attributes.lastName}`;
        result = result.filter((user) => getFullName(user).includes(searchValues.name));
      }
      return result;
    },
  },
  methods: {
    setFilters(data) {
      this.filterValues = data;
    },
    clearFilters() {
      this.filterValues = undefined;
    },
    setSearchFilters(data) {
      this.searchFilterValues = data;
    },
    clearSearchFilters() {
      this.searchFilterValues = undefined;
    },
  },
});
