




























































































import { mapState } from 'vuex';
import { MessagesState } from '@/store/messages';
import Vue from 'vue';
import RateMentor from '@/components/RateMentor.vue';
import { UsersState } from '@/store/users';
import { CloseDrawer, ShowDrawer, UIState } from '@/store/ui';

export default Vue.extend({
  name: 'BottomNavigation',
  components: { RateMentor },
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    ...mapState<UsersState>('users', {
      me: (state: UsersState) => state.me,
    }),
    ...mapState<MessagesState>('messages', {
      unreadCount: (state: MessagesState) => state.unreadMessageCount,
    }),
    showFilters() {
      return this.$route.name === 'Community';
    },
    isHR() {
      return this.me?.attributes.isHR;
    },
    ...mapState<UIState>('ui', {
      isDrawerVisible: (state: UIState) => state.navigationDrawer.isVisible,
    }),
  },
  methods: {
    async logout() {
      await this.$keycloak.keycloak.logout();
    },
    openDialog() {
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    },
    onClickChild() {
      this.closeDialog();
    },
    async toggleDrawer() {
      if (this.isDrawerVisible) {
        await this.$store.dispatch(new CloseDrawer());
      } else {
        await this.$store.dispatch(new ShowDrawer());
      }
    },
  },
});
