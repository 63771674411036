









import Vue from 'vue';
import ProfileEdit from '@/components/ProfileEdit.vue';
import { mapState } from 'vuex';
import { UsersState } from '@/store/users';
import { TenantState } from '@/store/tenant';

export default Vue.extend({
  components: {
    ProfileEdit,
  },
  data: () => ({}),
  computed: {
    ...mapState<UsersState>('users', {
      user: (state: UsersState) => state.me,
      isComplete: (state: UsersState) => state.me.complete,
    }),
    ...mapState<TenantState>('tenant', {
      userCard: (state: TenantState) => state.tenant?.config.userCard,
    }),
  },
});
