














































































import Vue from 'vue';
import { mapState } from 'vuex';
import { TasksState } from '@/store/tasks';
import { SendQuestionnaire } from '@/store/utils';
import { UsersState } from '@/store/users';
import { CloseFeedback, ShowWelcome, UIState, WelcomeType } from '@/store/ui';
import { MentoringType } from '@mentessa/types';

export default Vue.extend({
  name: 'RateMentor',
  computed: {
    ...mapState<UsersState>('users', {
      me: (state: UsersState) => state.me,
    }),
    ...mapState<TasksState>('tasks', {
      mentor: (state: TasksState) => state.mentor,
      mentee: (state: TasksState) => state.mentee,
    }),
    ...mapState<UIState>('ui', {
      isVisible: (state: UIState) => state.feedback.isVisible,
      taskSetId: (state: UIState) => state.feedback.taskSetId,
      questionnaire: (state: UIState) => state.feedback.questionnaire,
    }),
    isMentor() {
      return this.me.attributes.mentoringType === MentoringType.Mentor;
    },
    isReadOnly() {
      return this.questionnaire != null;
    },
  },
  created() {
    this.loadQuestions();
  },
  data: () => ({
    questions: [],
    additionalFeedback: '',
  }),
  watch: {
    questionnaire() {
      this.loadQuestions();
    },
  },
  methods: {
    addRating(index, newRating) {
      this.questions[index - 1].rating = newRating;
    },
    loadQuestions() {
      if (this.questionnaire) {
        this.questions = this.questionnaire.result.answers; //.map((answer) => ({ ...answer, rating: answer.rating * 5 }));
        this.additionalFeedback = this.questionnaire.result.text;
      } else {
        this.additionalFeedback = '';
        this.questions = this.$t('feedbackQuestions').map((element) => ({
          id: element.id,
          text: this.isMentor ? element.question : element.alternative,
          rating: 1,
        }));
      }
    },
    async submitFeedback() {
      const sender = this.me; // this.isMentor ? this.mentor.user : this.mentee.user;
      const target = this.isMentor ? this.mentee.user : this.mentor.user;
      await this.$store.dispatch(
        new SendQuestionnaire(sender, target, this.taskSetId, this.questions, this.additionalFeedback),
      );
      await this.closeFeedback();
      await this.$store.dispatch(new ShowWelcome(WelcomeType.FeedBack));
    },
    async setVisible(isVisible) {
      if (!isVisible) await this.closeFeedback();
      else {
        this.loadQuestions();
      }
    },
    async closeFeedback() {
      await this.$store.dispatch(new CloseFeedback());
    },
  },
});
