








































import { mapState } from 'vuex';
import { EditGoals, GuidanceType, CloseGuidance, UIState } from '@/store/ui';
import Vue from 'vue';
import i18n from '@/plugins/i18n';
import { UsersState } from '@/store/users';

export default Vue.extend({
  name: 'GuidanceSnackbar',
  data: () => ({
    text: '',
  }),
  computed: {
    ...mapState<UIState>('ui', {
      type: (state: UIState) => state.guidanceSnackbar.type,
      isVisible: (state: UIState) => state.guidanceSnackbar.isVisible,
      isWelcomeDialog: (state: UIState) => state.welcomeDialog.isVisible,
      isRateMentor: (state: UIState) => state.feedback.isVisible,
      badgeName: (state: UIState) => state.guidanceSnackbar.options?.badgeName,
    }),
    ...mapState<UsersState>('users', {
      me: (state: UsersState) => state.me,
    }),
    myName() {
      return `${this.me.identity.attributes.firstName} ${this.me.identity.attributes.lastName}`;
    },
    primaryButtonText() {
      switch (this.type) {
        case GuidanceType.NewBadge:
          return this.$t('guidance.checkBadgesBtn');
        default:
          return 'OK';
      }
    },
    isSecondaryButton() {
      switch (this.type) {
        case GuidanceType.MentorWelcome:
          return true;
        default:
          return false;
      }
    },
  },
  methods: {
    async closeSnackbar() {
      await this.$store.dispatch(new CloseGuidance());
    },
    async dialogAction() {
      switch (this.type) {
        case GuidanceType.MentorWelcome:
          await this.$store.dispatch(new EditGoals());
          break;
        case GuidanceType.NewBadge:
          if (this.$route.name !== 'Badges') await this.$router.push('/badges');
          break;
        default:
          break;
      }
      // const redirect = await this.$store.dispatch(new DoGuidanceAction(GuidanceType.MentorWelcome));
      // if (redirect && redirect.length > 0) await this.$router.push(redirect);
      await this.closeSnackbar();
    },
  },
  watch: {
    type(newType) {
      switch (newType) {
        case GuidanceType.MentorWelcome:
          this.text = i18n.t(`guidance.mentorWelcome`, { name: this.myName });
          break;
        case GuidanceType.MentorAddGoals:
          this.text = i18n.t(`guidance.mentorMonthlyGoals`);
          break;
        case GuidanceType.MentorAddTasks:
          this.text = i18n.t(`guidance.mentorWeeklyTasks`);
          break;
        case GuidanceType.MentorEvaluation:
          this.text = i18n.t(`guidance.mentorEvaluation`, { name: 'Mentee' });
          break;
        case GuidanceType.MenteeWelcome:
          this.text = i18n.t(`guidance.menteeWelcome`, { name: 'Mentee' });
          break;
        case GuidanceType.MenteeNewGoals:
          // TODO
          this.text = i18n.t(`guidance.menteeNewGoals`, { name: 'Mentor' });
          break;
        case GuidanceType.MenteeNewTasks:
          // TODO
          this.text = i18n.t(`guidance.menteeNewTasks`, { name: 'Mentor' });
          break;
        case GuidanceType.MenteeApprovedGoal:
          // TODO
          this.text = i18n.t(`guidance.menteeApGoal`, { name: 'Mentor' });
          break;
        case GuidanceType.MenteeApprovedTask:
          // TODO
          this.text = i18n.t(`guidance.menteeApTask`, { name: 'Mentor' });
          break;
        case GuidanceType.MenteeMarkedGoalNotDone:
          // TODO
          this.text = i18n.t(`guidance.menteeGoalNotDone`, { name: 'Mentor' });
          break;
        case GuidanceType.MenteeMarkedTaskNotDone:
          // TODO
          this.text = i18n.t(`guidance.menteeTaskNotDone`, { name: 'Mentor' });
          break;
        case GuidanceType.HRWelcome:
          // TODO
          this.text = i18n.t(`guidance.hrWelcome`);
          break;
        case GuidanceType.HRDashboard:
          this.text = i18n.t(`guidance.hrDashboard`, { name: 'HR' });
          break;
        case GuidanceType.HRWelcomeBack:
          this.text = i18n.t(`guidance.hrWelcomeBack`);
          break;
        case GuidanceType.NewBadge:
          this.text = i18n.t(`guidance.newBadge`, { badgeName: this.$t(`badges.${this.badgeName}`) });
          break;
      }
    },
  },
});
