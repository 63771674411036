import { Action } from '@/store/types';
import { QuestionnaireEntity, TaskEntity, UserEntity } from '@mentessa/types';

export const moduleName = 'ui';

export interface NotificationDialog {
  message: string;
  variant?: string;
  buttonText?: string;
}

export interface NotificationSnackbar {
  message: string;
}

export enum GuidanceType {
  MentorWelcome,
  MentorAddGoals,
  MentorAddTasks,
  MentorEvaluation,
  MenteeWelcome,
  MenteeNewGoals,
  MenteeNewTasks,
  MenteeApprovedGoal,
  MenteeApprovedTask,
  MenteeMarkedGoalNotDone,
  MenteeMarkedTaskNotDone,
  HRWelcome,
  HRDashboard,
  HRWelcomeBack,
  NewBadge,
}

export interface GuidanceOptions {
  user?: UserEntity | { id: number };
  badgeName?: string;
}

export interface GuidanceSnackbar {
  type: GuidanceType;
  isVisible: boolean;
  options: GuidanceOptions;
}

export interface WelcomeOptions {
  percents?: number;
  badge?: BadgeType;
  taskSetId?: number;
}

export enum WelcomeType {
  MentorWelcome,
  MenteeWelcome,
  HRWelcome,
  MentorEvaluation,
  MenteeTasksCompleted,
  MenteeGoalsCompleted,
  FeedBack,
  BadgeReceived,
}

export enum BadgeType {
  Champion,
  GreatJob,
  KeepWinning,
  LetsTalk,
  Rocket,
  Support,
  Welcome,
  WellDone,
}

export interface WelcomeDialog {
  type: WelcomeType;
  isVisible: boolean;
  options: WelcomeOptions;
}

export interface CommentsDialog {
  isVisible: boolean;
  task: TaskEntity;
}

export interface TotalStatistics {
  time: { total: number; weekAvg: number };
  goals: { total: number; perPair: number; donePerPair: number; monthly: { total: number; couples: number } };
  tasks: { total: number; perWeek: number; donePerWeek: number; weekly: { total: number; couples: number } };
}

export interface CoupleStatistics {
  time: { total: number; week: number };
  goals: { total: number; completed: number };
  tasks: { total: number; completed: number };
}

export interface Statistics {
  total: TotalStatistics;
  couple: CoupleStatistics;
}

export interface FeedbackOptions {
  isVisible: boolean;
  taskSetId?: number;
  questionnaire?: QuestionnaireEntity;
}

export interface UIState {
  notificationDialog: NotificationDialog;
  notificationSnackbar: NotificationSnackbar;
  guidanceSnackbar: GuidanceSnackbar;
  welcomeDialog: WelcomeDialog;
  commentsDialog: CommentsDialog;
  ui: {
    editGoals: boolean;
    editTasks: boolean;
  };
  stats: Statistics;
  feedback: FeedbackOptions;
  navigationDrawer: {
    isVisible: boolean;
  };
}

export enum UIActions {
  ShowNotificationDialog = '[UI] Show Notification Dialog',
  ShowNotificationSnackbar = '[UI] Show Notification Snackbar',
  ShowGuidance = '[UI] Show Guidance Snackbar',
  CloseGuidanceSnackbar = '[UI] Close Guidance Snackbar',
  DoGuidanceAction = '[UI] Do Guidance Action',
  ShowWelcomeAction = '[UI] Show Welcome Action',
  CloseWelcomeAction = '[UI] Close Welcome Action',
  EditGoals = '[UI] Edit goals',
  EditTasks = '[UI] Edit tasks',
  LoadTotalStats = '[UI] Load Total Stats',
  LoadCoupleStats = '[UI] Load Couple Stats',
  GiveFeedback = '[UI] Give Feedback',
  ShowFeedback = '[UI] Show Feedback',
  CloseFeedback = '[UI] Close Feedback',
  ShowCommentsDialog = '[UI] Show Comments Dialog',
  CloseCommentsDialog = '[UI] Close Comments Dialog',
  ShowNavigationDrawer = '[UI] Show Navigation Drawer',
  CloseNavigationDrawer = '[UI] Close Navigation Drawer',
}

export enum UIMutations {
  SetNotificationDialog = '[UI] Set Notification Dialog',
  SetNotificationSnackbar = '[UI] Set Notification Snackbar',
  ShowGuidanceSnackbarVisibility = '[UI] Show Guidance Snackbar Visibility',
  SetGuidanceType = '[UI] Set Guidance Type',
  SetGuidanceOptions = '[UI] Set Guidance Options',
  DoGuidance = '[UI] Do Guidance',
  ShowWelcome = '[UI] Show Welcome',
  CloseWelcome = '[UI] Close Welcome',
  SetEditGoals = '[UI] Set Edit Goals',
  SetEditTasks = '[UI] Set Edit Tasks',
  SetTotalStats = '[UI] Set Total Stats',
  SetCoupleStats = '[UI] Set Couple Stats',
  SetFeedbackVisible = '[UI] Set Feedback Visible',
  SetCommentsDialogVisible = '[UI] Set Comments Dialog Visible',
  SetCommentsDialogTask = '[UI] Set Comments Dialog Task',
  SetNavigationDrawerVisible = '[UI] Set Navigation Drawer Visible',
  SetNavigationDrawerHidden = '[UI] Set Navigation Drawer Hidden',
}

export class ShowNotificationDialogAction implements Action {
  constructor(public message: string, public variant: string, public buttonText: string) {
    this.message = message;
    this.variant = variant;
    this.buttonText = buttonText;
  }

  type = `${moduleName}/${UIActions.ShowNotificationDialog}`;
}

export class ShowNotificationSnackbarAction implements Action {
  constructor(public message: string) {
    this.message = message;
  }

  type = `${moduleName}/${UIActions.ShowNotificationSnackbar}`;
}

export class ShowGuidance {
  constructor(public guidanceType: GuidanceType, public options?: GuidanceOptions) {
    this.guidanceType = guidanceType;
    this.options = options;
  }

  type = `${moduleName}/${UIActions.ShowGuidance}`;
}

export class CloseGuidance {
  type = `${moduleName}/${UIActions.CloseGuidanceSnackbar}`;
}

export class DoGuidanceAction {
  constructor(public guidanceType: GuidanceType) {
    this.guidanceType = guidanceType;
  }

  type = `${moduleName}/${UIActions.DoGuidanceAction}`;
}

export class ShowWelcome {
  constructor(public welcomeType: WelcomeType, public options?: WelcomeOptions) {
    this.welcomeType = welcomeType;
    this.options = options;
  }

  type = `${moduleName}/${UIActions.ShowWelcomeAction}`;
}

export class CloseWelcome {
  type = `${moduleName}/${UIActions.CloseWelcomeAction}`;
}

export class EditGoals {
  constructor(public isEdit: boolean = true) {
    this.isEdit = isEdit;
  }

  type = `${moduleName}/${UIActions.EditGoals}`;
}

export class EditTasks {
  constructor(public isEdit: boolean = true) {
    this.isEdit = isEdit;
  }

  type = `${moduleName}/${UIActions.EditTasks}`;
}

export class LoadTotalStats {
  type = `${moduleName}/${UIActions.LoadTotalStats}`;

  constructor(public filterDate: Date = undefined) {
    this.filterDate = filterDate;
  }
}

export class GiveFeedback {
  constructor(public taskSetId: number) {
    this.taskSetId = taskSetId;
  }

  type = `${moduleName}/${UIActions.GiveFeedback}`;
}

export class ShowFeedback {
  constructor(public questionnaire: QuestionnaireEntity) {
    this.questionnaire = questionnaire;
  }

  type = `${moduleName}/${UIActions.ShowFeedback}`;
}

export class CloseFeedback {
  type = `${moduleName}/${UIActions.CloseFeedback}`;
}

export class LoadCoupleStats {
  constructor(public mentor: UserEntity, public mentee: UserEntity, public filterDate: Date) {
    this.mentor = mentor;
    this.mentee = mentee;
    this.filterDate = filterDate;
  }

  type = `${moduleName}/${UIActions.LoadCoupleStats}`;
}

export class ShowCommentsDialog {
  constructor(public task: TaskEntity) {
    this.task = task;
  }

  type = `${moduleName}/${UIActions.ShowCommentsDialog}`;
}

export class CloseCommentsDialog {
  type = `${moduleName}/${UIActions.CloseCommentsDialog}`;
}

export class ShowDrawer {
  type = `${moduleName}/${UIActions.ShowNavigationDrawer}`;
}

export class CloseDrawer {
  type = `${moduleName}/${UIActions.CloseNavigationDrawer}`;
}
