import { ActionTree } from 'vuex';
import { RootActions, RootMutations, RootState } from '@/store/types';
import Vue from 'vue';
import { GetMe, LoadHiddenUsers, LoadManagedUsers, LoadNextStreamPage, LoadPermissions } from '@/store/users';
import { LoadLocations, LoadTenant, LoadUsernames } from '@/store/tenant';
import { InitGeonames, LoadLanguages } from '@/store/utils';
import { MentoringType, UserEntity } from '@mentessa/types';
import { pause, resume } from '@/router';
import { StartFetchMessages } from '@/store/messages';
import { LoadAllTasks, LoadMentees, LoadMentor, LoadTasks, LoadTasksPeriod } from '@/store/tasks';
import { LoadTotalStats } from '@/store/ui';

export const actions: ActionTree<RootState, RootState> = {
  async [RootActions.Initialize]({ commit, dispatch }) {
    pause();
    Vue.prototype.$log.debug('Initialize app');
    await dispatch(new LoadTenant());
    await dispatch(new LoadLocations());
    const user: UserEntity = await dispatch(new GetMe());
    await dispatch(new LoadNextStreamPage());
    switch (user.attributes.mentoringType) {
      case MentoringType.Mentor:
        console.log('Load Mentor Data');
        await dispatch(new LoadMentees());
        await dispatch(new LoadTasks());
        break;
      case MentoringType.Mentee:
        console.log('Load Mentee Data');
        await dispatch(new LoadMentor());
        await dispatch(new LoadTasks());
        break;
    }
    if (user.attributes.isHR || user.attributes.isManager) {
      console.log('Load HR data');
      await dispatch(new LoadTasksPeriod());
      await dispatch(new LoadTotalStats(new Date()));
      await dispatch(new LoadAllTasks());
      if (user.attributes.isManager) {
        await dispatch(new LoadManagedUsers(user));
      }
    }
    // console.log('Initialize:', user);
    const locale = user.locale || 'en';
    await dispatch(new LoadLanguages(locale));
    await dispatch(new LoadUsernames());
    await dispatch(new InitGeonames(locale));
    await dispatch(new StartFetchMessages());
    await dispatch(new LoadPermissions());

    if (user.hidden) {
      await dispatch(new LoadHiddenUsers());
    }

    commit(RootMutations.Initialize);
    resume();
  },
};
