export const moduleName = 'events';

export interface EventsState {
  isConnected: boolean;
  isRegistered: boolean;
}

export enum EventsActions {
  msgToClient = 'socket_msgToClient',
  registerResult = 'socket_registerResult',
  activity = 'socket_activity',
  anotherSession = 'socket_anotherSession',
  logout = 'socket_logout',
}

export enum EventsMutations {
  Connect = 'SOCKET_CONNECT',
  Disconnect = 'SOCKET_DISCONNECT',
  SetRegisterStatus = '[Events] Set Register Status',
}
