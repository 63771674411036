import { MentoringUserRecord, TasksMutations, TasksState, TimePeriod } from './types';
import { MutationTree } from 'vuex';
import { ActivityEntity, TaskCommentEntity, TaskSetEntity, TaskSetType, UserEntity } from '@mentessa/types';

function findTask(state: TasksState, id: number) {
  let task = state.mentor?.tasks.goals?.tasks.find((task) => task.id === id);
  task = task || state.mentor?.tasks.weekly?.tasks.find((task) => task.id === id);
  if (task) {
    return task;
  }

  for (const mentee of state.mentees) {
    task = mentee.tasks.goals?.tasks.find((task) => task.id === id);
    task = task || mentee.tasks.weekly?.tasks.find((task) => task.id === id);
    if (task) {
      return task;
    }
  }
  return undefined;
}

function findTaskSet(state: TasksState, id: number) {
  if (state.mentor?.tasks.goals.id === id) return state.mentor.tasks.goals;
  else if (state.mentor?.tasks.weekly.id === id) return state.mentor.tasks.weekly;
  for (const mentee of state.mentees) {
    if (mentee.tasks.goals?.id === id) {
      return mentee.tasks.goals;
    } else if (mentee.tasks.weekly?.id === id) return mentee.tasks.weekly;
  }
  return undefined;
}

function findTaskSetContainer(state: TasksState, id: number) {
  if (state.mentor?.tasks.goals?.id === id) return state.mentor.tasks;
  else if (state.mentor?.tasks.weekly?.id === id) return state.mentor.tasks;
  for (const mentee of state.mentees) {
    if (mentee.tasks.goals?.id === id) {
      return mentee.tasks;
    } else if (mentee.tasks.weekly?.id === id) return mentee.tasks;
  }
  return undefined;
}

export const mutations: MutationTree<TasksState> = {
  [TasksMutations.SetMentor](state: TasksState, mentor: UserEntity) {
    state.mentor = {
      user: mentor,
      tasks: {
        goals: undefined,
        weekly: undefined,
      },
    };
    if (mentor && !state.mentor.user.identity.attributes.image) {
      state.mentor.user.identity.attributes.image = '/user-empty.png';
    }
  },

  [TasksMutations.SetMentees](state: TasksState, mentees: UserEntity[]) {
    state.mentees = mentees.map((mentee) => ({ user: mentee, tasks: { goals: undefined, weekly: undefined } }));
    state.mentees.forEach((mentee) => {
      if (!mentee.user.identity.attributes.image) {
        mentee.user.identity.attributes.image = '/user-empty.png';
      }
    });
  },

  [TasksMutations.SetTaskSetForMentee](state: TasksState, set: TaskSetEntity) {
    const mentee = state.mentees.find((record) => record.user.id === set.menteeId);

    if (!mentee) {
      console.error('Wrong task set or mentee not loaded', set);
      return;
    }

    if (set.type === TaskSetType.Monthly) {
      mentee.tasks.goals = set;
    } else {
      mentee.tasks.weekly = set;
    }
  },
  [TasksMutations.SetTaskSetForMentor](state: TasksState, set: TaskSetEntity) {
    if (set.type === TaskSetType.Monthly) {
      state.mentor.tasks.goals = set;
    } else {
      state.mentor.tasks.weekly = set;
    }
  },

  [TasksMutations.SetMentee](state: TasksState, mentee: MentoringUserRecord) {
    state.mentee = mentee;
  },
  [TasksMutations.SetTaskSetStage](state: TasksState, { taskSet, stage }) {
    taskSet.stage = stage;
  },
  [TasksMutations.SetTaskDone](state: TasksState, { task, status }) {
    const storeTask = findTask(state, task.id);
    storeTask.isDone = status;
  },
  [TasksMutations.SetTaskEvaluated](state: TasksState, { task, status }) {
    const storeTask = findTask(state, task.id);
    storeTask.isEvaluated = status;
  },
  [TasksMutations.SetTaskProgress](state: TasksState, { task, value }) {
    const storeTask = findTask(state, task.id);
    storeTask.progress = value;
  },
  [TasksMutations.SetEvents](state: TasksState, events: ActivityEntity[]) {
    state.activityFeed = events;
  },
  [TasksMutations.AppendEvent](state: TasksState, event: ActivityEntity) {
    state.activityFeed.unshift(event);
  },
  [TasksMutations.SetEventRead](state: TasksState, event: ActivityEntity) {
    const storeEvent = state.activityFeed.find((e) => e.id === event.id);
    storeEvent.isRead = true;
  },
  [TasksMutations.SetTaskComments](state: TasksState, comments: TaskCommentEntity[]) {
    state.comments = comments;
  },
  [TasksMutations.AppendTaskComment](state: TasksState, comment: TaskCommentEntity) {
    state.comments.push(comment);
  },
  [TasksMutations.FindTaskSetAndSetStage](state: TasksState, { id, stage }) {
    const taskSet = findTaskSet(state, id);
    if (!taskSet) {
      console.error(`FindTaskSetAndSetStage. No ${id} taskSet`);
      return;
    }
    taskSet.stage = stage;
  },
  [TasksMutations.FindTaskSetAndClear](state: TasksState, id: number) {
    const taskSetContainer = findTaskSetContainer(state, id);
    if (taskSetContainer) {
      if (taskSetContainer.goals?.id === id) taskSetContainer.goals = undefined;
      else if (taskSetContainer.weekly?.id === id) taskSetContainer.weekly = undefined;
    } else {
      console.error(`FindTaskSetAndClear. No ${id} taskSet`);
      return;
    }
  },
  [TasksMutations.FindTaskAndSetProgress](state: TasksState, { id, progress }) {
    const task = findTask(state, id);
    if (!task) {
      console.error(`FindTaskAndSetProgress. No ${id} task`);
      return;
    }
    task.progress = progress;
  },
  [TasksMutations.FindTaskAndSetText](state: TasksState, { id, text }) {
    const task = findTask(state, id);
    if (!task) {
      console.error(`FindTaskAndSetText. No ${id} task`);
      return;
    }
    task.text = text;
  },
  [TasksMutations.FindTaskAndSetState](tasksState: TasksState, { id, state }) {
    const task = findTask(tasksState, id);
    if (!task) {
      console.error(`FindTaskAndSetState. No ${id} task`);
      return;
    }
    task.state = state;
  },
  [TasksMutations.FindTaskAndDelete](state: TasksState, { id }) {
    const taskSet = findTaskSet(state, id);
    if (!taskSet) {
      console.error(`FindTaskAndDelete. No ${id} task`);
      return;
    }
    const position = taskSet.tasks.findIndex((task) => task.id === id);
    taskSet.tasks.splice(position, 1);
  },
  [TasksMutations.SetNewAvailability](state: TasksState, availability: { goals: boolean; tasks: boolean }) {
    state.canCreateNew = availability;
  },
  [TasksMutations.SetTaskState](state: TasksState, options) {
    const storeTask = findTask(state, options.task.id);
    storeTask.state = options.state;
  },
  [TasksMutations.SetTaskText](state: TasksState, options) {
    const storeTask = findTask(state, options.task.id);
    storeTask.text = options.text;
  },
  [TasksMutations.RemoveTaskFromTaskSet](state: TasksState, options) {
    const storeTask = findTask(state, options.task.id);
    storeTask.state = options.state;
  },
  [TasksMutations.SetPeriod](state: TasksState, period: TimePeriod) {
    state.period = period;
    const today = new Date();
    state.period = {
      begin: period.begin < today ? period.begin : today,
      end: period.end > today ? period.end : today,
    };
  },
  [TasksMutations.SetAllTasks](state: TasksState, taskSets) {
    state.all = taskSets ?? [];
  },
  [TasksMutations.SetClosedTasks](state: TasksState, taskSets) {
    state.closed = taskSets ?? [];
  },
};
