<template>
  <svg width="30" height="18" viewBox="0 0 30 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2 0C0.895431 0 0 0.89543 0 2V16C0 17.1046 0.895432 18 2 18H28.0009C29.1054 18 30.0009 17.1046 30.0009 16V2C30.0009 0.895431 29.1054 0 28.0009 0H2ZM9.63452 9.75515L13.2267 13.316C13.4425 13.53 13.7925 13.53 14.0083 13.316L20.3637 7.01602C20.5796 6.80207 20.5796 6.4552 20.3637 6.24127L19.5822 5.46653C19.3664 5.2526 19.0164 5.2526 18.8006 5.46653L13.6175 10.6044L11.1976 8.20566C10.9818 7.99171 10.6319 7.99171 10.4161 8.20566L9.63452 8.9804C9.4187 9.19436 9.4187 9.54122 9.63452 9.75515Z"
      fill="#fff"
    />
  </svg>
</template>

<script>
export default {
  name: 'Evaluate',
};
</script>

<style scoped>
.evaluated-on path {
  fill: var(--v-primary-base);
}
.evaluated-off path {
  fill: var(--v-secondary-base);
}
</style>
