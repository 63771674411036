






























































































import { mapState } from 'vuex';
import { UsersState } from '@/store/users';
import Vue from 'vue';
import { MessagesState } from '@/store/messages';
import { MentoringType } from '@mentessa/types';
import { TenantState } from '@/store/tenant';
import { RootState } from '@/store/types';
import { TasksState } from '@/store/tasks';

export default Vue.extend({
  name: 'AppBar',
  data() {
    return {
      feedbackDialog: false,
    };
  },
  computed: {
    ...mapState<UsersState>('users', {
      isLoading: (state: UsersState) => state.stream.isLoading,
      me: (state: UsersState) => state.me,
      canInvite: (state: UsersState) => state.permissions.canInvite,
    }),
    ...mapState<MessagesState>('messages', {
      unreadCount: (state: MessagesState) => state.unreadMessageCount,
    }),
    ...mapState<TenantState>('tenant', {
      logo: (state: TenantState) => state.tenant?.config.theme.logo,
    }),
    ...mapState<RootState>({
      initialized: (state: RootState) => state.initialized,
    }),
    ...mapState<TasksState>('tasks', {
      mentees: (state: TasksState) => state.mentees,
      mentor: (state: TasksState) => state.mentor,
    }),
    isMentor() {
      return this.me?.attributes.mentoringType === MentoringType.Mentor;
    },
    isMentee() {
      return this.me?.attributes.mentoringType === MentoringType.Mentee;
    },
    isHR() {
      return this.me?.attributes.isHR;
    },
    isManager() {
      return this.me?.attributes.isManager;
    },
    isMentoringAvailable() {
      return (this.isMentor && this.mentees?.length > 0) || (this.isMentee && this.mentor.user);
    },
  },
  watch: {
    initialized(newState) {
      console.log(newState);
    },
  },
  methods: {
    async logout() {
      await this.$keycloak.keycloak.logout();
    },
    openDialog() {
      this.feedbackDialog = true;
    },
    closeDialog() {
      this.feedbackDialog = false;
    },
    onClickChild() {
      this.closeDialog();
    },
    goHome() {
      if (this.isMentoringAvailable) {
        if (this.$route.path !== '/mentoring') this.$router.push('/mentoring');
      } else {
        if (this.$route.path !== '/') this.$router.push('/');
      }
    },
  },
});
